import React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import '../css/Header.css';
import { useQuestions } from './QuestionProvider';
import { Button, ClickAwayListener, Popover } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useNavigate } from 'react-router-dom';



const Header: React.FC = () => {

    const { lan, setLan, languages } = useQuestions();
    let navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(prevState => !prevState);
    };


    const handleClose = () => {
        setAnchorEl(null);
        setOpen(prevState => !prevState);
    };

    const [open, setOpen] = React.useState(false);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className='header_div'>
            <div>
                <Button
                    onClick={() => navigate('/')}
                    style={{ padding: '0px', display: 'flex', flexDirection: 'row' }} variant='zacco-full-rounded'>
                    <img style={{ height: "40%", width: '40%' }} src='./Home.svg' alt='zacco-logo' />
                </Button>
            </div>
            <ClickAwayListener onClickAway={handleClose}>
                <>
                    <div
                        onClick={handleClick}
                        className='header_lan_btn'
                    >
                        <div><p>{lan || '-'}</p></div>
                        <div>{!open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div>
                    </div>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        {
                            languages.map((lan, index) => {
                                return (
                                    <MenuItem
                                        key={index}
                                        onClick={() => {
                                            setLan(lan)
                                            handleClose()
                                        }}
                                    >
                                        {lan}
                                    </MenuItem>
                                )
                            })
                        }
                    </Popover>
                </>
            </ClickAwayListener>
        </div >
    )
}

export default Header;