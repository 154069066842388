import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Millionaire from './zacco-games/Millionaire';
import ZaccoHomePage from './zacco-games/ZaccoHomePage';
import TimeTravel from './zacco-games/TimeTravel';



function App() {
  return (
    <Routes>
      <Route path="/" element={<ZaccoHomePage />} />
      <Route path="/millionaire" element={<Millionaire />} />
      <Route path="/time-travel" element={<TimeTravel />} />
    </Routes>
  );
}

export default App;
