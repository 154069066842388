import React from 'react';
import { IconInterface } from '../components/IconInterface';

const NazioniUniteIcon: React.FC<IconInterface> = ({ width, height, fill }) => {
    return (
        <svg width={`${width ? `${width}px` : '20px'}`} height={`${height ? `${height}px` : '20px'}`} viewBox="0 0 122 57" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_30_498)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M46.5598 0.45708C46.5598 0.578306 46.5127 0.694566 46.4287 0.780285C46.3448 0.866004 46.2309 0.914161 46.1122 0.914161C45.9953 0.911446 45.884 0.862094 45.8023 0.776667C45.7205 0.69124 45.6748 0.576527 45.6748 0.45708C45.6748 0.337634 45.7205 0.222921 45.8023 0.137494C45.884 0.052067 45.9953 0.00271469 46.1122 0C46.2309 0 46.3448 0.0481565 46.4287 0.133876C46.5127 0.219595 46.5598 0.335855 46.5598 0.45708Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M46.5598 2.07755C46.5598 2.19878 46.5127 2.31504 46.4287 2.40076C46.3448 2.48648 46.231 2.53463 46.1122 2.53463C46.0064 2.5182 45.9099 2.46357 45.8401 2.38067C45.7703 2.29776 45.7319 2.19206 45.7319 2.08275C45.7319 1.97343 45.7703 1.86773 45.8401 1.78482C45.9099 1.70192 46.0064 1.64729 46.1122 1.63086C46.2292 1.63083 46.3415 1.67756 46.4252 1.76104C46.5089 1.84453 46.5572 1.95814 46.5598 2.07755Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M46.5602 3.82329C46.5602 3.94452 46.5131 4.06078 46.4291 4.1465C46.3452 4.23222 46.2313 4.28037 46.1126 4.28037C45.9939 4.28037 45.8801 4.23222 45.7961 4.1465C45.7122 4.06078 45.665 3.94452 45.665 3.82329C45.665 3.70207 45.7122 3.58581 45.7961 3.50009C45.8801 3.41437 45.9939 3.36621 46.1126 3.36621C46.2313 3.36621 46.3452 3.41437 46.4291 3.50009C46.5131 3.58581 46.5602 3.70207 46.5602 3.82329Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M46.5602 5.57817C46.5602 5.6382 46.5487 5.69764 46.5262 5.75309C46.5037 5.80855 46.4707 5.85894 46.4291 5.90138C46.3876 5.94382 46.3382 5.97749 46.2839 6.00046C46.2296 6.02343 46.1714 6.03525 46.1126 6.03525C45.9939 6.03525 45.8801 5.9871 45.7961 5.90138C45.7122 5.81566 45.665 5.6994 45.665 5.57817C45.665 5.45695 45.7122 5.34069 45.7961 5.25497C45.8801 5.16925 45.9939 5.12109 46.1126 5.12109C46.2313 5.12109 46.3452 5.16925 46.4291 5.25497C46.5131 5.34069 46.5602 5.45695 46.5602 5.57817Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M46.5598 7.45841C46.5598 7.57964 46.5127 7.6959 46.4287 7.78162C46.3448 7.86733 46.231 7.91549 46.1122 7.91549C46.0064 7.89906 45.9099 7.84443 45.8401 7.76153C45.7703 7.67862 45.7319 7.57292 45.7319 7.4636C45.7319 7.35429 45.7703 7.24859 45.8401 7.16568C45.9099 7.08278 46.0064 7.02815 46.1122 7.01172C46.2292 7.01169 46.3415 7.05842 46.4252 7.1419C46.5089 7.22539 46.5572 7.33899 46.5598 7.45841Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M46.5602 9.34966C46.5602 9.47088 46.5131 9.58714 46.4291 9.67286C46.3452 9.75858 46.2313 9.80674 46.1126 9.80674C45.9939 9.80674 45.8801 9.75858 45.7961 9.67286C45.7122 9.58714 45.665 9.47088 45.665 9.34966C45.665 9.22843 45.7122 9.11217 45.7961 9.02645C45.8801 8.94073 45.9939 8.89258 46.1126 8.89258C46.1714 8.89258 46.2296 8.9044 46.2839 8.92737C46.3382 8.95034 46.3876 8.98401 46.4291 9.02645C46.4707 9.0689 46.5037 9.11929 46.5262 9.17474C46.5487 9.2302 46.5602 9.28963 46.5602 9.34966Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M46.5602 11.292C46.5602 11.4133 46.5131 11.5295 46.4291 11.6152C46.3452 11.701 46.2313 11.7491 46.1126 11.7491C45.9939 11.7491 45.8801 11.701 45.7961 11.6152C45.7122 11.5295 45.665 11.4133 45.665 11.292C45.665 11.1708 45.7122 11.0546 45.7961 10.9688C45.8801 10.8831 45.9939 10.835 46.1126 10.835C46.2313 10.835 46.3452 10.8831 46.4291 10.9688C46.5131 11.0546 46.5602 11.1708 46.5602 11.292Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M46.5602 13.2344C46.5602 13.3556 46.5131 13.4719 46.4291 13.5576C46.3452 13.6433 46.2313 13.6915 46.1126 13.6915C45.9939 13.6915 45.8801 13.6433 45.7961 13.5576C45.7122 13.4719 45.665 13.3556 45.665 13.2344C45.665 13.1132 45.7122 12.9969 45.7961 12.9112C45.8801 12.8255 45.9939 12.7773 46.1126 12.7773C46.1714 12.7773 46.2296 12.7892 46.2839 12.8121C46.3382 12.8351 46.3876 12.8688 46.4291 12.9112C46.4707 12.9537 46.5037 13.0041 46.5262 13.0595C46.5487 13.115 46.5602 13.1744 46.5602 13.2344Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M46.5598 15.3334C46.5598 15.4546 46.5127 15.5709 46.4287 15.6566C46.3448 15.7423 46.231 15.7905 46.1122 15.7905C46.0064 15.7741 45.9099 15.7194 45.8401 15.6365C45.7703 15.5536 45.7319 15.4479 45.7319 15.3386C45.7319 15.2293 45.7703 15.1236 45.8401 15.0407C45.9099 14.9578 46.0064 14.9032 46.1122 14.8867C46.2292 14.8867 46.3415 14.9334 46.4252 15.0169C46.5089 15.1004 46.5572 15.214 46.5598 15.3334Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M46.5602 17.4415C46.5602 17.5627 46.5131 17.6789 46.4291 17.7647C46.3452 17.8504 46.2313 17.8985 46.1126 17.8985C45.9939 17.8985 45.8801 17.8504 45.7961 17.7647C45.7122 17.6789 45.665 17.5627 45.665 17.4415C45.665 17.3202 45.7122 17.204 45.7961 17.1182C45.8801 17.0325 45.9939 16.9844 46.1126 16.9844C46.2313 16.9844 46.3452 17.0325 46.4291 17.1182C46.5131 17.204 46.5602 17.3202 46.5602 17.4415Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M46.5598 19.6543C46.5572 19.7738 46.5089 19.8874 46.4252 19.9709C46.3415 20.0543 46.2292 20.1011 46.1122 20.101C46.0064 20.0846 45.9099 20.03 45.8401 19.9471C45.7703 19.8642 45.7319 19.7585 45.7319 19.6492C45.7319 19.5398 45.7703 19.4341 45.8401 19.3512C45.9099 19.2683 46.0064 19.2137 46.1122 19.1973C46.231 19.1973 46.3448 19.2454 46.4287 19.3311C46.5127 19.4169 46.5598 19.5331 46.5598 19.6543Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M46.5602 21.919C46.5602 21.979 46.5487 22.0385 46.5262 22.0939C46.5037 22.1494 46.4707 22.1998 46.4291 22.2422C46.3876 22.2846 46.3382 22.3183 46.2839 22.3413C46.2296 22.3643 46.1714 22.3761 46.1126 22.3761C45.9939 22.3761 45.8801 22.3279 45.7961 22.2422C45.7122 22.1565 45.665 22.0402 45.665 21.919C45.665 21.7978 45.7122 21.6815 45.7961 21.5958C45.8801 21.5101 45.9939 21.4619 46.1126 21.4619C46.2313 21.4619 46.3452 21.5101 46.4291 21.5958C46.5131 21.6815 46.5602 21.7978 46.5602 21.919Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M46.5602 24.2872C46.5602 24.4084 46.5131 24.5246 46.4291 24.6104C46.3452 24.6961 46.2313 24.7442 46.1126 24.7442C45.9939 24.7442 45.8801 24.6961 45.7961 24.6104C45.7122 24.5246 45.665 24.4084 45.665 24.2872C45.665 24.1659 45.7122 24.0497 45.7961 23.964C45.8801 23.8782 45.9939 23.8301 46.1126 23.8301C46.2313 23.8301 46.3452 23.8782 46.4291 23.964C46.5131 24.0497 46.5602 24.1659 46.5602 24.2872Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M46.5598 27.0088C46.5572 27.1283 46.5088 27.2419 46.4252 27.3253C46.3415 27.4088 46.2292 27.4556 46.1122 27.4555C45.9962 27.4555 45.885 27.4085 45.8029 27.3247C45.7209 27.2409 45.6748 27.1273 45.6748 27.0088C45.6748 26.8894 45.7205 26.7747 45.8023 26.6893C45.884 26.6038 45.9953 26.5545 46.1122 26.5518C46.2309 26.5518 46.3448 26.5999 46.4287 26.6856C46.5127 26.7714 46.5598 26.8876 46.5598 27.0088Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M46.5602 29.9493C46.5602 30.0705 46.5131 30.1868 46.4291 30.2725C46.3452 30.3582 46.2313 30.4063 46.1126 30.4063C45.9939 30.4063 45.8801 30.3582 45.7961 30.2725C45.7122 30.1868 45.665 30.0705 45.665 29.9493C45.665 29.828 45.7122 29.7118 45.7961 29.6261C45.8801 29.5403 45.9939 29.4922 46.1126 29.4922C46.2313 29.4922 46.3452 29.5403 46.4291 29.6261C46.5131 29.7118 46.5602 29.828 46.5602 29.9493Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M46.5598 33.46C46.5572 33.5794 46.5088 33.693 46.4252 33.7765C46.3415 33.86 46.2292 33.9067 46.1122 33.9067C45.9962 33.9067 45.885 33.8596 45.8029 33.7759C45.7209 33.6921 45.6748 33.5785 45.6748 33.46C45.6748 33.3406 45.7205 33.2258 45.8023 33.1404C45.884 33.055 45.9953 33.0056 46.1122 33.0029C46.2309 33.0029 46.3448 33.0511 46.4287 33.1368C46.5127 33.2225 46.5598 33.3388 46.5598 33.46Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M46.5602 37.2315C46.5602 37.3527 46.5131 37.469 46.4291 37.5547C46.3452 37.6404 46.2313 37.6886 46.1126 37.6886C45.9939 37.6886 45.8801 37.6404 45.7961 37.5547C45.7122 37.469 45.665 37.3527 45.665 37.2315C45.665 37.1103 45.7122 36.994 45.7961 36.9083C45.8801 36.8226 45.9939 36.7744 46.1126 36.7744C46.2313 36.7744 46.3452 36.8226 46.4291 36.9083C46.5131 36.994 46.5602 37.1103 46.5602 37.2315Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M46.5602 41.5528C46.5602 41.674 46.5131 41.7903 46.4291 41.876C46.3452 41.9617 46.2313 42.0099 46.1126 42.0099C45.9939 42.0099 45.8801 41.9617 45.7961 41.876C45.7122 41.7903 45.665 41.674 45.665 41.5528C45.665 41.4316 45.7122 41.3153 45.7961 41.2296C45.8801 41.1439 45.9939 41.0957 46.1126 41.0957C46.1714 41.0957 46.2296 41.1075 46.2839 41.1305C46.3382 41.1535 46.3876 41.1871 46.4291 41.2296C46.4707 41.272 46.5037 41.3224 46.5262 41.3779C46.5487 41.4333 46.5602 41.4928 46.5602 41.5528Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M46.5602 46.4043C46.5602 46.5256 46.5131 46.6418 46.4291 46.7276C46.3452 46.8133 46.2313 46.8614 46.1126 46.8614C45.9939 46.8614 45.8801 46.8133 45.7961 46.7276C45.7122 46.6418 45.665 46.5256 45.665 46.4043C45.665 46.2831 45.7122 46.1669 45.7961 46.0811C45.8801 45.9954 45.9939 45.9473 46.1126 45.9473C46.2313 45.9473 46.3452 45.9954 46.4291 46.0811C46.5131 46.1669 46.5602 46.2831 46.5602 46.4043Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M46.5602 51.796C46.5602 51.9172 46.5131 52.0334 46.4291 52.1192C46.3452 52.2049 46.2313 52.253 46.1126 52.253C45.9939 52.253 45.8801 52.2049 45.7961 52.1192C45.7122 52.0334 45.665 51.9172 45.665 51.796C45.665 51.6747 45.7122 51.5585 45.7961 51.4727C45.8801 51.387 45.9939 51.3389 46.1126 51.3389C46.1714 51.3389 46.2296 51.3507 46.2839 51.3737C46.3382 51.3966 46.3876 51.4303 46.4291 51.4727C46.4707 51.5152 46.5037 51.5656 46.5262 51.621C46.5487 51.6765 46.5602 51.7359 46.5602 51.796Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M46.5602 56.543C46.5602 56.6642 46.5131 56.7805 46.4291 56.8662C46.3452 56.9519 46.2313 57.0001 46.1126 57.0001C45.9939 57.0001 45.8801 56.9519 45.7961 56.8662C45.7122 56.7805 45.665 56.6642 45.665 56.543C45.665 56.4218 45.7122 56.3055 45.7961 56.2198C45.8801 56.1341 45.9939 56.0859 46.1126 56.0859C46.2313 56.0859 46.3452 56.1341 46.4291 56.2198C46.5131 56.3055 46.5602 56.4218 46.5602 56.543Z" fill="#1A1A18" />
                <path d="M38.4016 25.0879H12.2783V26.2098H38.4016V25.0879Z" fill="#1A1A18" />
                <path d="M39.8257 26.9883H10.8032V28.1102H39.8257V26.9883Z" fill="#1A1A18" />
                <path d="M41.2501 28.8896H9.43018V30.0116H41.2501V28.8896Z" fill="#1A1A18" />
                <path d="M38.4016 10.9902H12.2783V12.1122H38.4016V10.9902Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5683 10.2742L25.3402 7.89526L32.9594 10.2742H36.1333L25.3402 6.85645L14.3843 10.2742H17.5683Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2783 10.2738L25.2586 6.21205L38.4016 10.2738V9.0688L25.3399 4.95508L12.2783 9.0688V10.2738Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8145 23.6437C13.8167 23.7626 13.843 23.8796 13.8917 23.9876C13.9403 24.0955 14.0103 24.192 14.0973 24.271C14.1842 24.3501 14.2862 24.4099 14.3968 24.4467C14.5074 24.4836 14.6243 24.4967 14.7402 24.4852C14.8544 24.4989 14.9702 24.487 15.0795 24.4504C15.1888 24.4139 15.289 24.3535 15.3732 24.2735C15.4574 24.1934 15.5235 24.0956 15.567 23.9869C15.6105 23.8782 15.6304 23.7611 15.6252 23.6437V12.7881H14.9334V23.6645C14.9334 23.6645 14.9334 23.8619 14.6893 23.8619C14.4452 23.8619 14.496 23.6126 14.496 23.6126V12.7881H13.8145V23.6437Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1074 24.4852H18.6567V17.4524L19.3485 24.4852H19.9181V12.7881H19.3485V19.0418L18.6567 12.7881H18.1074V24.4852Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.4712 24.4852H24.2209V23.8619H23.2341V18.5847H23.9767V17.9406H23.2341V13.3802H23.9767V12.7881H22.4712V24.4852Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M32.5525 16.2574V13.6292C32.5473 13.5132 32.5197 13.3994 32.4714 13.2943C32.423 13.1892 32.3549 13.0948 32.2708 13.0167C32.1867 12.9385 32.0883 12.8781 31.9813 12.8388C31.8743 12.7995 31.7607 12.7822 31.6472 12.7877C31.5348 12.7806 31.4221 12.7971 31.3162 12.8362C31.2103 12.8753 31.1133 12.9362 31.0314 13.015C30.9494 13.0939 30.8841 13.1891 30.8395 13.2947C30.795 13.4003 30.7721 13.5142 30.7723 13.6292V23.6434C30.769 23.7593 30.7898 23.8746 30.8332 23.9817C30.8767 24.0888 30.942 24.1853 31.0246 24.2648C31.1073 24.3443 31.2055 24.4051 31.3128 24.443C31.4201 24.4809 31.5341 24.4952 31.6472 24.4848C31.7617 24.4949 31.877 24.4806 31.9859 24.443C32.0948 24.4053 32.1948 24.3451 32.2799 24.2661C32.3649 24.1871 32.433 24.091 32.4799 23.9838C32.5268 23.8767 32.5515 23.7608 32.5525 23.6434V20.7035H31.871V23.6434C31.871 23.6729 31.8651 23.7022 31.8538 23.7294C31.8424 23.7565 31.8258 23.781 31.8048 23.8014C31.7839 23.8218 31.7591 23.8377 31.732 23.848C31.7049 23.8584 31.6761 23.863 31.6472 23.8615C31.5885 23.858 31.5327 23.8347 31.4885 23.7952C31.4443 23.7557 31.4142 23.7023 31.403 23.6434V13.6292C31.403 13.5631 31.4287 13.4996 31.4745 13.4529C31.5203 13.4061 31.5824 13.3799 31.6472 13.3799C31.871 13.3799 31.871 13.6292 31.871 13.6292V16.2574H32.5525Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M36.235 23.6432C36.235 23.6432 36.235 23.8613 35.9909 23.8613C35.7468 23.8613 35.7162 23.6432 35.7162 23.6432V13.629C35.7162 13.5953 35.7228 13.5621 35.7357 13.5311C35.7486 13.5002 35.7675 13.4722 35.7913 13.4489C35.8151 13.4256 35.8432 13.4075 35.874 13.3956C35.9048 13.3837 35.9376 13.3783 35.9705 13.3797C36.0043 13.3768 36.0382 13.3811 36.0702 13.3923C36.1022 13.4036 36.1316 13.4215 36.1565 13.4449C36.1814 13.4684 36.2012 13.4968 36.2147 13.5285C36.2282 13.5602 36.2352 13.5944 36.235 13.629V23.6432ZM36.8963 13.629C36.9019 13.5096 36.8817 13.3905 36.837 13.2801C36.7924 13.1697 36.7243 13.0707 36.6378 12.9903C36.5513 12.9099 36.4484 12.85 36.3366 12.815C36.2247 12.7799 36.1067 12.7705 35.9909 12.7875C35.873 12.773 35.7534 12.7838 35.6398 12.8192C35.5262 12.8545 35.421 12.9137 35.331 12.9928C35.241 13.072 35.1682 13.1695 35.1172 13.279C35.0662 13.3885 35.0381 13.5077 35.0347 13.629V23.6432C35.0356 23.7606 35.0604 23.8765 35.1073 23.9836C35.1542 24.0908 35.2223 24.1869 35.3073 24.2659C35.3923 24.3449 35.4924 24.4051 35.6013 24.4428C35.7102 24.4804 35.8255 24.4947 35.94 24.4846C36.058 24.4991 36.1775 24.4883 36.2912 24.453C36.4048 24.4176 36.5099 24.3585 36.5999 24.2793C36.6899 24.2001 36.7627 24.1027 36.8137 23.9932C36.8648 23.8836 36.8929 23.7644 36.8963 23.6432V13.629Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M27.5271 23.6432C27.5271 23.6432 27.5271 23.8614 27.3542 23.851C27.3038 23.8458 27.2572 23.8217 27.2233 23.7832C27.1895 23.7448 27.1708 23.6949 27.1711 23.6432V20.7034H26.4488V23.6432C26.447 23.7596 26.4691 23.8751 26.5137 23.9822C26.5584 24.0894 26.6246 24.1857 26.708 24.265C26.7914 24.3444 26.8903 24.4049 26.9981 24.4428C27.1059 24.4806 27.2203 24.4949 27.3339 24.4847C27.4476 24.4932 27.5618 24.4778 27.6695 24.4395C27.7771 24.4012 27.876 24.3407 27.9598 24.2619C28.0437 24.183 28.1109 24.0874 28.1572 23.981C28.2035 23.8746 28.228 23.7596 28.229 23.6432V21.1085C28.229 19.7373 27.1609 17.1298 27.1609 16.2572V13.629C27.1609 13.629 27.1609 13.3797 27.3339 13.3797C27.5068 13.3797 27.517 13.629 27.517 13.629V16.2572H28.229V13.629C28.234 13.5126 28.2144 13.3965 28.1715 13.2886C28.1286 13.1807 28.0634 13.0835 27.9804 13.0036C27.8973 12.9238 27.7984 12.8631 27.6904 12.8258C27.5823 12.7886 27.4676 12.7755 27.3542 12.7876C27.2403 12.7806 27.1262 12.7969 27.0186 12.8357C26.911 12.8744 26.8121 12.9348 26.7277 13.0132C26.6433 13.0917 26.5752 13.1866 26.5273 13.2923C26.4794 13.3981 26.4527 13.5125 26.4488 13.629V15.8625C26.4488 17.3376 27.4661 19.81 27.4661 20.9423L27.5271 23.6432Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M53.1314 12.861H53.1924C53.1994 12.8811 53.1994 12.9031 53.1924 12.9233C53.1981 12.9472 53.1981 12.9721 53.1924 12.996C53.1966 13.0201 53.1966 13.0447 53.1924 13.0687C53.1954 13.0894 53.1954 13.1104 53.1924 13.1311H53.1314H52.989H52.9178V13.0687C52.9128 13.0448 52.9128 13.02 52.9178 12.996C52.9122 12.9721 52.9122 12.9472 52.9178 12.9233C52.9159 12.8991 52.9159 12.8748 52.9178 12.8506H52.9788H53.1314V12.861Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M52.4704 14.8347L52.2262 14.6892L51.9821 14.5438L51.7685 14.4088V14.3049C51.7724 14.2878 51.7724 14.27 51.7685 14.2529H51.9923H52.2873H52.6128H52.9078H53.1316H53.2435C53.2435 14.2529 53.2435 14.3153 53.2435 14.336C53.2387 14.367 53.2387 14.3986 53.2435 14.4295C53.2435 14.4295 53.2435 14.4295 53.2435 14.5126C53.2435 14.5957 53.2435 14.5126 53.2435 14.5957H52.684H52.4093H52.1652L52.4195 14.7516L52.684 14.9282L52.9281 15.0736C52.9848 15.1157 53.0463 15.1506 53.1113 15.1775V15.2294V15.2814H52.8061H52.6026H52.389H52.1855H52.0228L52.2771 15.4268L52.5619 15.593L52.8061 15.7281L52.979 15.8215C52.9705 15.8486 52.9705 15.8776 52.979 15.9047V15.9566L52.8569 15.8839L52.6738 15.7592L52.4297 15.6138L52.0635 15.5826L51.799 15.4268L51.5752 15.2917V15.1879C51.5752 15.1879 51.5752 15.1879 51.5752 15.1359H52.094L52.6331 15.0736L52.4704 14.8347Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M52.1449 17.2549C52.2255 17.2711 52.3084 17.2711 52.389 17.2549C52.4563 17.2446 52.5196 17.2159 52.5722 17.1718C52.6229 17.1375 52.6648 17.0912 52.6942 17.0367C52.7275 16.9802 52.745 16.9156 52.7451 16.8497C52.7653 16.7856 52.7653 16.7165 52.7451 16.6524C52.713 16.597 52.6717 16.5477 52.623 16.5069C52.5644 16.4561 52.4946 16.4205 52.4196 16.403C52.3385 16.3923 52.2565 16.3923 52.1754 16.403C52.0808 16.3916 51.9852 16.3916 51.8906 16.403C51.8233 16.4193 51.7606 16.4513 51.7075 16.4965C51.6574 16.5286 51.6182 16.5758 51.5956 16.6316C51.5852 16.6865 51.5852 16.7429 51.5956 16.7978C51.5859 16.8597 51.5859 16.9228 51.5956 16.9848C51.6226 17.0437 51.6606 17.0966 51.7075 17.1406C51.7678 17.1912 51.8368 17.23 51.9109 17.2549C52.002 17.2899 52.0985 17.3075 52.1958 17.3068L52.1449 17.2549ZM52.1449 15.9875C52.24 15.9945 52.3331 16.0191 52.4196 16.0602C52.5054 16.1073 52.5842 16.1668 52.6535 16.2368C52.7166 16.3173 52.7649 16.4088 52.7959 16.5069C52.8166 16.6167 52.8166 16.7295 52.7959 16.8393C52.7874 16.9453 52.7669 17.0499 52.7349 17.151C52.6986 17.2425 52.647 17.3269 52.5823 17.4003C52.5177 17.4756 52.4328 17.5298 52.3382 17.5561C52.2313 17.5834 52.1195 17.5834 52.0127 17.5561C51.9135 17.5544 51.8161 17.5295 51.7278 17.4834C51.6411 17.4426 51.5646 17.3822 51.504 17.3068C51.4409 17.2263 51.3927 17.1348 51.3616 17.0367C51.3411 16.9304 51.3411 16.821 51.3616 16.7147C51.3702 16.6088 51.3906 16.5042 51.4226 16.403C51.4493 16.3092 51.4982 16.2236 51.5651 16.1537C51.6298 16.0811 51.7098 16.0243 51.799 15.9875C51.9232 15.9612 52.0513 15.9612 52.1754 15.9875H52.1449Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M52.4804 18.481C52.527 18.4943 52.5762 18.4943 52.6228 18.481C52.64 18.4772 52.6561 18.4696 52.6702 18.4589C52.6842 18.4481 52.6958 18.4344 52.7042 18.4187C52.7069 18.3841 52.7069 18.3493 52.7042 18.3148C52.7089 18.2664 52.7089 18.2177 52.7042 18.1693H52.1447V18.2213C52.1494 18.2996 52.182 18.3735 52.2362 18.429C52.2683 18.4525 52.3046 18.4692 52.343 18.4781C52.3814 18.4871 52.4212 18.488 52.46 18.481H52.4804ZM52.0735 18.1797H51.9921H51.6869H51.4326C51.437 18.1522 51.437 18.1241 51.4326 18.0966V18.0031C51.4326 18.0031 51.4326 17.9408 51.4326 17.9096C51.4382 17.8857 51.4382 17.8608 51.4326 17.8369H51.6971H52.0023H52.2871H52.5923H52.8568V18.0031C52.8568 18.0031 52.8568 18.0862 52.8568 18.1382C52.863 18.1969 52.863 18.2561 52.8568 18.3148C52.8568 18.3667 52.8568 18.4187 52.8568 18.4498C52.8568 18.481 52.8568 18.5225 52.8568 18.5537C52.8628 18.5775 52.8628 18.6026 52.8568 18.6264C52.8612 18.6469 52.8612 18.6682 52.8568 18.6887C52.8272 18.7312 52.789 18.7666 52.7449 18.7926C52.7009 18.8263 52.6473 18.8446 52.5923 18.8446C52.5032 18.8522 52.414 18.8303 52.338 18.7822C52.2623 18.7223 52.2086 18.6382 52.1854 18.5433L51.8497 18.7822L51.5242 19.0419V18.9069C51.5192 18.8759 51.5192 18.8444 51.5242 18.8134V18.6991C51.5291 18.6682 51.5291 18.6366 51.5242 18.6056L52.1549 18.1797H52.0735Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M52.6635 19.0731L52.8975 19.0107C52.8931 19.0383 52.8931 19.0663 52.8975 19.0938C52.8934 19.1249 52.8934 19.1563 52.8975 19.1873C52.8927 19.2183 52.8927 19.2499 52.8975 19.2808C52.8931 19.3049 52.8931 19.3295 52.8975 19.3536H52.633L52.3278 19.4055L52.0531 19.4574H51.8293H51.6361C51.6313 19.537 51.6313 19.6168 51.6361 19.6964C51.6361 19.7691 51.6361 19.8522 51.6971 19.9353H51.5547C51.5547 19.9353 51.5547 19.8418 51.5547 19.7899C51.5547 19.7379 51.5547 19.6652 51.5547 19.5613C51.5547 19.4574 51.5547 19.4159 51.5547 19.3639V19.2393V19.1666H51.8192L52.1142 19.1146L52.399 19.0627L52.6635 19.0731Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M52.2158 20.5898H52.1039H51.992H51.931C51.9323 20.614 51.9323 20.6383 51.931 20.6625V20.7664C51.9477 20.8358 51.9791 20.9007 52.0231 20.9563C52.067 21.0119 52.1224 21.0571 52.1853 21.0885C52.3468 21.1454 52.5223 21.1454 52.6838 21.0885C52.7603 21.0666 52.8325 21.0315 52.8974 20.9846C52.9544 20.947 53.003 20.8974 53.0398 20.8391C53.0707 20.7846 53.0914 20.7248 53.1008 20.6625C53.1152 20.5976 53.1152 20.5301 53.1008 20.4652C53.1038 20.4237 53.1038 20.382 53.1008 20.3405V20.2574H53.0398H52.9279H52.816H52.7143L52.3582 20.3613H52.2565L52.2158 20.5898ZM52.5413 20.1639L52.8363 20.0704L53.0907 19.9873C53.0873 20.0114 53.0873 20.0359 53.0907 20.06C53.0859 20.091 53.0859 20.1225 53.0907 20.1535C53.1047 20.203 53.1217 20.2516 53.1415 20.299C53.1673 20.37 53.1877 20.4429 53.2026 20.5171C53.2405 20.6202 53.2612 20.729 53.2636 20.8391C53.2848 20.9314 53.2848 21.0274 53.2636 21.1196C53.2319 21.2045 53.1793 21.2796 53.111 21.3378C53.0245 21.4104 52.924 21.4635 52.816 21.4936C52.7118 21.5136 52.6049 21.5136 52.5006 21.4936C52.4075 21.4866 52.3171 21.4582 52.2362 21.4105C52.1541 21.3668 52.0815 21.3068 52.0225 21.2339C51.9607 21.1581 51.9156 21.0695 51.8903 20.9742C51.8801 20.9119 51.8801 20.7768 51.8801 20.7768C51.8801 20.7768 51.8801 20.6418 51.8293 20.5898C51.8245 20.5588 51.8245 20.5273 51.8293 20.4963V20.4236L52.0836 20.3613L52.3786 20.2782L52.5413 20.1639Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M53.4673 23.0615L53.4063 22.9161L53.3249 22.7707L53.2435 22.6356L53.1418 22.6876L52.8976 22.8538L52.6738 22.9888C52.6772 22.9647 52.6772 22.9402 52.6738 22.9161V22.833C52.6757 22.8053 52.6757 22.7776 52.6738 22.7499C52.6758 22.7257 52.6758 22.7014 52.6738 22.6772L52.9078 22.5629L53.1825 22.4279L53.4368 22.2928L53.7013 22.1474L53.9352 22.002V22.0747V22.1578V22.2409V22.3136L53.7013 22.4279L53.4266 22.5629C53.449 22.6034 53.4693 22.645 53.4876 22.6876L53.569 22.8538C53.569 22.9265 53.6301 22.9784 53.6606 23.02L53.7216 23.1343L53.9861 22.9888L54.2201 22.8538C54.2214 22.878 54.2214 22.9023 54.2201 22.9265C54.2393 22.9527 54.2563 22.9805 54.2709 23.0096C54.2691 23.0373 54.2691 23.065 54.2709 23.0927V23.155L54.0268 23.2693L53.7521 23.4147L53.508 23.5498L53.2435 23.6952L53.0095 23.8303V23.7576C53.0077 23.7299 53.0077 23.7021 53.0095 23.6745C53.0059 23.6469 53.0059 23.6189 53.0095 23.5913C53.0066 23.5707 53.0066 23.5497 53.0095 23.529L53.2435 23.4147L53.5182 23.2693L53.6199 23.2174C53.5751 23.1595 53.5239 23.1072 53.4673 23.0615Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M54.2708 23.508L54.4844 23.3418L54.5759 23.4976L54.6777 23.6534L54.8404 23.882C54.8654 23.9306 54.8962 23.976 54.932 24.017H54.8608L54.7997 23.9028C54.7723 23.8635 54.7484 23.8218 54.7285 23.7781L54.6675 23.6742C54.6675 23.6742 54.6675 23.6223 54.6675 23.6119C54.5997 23.6438 54.535 23.682 54.4742 23.7262L54.2809 23.8612L54.4233 24.0586L54.5658 24.2456H54.5149C54.4335 24.1001 54.3521 23.9651 54.2708 23.8404L54.1589 23.9131L54.047 23.9859L53.8333 24.1417L53.9758 24.3391L54.1283 24.5261C54.1079 24.4826 54.084 24.441 54.0571 24.4014L53.9351 24.2144L53.813 24.0378C53.813 24.0378 53.7621 23.9651 53.752 23.9339V23.882L53.9758 23.7365L54.2301 23.5703L54.464 23.4041L54.2708 23.508Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M55.1762 24.9314L55.2678 24.8275C55.2768 24.7935 55.2768 24.7576 55.2678 24.7236C55.2743 24.6893 55.2743 24.654 55.2678 24.6197L55.1864 24.5055L54.7489 24.869C54.7704 24.8997 54.7977 24.9256 54.8291 24.9452C54.8606 24.9648 54.8956 24.9778 54.9321 24.9833C55.0156 24.9826 55.0958 24.9492 55.1559 24.8898L55.1762 24.9314ZM54.6676 24.9314H54.6065L54.3726 25.1391L54.1793 25.3157V25.2534L54.1182 25.1807L54.0572 25.108L54.0063 25.056L54.2098 24.8898L54.4438 24.6924L54.6676 24.5055L54.8914 24.3081L55.0948 24.1211V24.1834L55.1559 24.2561L55.2372 24.3496L55.3593 24.4847L55.4407 24.599C55.4438 24.6266 55.4438 24.6544 55.4407 24.6821V24.7548C55.4454 24.7753 55.4454 24.7966 55.4407 24.8171C55.4512 24.865 55.4512 24.9147 55.4407 24.9625C55.4193 25.0161 55.3841 25.0627 55.339 25.0976C55.2743 25.1639 55.1865 25.2012 55.0948 25.2015C55.0031 25.1881 54.9159 25.1525 54.8405 25.0976C54.8405 25.2326 54.7489 25.3573 54.7083 25.4923C54.6676 25.6274 54.6269 25.7728 54.5862 25.8975V25.8248L54.5251 25.752L54.4641 25.6793L54.4031 25.6066L54.6574 24.8898L54.6676 24.9314Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M55.6849 25.3263L55.868 25.1289V25.1912L55.9392 25.2536L56.0003 25.3263H56.0613L55.9494 25.5548L55.7256 25.773L55.5323 25.9807L55.3187 26.1989L55.1356 26.3963C55.1356 26.3963 55.1356 26.3963 55.1356 26.3443L55.0745 26.2716L54.9321 26.2093V26.1573L55.1254 25.9703L55.339 25.7522L55.5425 25.5548L55.6849 25.3263Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M56.3764 25.9707C56.3764 25.9707 56.3764 25.9707 56.3154 25.9188L56.2441 25.8565L56.1526 25.7941L56.0712 25.7422L56.1628 25.8253L56.2441 25.9084L56.3459 25.9915L56.4679 26.1058C56.5089 26.1444 56.5531 26.1792 56.6002 26.2097L56.7019 26.2928C56.7241 26.3223 56.7517 26.347 56.7833 26.3655L56.8748 26.4486L56.8138 26.3759L56.7324 26.3031L56.6612 26.2408V26.1889L56.59 26.272L56.2543 26.6771L56.061 26.9161L55.8983 27.1342V27.0719C55.8699 27.0574 55.8454 27.036 55.8271 27.0095L55.7559 26.9576L55.6948 26.9057L55.8678 26.7083L56.0712 26.4797L56.4069 26.0746L56.3764 25.9707Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M57.3023 26.8847C57.2241 26.927 57.1492 26.9757 57.0785 27.0301L56.8649 27.186L57.1803 27.4041L57.2413 27.1444L57.3023 26.8847ZM57.3023 28.1728L57.221 28.1209L57.1803 28.0482H57.1091H57.0277L57.0887 27.8404L57.1497 27.6119L57.0378 27.5391C57.0046 27.509 56.9668 27.4844 56.9259 27.4664L56.8446 27.4041L56.753 27.3418L56.5699 27.4768L56.397 27.6119L56.5292 27.5288L56.7327 27.3937L56.9666 27.2379L57.2108 27.0717L57.4549 26.8951L57.6889 26.7393C57.6889 26.8639 57.6279 26.9886 57.5872 27.134L57.4956 27.5599C57.4956 27.695 57.4346 27.83 57.4142 27.9547C57.3939 28.0793 57.343 28.0482 57.3329 28.1728H57.3023Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M59.0724 27.6325C59.0218 27.5649 58.9553 27.5113 58.8792 27.4767C58.8229 27.4452 58.7602 27.4275 58.6961 27.4248C58.6291 27.4088 58.5595 27.4088 58.4926 27.4248C58.417 27.4561 58.3479 27.502 58.2891 27.5598C58.2169 27.625 58.1611 27.7069 58.1264 27.7987C58.0892 27.8661 58.065 27.9402 58.0552 28.0169C58.0401 28.0888 58.0401 28.1631 58.0552 28.235C58.0982 28.3739 58.1933 28.4897 58.3197 28.5571H58.3705H58.4316H58.4926V28.4844L58.5943 28.2558C58.5978 28.2213 58.5978 28.1865 58.5943 28.1519H58.6757L58.7774 28.2039H58.8283V28.287C58.8153 28.3202 58.7982 28.3516 58.7774 28.3805V28.474V28.5363V28.6194C58.7774 28.6194 58.7774 28.6714 58.7774 28.6817L58.5028 28.609C58.4141 28.5853 58.3287 28.5504 58.2485 28.5051C58.148 28.4576 58.0554 28.3945 57.9738 28.3182C57.9017 28.2465 57.8431 28.162 57.8009 28.0688C57.7684 27.9826 57.7511 27.8911 57.75 27.7987C57.7531 27.6977 57.7774 27.5985 57.8212 27.5079C57.8729 27.3974 57.9455 27.2986 58.0348 27.217C58.1154 27.1517 58.2092 27.1056 58.3095 27.082C58.4096 27.056 58.5145 27.056 58.6147 27.082C58.7285 27.0959 58.8388 27.1311 58.9402 27.1858C59.024 27.228 59.1026 27.2803 59.1742 27.3417C59.2361 27.3965 59.2908 27.4594 59.3369 27.5286L59.2555 27.6118L59.1843 27.6949C59.1495 27.6697 59.112 27.6488 59.0724 27.6325Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M59.4995 27.9443C59.4995 27.8612 59.4995 27.7781 59.5809 27.6846L59.7538 27.7573L59.9268 27.83L60.1811 27.9131L60.3337 27.9651V28.0482C60.3347 28.0655 60.3347 28.0828 60.3337 28.1001L60.1506 28.0482H60.0183L59.9064 27.9962H59.8352C59.8005 28.0635 59.7699 28.1328 59.7437 28.204C59.721 28.2784 59.6938 28.3512 59.6623 28.4221L59.8962 28.5052L60.1099 28.5676C60.1074 28.5848 60.1074 28.6023 60.1099 28.6195V28.7026L59.6724 28.5156C59.6724 28.5156 59.6724 28.5987 59.6724 28.6507V28.7753L59.5809 29.0246L59.8149 29.0974C59.8877 29.1243 59.9625 29.1451 60.0387 29.1597C60.0412 29.1769 60.0412 29.1944 60.0387 29.2116H59.9166L59.703 29.1285L59.3164 29.087H59.2045H59.1333C59.1715 29.0058 59.2055 28.9226 59.235 28.8377L59.3469 28.5468L59.4385 28.2767L59.4995 27.9443Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M60.9644 28.7858V28.7131V28.6611H61.0356C61.0617 28.6507 61.0908 28.6507 61.1169 28.6611C61.1367 28.654 61.1582 28.654 61.178 28.6611C61.1947 28.6575 61.2121 28.6575 61.2288 28.6611V28.7339V28.8066V28.8793C61.2288 28.8793 61.2288 28.8793 61.178 28.9312H61.1068H61.0254C61.0056 28.9384 60.9841 28.9384 60.9644 28.9312C60.9644 28.9312 60.9644 28.9312 60.9644 28.8689V28.7858Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M62.8665 28.8584C62.8724 28.8136 62.8724 28.7682 62.8665 28.7234C62.8739 28.6927 62.8739 28.6606 62.8665 28.6299H62.8055H62.7241H62.6326V28.9312C62.6376 29.0281 62.6376 29.1251 62.6326 29.222H62.7445H62.8462C62.8783 29.1958 62.9058 29.1642 62.9276 29.1285C62.9359 29.077 62.9359 29.0243 62.9276 28.9727L62.8665 28.8584ZM62.2358 28.9831C62.2358 28.8688 62.2358 28.765 62.2358 28.6715C62.2409 28.5815 62.2409 28.4913 62.2358 28.4014H62.7038H62.9377C62.9828 28.417 63.0243 28.4418 63.0598 28.4741C63.0971 28.5077 63.1252 28.5506 63.1412 28.5987C63.1565 28.6531 63.1565 28.7106 63.1412 28.765C63.1589 28.833 63.1589 28.9046 63.1412 28.9727C63.1116 29.0277 63.0692 29.0745 63.0177 29.1089C62.9663 29.1433 62.9074 29.1643 62.8462 29.1701C62.7753 29.1805 62.7034 29.1805 62.6326 29.1701H62.5207C62.5207 29.274 62.5207 29.3779 62.5207 29.4817C62.5207 29.5856 62.5207 29.6895 62.5207 29.7934H62.1748C62.1797 29.7069 62.1797 29.6202 62.1748 29.5337C62.1748 29.4367 62.1748 29.3328 62.1748 29.222L62.2358 28.9831Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M63.8329 28.8683C63.7946 28.9495 63.7607 29.0327 63.7311 29.1176C63.6994 29.2026 63.6722 29.2893 63.6497 29.3773H64.0261L63.9346 29.0865C63.8964 29.0053 63.8624 28.922 63.8329 28.8371V28.8683ZM64.5856 29.9071H64.2296L64.1584 29.689C64.1584 29.6266 64.1584 29.5539 64.077 29.4812H63.5989C63.5706 29.5523 63.5468 29.6252 63.5277 29.6994L63.4666 29.9175H63.2632C63.2907 29.8688 63.3112 29.8163 63.3242 29.7617L63.4158 29.5435C63.4158 29.4604 63.4768 29.3669 63.5175 29.2734L63.6192 28.993C63.6192 28.8891 63.6904 28.7956 63.721 28.7021L63.8227 28.4424H63.9753L64.1279 28.8267C64.1787 28.9618 64.2398 29.0968 64.2906 29.2215C64.3415 29.3462 64.3923 29.4812 64.4432 29.5955C64.4941 29.7097 64.5449 29.824 64.5856 29.9071Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M64.9624 28.6091C64.9637 28.5849 64.9637 28.5606 64.9624 28.5364H64.8912H64.7895H64.6877H64.5962V28.5676V28.5156H64.8403H64.9726H65.1353H65.2981H65.4202H65.5321H65.6541V28.7026H65.2879V28.8896L65.3591 29.409C65.3591 29.5233 65.3591 29.6272 65.41 29.7207C65.4609 29.8141 65.41 29.8973 65.41 29.9804H65.1455H65.0743C65.0743 29.8869 65.0743 29.8038 65.0743 29.7103C65.0743 29.6168 65.0743 29.5129 65.0743 29.3986L65.0031 28.8792C65.0031 28.8792 64.9624 28.6403 64.9624 28.6091Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M66.4676 28.4847C66.4767 28.4401 66.4767 28.3942 66.4676 28.3496C66.4483 28.3176 66.4199 28.2922 66.3862 28.2769H66.2845H66.1421C66.1421 28.4639 66.2235 28.6509 66.2743 28.8379C66.3123 28.8332 66.3486 28.8195 66.3804 28.7978C66.4122 28.7762 66.4386 28.7472 66.4574 28.7132C66.4734 28.6755 66.4816 28.6348 66.4816 28.5938C66.4816 28.5527 66.4734 28.512 66.4574 28.4743L66.4676 28.4847ZM66.2438 28.9418V29.0249C66.2438 29.1391 66.2438 29.243 66.315 29.3261C66.3862 29.4092 66.3659 29.5027 66.3964 29.5858H66.315H66.2235H66.1421H66.0607C66.0655 29.4993 66.0655 29.4126 66.0607 29.3261C66.0607 29.2326 66.0607 29.1391 65.9997 29.0249L65.9285 28.7444C65.9285 28.6301 65.9285 28.5262 65.8471 28.4431C65.7657 28.36 65.8471 28.2665 65.7759 28.173H65.8471H65.9386H66.0607L66.2336 28.1211H66.3659H66.5999C66.6484 28.1399 66.6907 28.1723 66.7219 28.2146C66.7575 28.256 66.782 28.3061 66.7931 28.36C66.811 28.4027 66.8203 28.4486 66.8203 28.4951C66.8203 28.5415 66.811 28.5874 66.7931 28.6301C66.7473 28.7099 66.6803 28.7747 66.5999 28.8171L66.8847 29.1184L67.1899 29.3988H67.0983H67.0068H66.9152H66.8237L66.315 28.8483L66.2438 28.9418Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M67.0373 28.1829C67.0077 28.098 66.9738 28.0148 66.9355 27.9336H67.0169H67.0983H67.1899H67.2611L67.3323 28.1933L67.434 28.4946L67.5154 28.7647C67.5154 28.8685 67.5866 28.9724 67.6273 29.0555L67.7188 29.3048H67.6476H67.5561H67.4645H67.4035C67.4035 29.2217 67.3526 29.1282 67.3221 29.0451C67.2979 28.9462 67.2673 28.849 67.2305 28.7543L67.139 28.4738C67.139 28.3699 67.0678 28.266 67.0373 28.1829Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M68.0342 28.5781L68.2071 29.0144C68.1461 28.7859 68.0749 28.5469 68.0037 28.3184C67.9325 28.0899 67.8612 27.8509 67.79 27.612L68.0037 27.799L68.2173 27.9756L68.4411 28.1522L68.6445 28.3184C68.6445 28.1418 68.6445 27.9548 68.6445 27.7574C68.6547 27.5706 68.6547 27.3833 68.6445 27.1965H68.7361L68.8073 27.1445L69.0107 27.477L69.2244 27.8302L69.4177 28.1522L69.5702 28.3807H69.499H69.4177H69.3464H69.2651C69.204 28.2457 69.1227 28.1106 69.0413 27.9652C68.9599 27.8198 68.8683 27.6743 68.787 27.5289V28.5677H68.7157L68.319 28.2353L67.9019 27.9029L68.0342 28.5781Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M70.3742 26.8843C70.3278 26.8183 70.2731 26.7589 70.2114 26.7077C70.1575 26.6614 70.0953 26.6261 70.0283 26.6038C69.9677 26.5935 69.9058 26.5935 69.8452 26.6038C69.7837 26.6171 69.7251 26.6418 69.6723 26.6765C69.6151 26.7152 69.5694 26.7691 69.54 26.8323C69.5294 26.8977 69.5294 26.9644 69.54 27.0297C69.5304 27.1021 69.5304 27.1755 69.54 27.2479C69.5705 27.3293 69.6116 27.4061 69.6621 27.4764C69.7129 27.5574 69.7746 27.6309 69.8452 27.6946C69.8991 27.7409 69.9613 27.7761 70.0283 27.7984C70.085 27.8139 70.1446 27.8139 70.2012 27.7984C70.2552 27.7868 70.3069 27.7657 70.3538 27.7361C70.4022 27.699 70.4436 27.6533 70.4759 27.6011C70.5082 27.5435 70.529 27.4799 70.5369 27.4141C70.5462 27.3382 70.5462 27.2614 70.5369 27.1855C70.5034 27.0872 70.4591 26.9931 70.4047 26.9051L70.3742 26.8843ZM69.3569 27.6322C69.3026 27.5511 69.2647 27.4596 69.2455 27.3633C69.2263 27.267 69.2261 27.1677 69.245 27.0713C69.2683 26.9709 69.3097 26.8757 69.3671 26.7908C69.4291 26.6963 69.5086 26.6152 69.6011 26.5519C69.6886 26.4921 69.7847 26.4465 69.8859 26.4168C69.9763 26.3962 70.0701 26.3962 70.1605 26.4168C70.2573 26.429 70.3507 26.4608 70.4352 26.5103C70.5254 26.5764 70.6045 26.6571 70.6692 26.7492C70.7197 26.8367 70.7574 26.9312 70.7811 27.0297C70.7965 27.126 70.7965 27.2243 70.7811 27.3206C70.759 27.4197 70.7212 27.5144 70.6692 27.6011C70.6036 27.6895 70.5246 27.7667 70.4352 27.8296C70.3456 27.8883 70.2501 27.9371 70.1504 27.975C70.0599 27.9953 69.9662 27.9953 69.8757 27.975C69.7773 27.9637 69.683 27.928 69.6011 27.8712C69.5071 27.8097 69.4273 27.7282 69.3671 27.6322H69.3569Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M70.5879 26.1987L70.4048 25.9702H70.4658L70.537 25.9079L70.6082 25.8455V25.7832C70.6608 25.86 70.7186 25.9329 70.7812 26.0014C70.8411 26.088 70.9056 26.1712 70.9744 26.2507L71.1474 26.4688L71.3508 26.6974L71.5238 26.8947C71.5238 26.8947 71.5238 26.8947 71.4627 26.9467L71.3915 27.009L71.3203 27.0713V27.1233L71.2084 26.8947L71.0151 26.6558L70.832 26.4377L70.5879 26.1987Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M71.208 25.8668L71.0249 25.7006C70.9868 25.6564 70.946 25.6148 70.9028 25.5759L70.974 25.5136C70.9992 25.494 71.02 25.4692 71.0351 25.4409H71.6963H72.3677L72.2253 25.3578L72.0829 25.2123C72.0329 25.1665 71.9853 25.1179 71.9404 25.0669L71.8184 24.9318L71.7065 24.8384L71.6454 24.7864L71.7166 24.7241L71.7777 24.6514L72.0116 24.9007L72.2049 25.1084L72.3982 25.3058L72.5915 25.5032L72.7746 25.6798L72.7136 25.7421V25.8045C72.2761 25.8045 71.8285 25.8045 71.3606 25.7525L71.442 25.846L71.5335 25.9291L71.7065 26.1057L71.8591 26.2511C71.8955 26.2831 71.9295 26.3178 71.9608 26.355L72.0422 26.4174L71.9811 26.4901C71.9811 26.4901 71.9811 26.4901 71.8998 26.3966L71.737 26.2096L71.5132 25.9707C71.3606 25.9707 71.2792 25.9707 71.208 25.8668Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M72.1442 24.6306L71.9204 24.4644L72.0425 24.3189C72.0822 24.2725 72.1196 24.2239 72.1544 24.1735L72.3171 23.9554C72.3521 23.9137 72.3828 23.8684 72.4087 23.8203L72.4697 23.8826H72.5206L72.4392 23.9865L72.3477 24.0904L72.2663 24.1839V24.2462L72.4392 24.3917L72.6223 24.5371L72.7647 24.3293L72.897 24.1423C72.787 24.2561 72.685 24.3776 72.5918 24.5059L72.6935 24.589L72.7953 24.6721C72.8615 24.7308 72.933 24.783 73.0089 24.828L73.1513 24.641L73.2734 24.4332L73.192 24.5475L73.0496 24.7241L72.9275 24.8903L72.8461 24.9942V25.0461L72.6427 24.8695C72.5685 24.7992 72.4903 24.7333 72.4087 24.6721L72.1849 24.5059L72.1442 24.6306Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M73.7511 23.5192L74.1479 23.7685C74.1479 23.7685 74.1479 23.7685 74.1479 23.8204C73.958 23.6611 73.7647 23.5157 73.568 23.3841L72.978 22.9582V22.9063H73.2527H74.0767L73.7613 22.4596C73.6494 22.3038 73.5273 22.1583 73.4154 22.0129V21.9298C73.4187 21.9022 73.4187 21.8743 73.4154 21.8467L73.7817 21.9921L74.1581 22.1375L74.5039 22.2622L74.7582 22.3557C74.7616 22.3798 74.7616 22.4043 74.7582 22.4284V22.5011V22.5842C74.7528 22.6117 74.7528 22.6399 74.7582 22.6673L74.2598 22.5323L73.802 22.3453L74.1174 22.7816L74.4327 23.2075H73.9139H73.3951L73.7511 23.5192Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M74.6058 20.7761C74.5246 20.7678 74.4429 20.7678 74.3617 20.7761C74.2943 20.7652 74.2256 20.7652 74.1582 20.7761C74.0996 20.797 74.0471 20.8327 74.0056 20.88C73.9637 20.924 73.9323 20.9773 73.9141 21.0358C73.8937 21.1035 73.8937 21.1759 73.9141 21.2436C73.9263 21.3085 73.9543 21.3692 73.9955 21.4202C74.0422 21.4798 74.097 21.5323 74.1582 21.576C74.2304 21.623 74.3093 21.658 74.3922 21.6799C74.4791 21.7181 74.5723 21.7393 74.6669 21.7422C74.7343 21.7531 74.8029 21.7531 74.8703 21.7422C74.9203 21.7162 74.9651 21.6809 75.0026 21.6383C75.0445 21.5944 75.0759 21.541 75.0941 21.4825C75.1046 21.4241 75.1046 21.3643 75.0941 21.3059C75.0941 21.2385 75.0727 21.1729 75.0331 21.1189C74.9894 21.0536 74.9342 20.9973 74.8703 20.9527C74.7875 20.8974 74.6988 20.8521 74.6058 20.8177V20.7761ZM74.26 21.9604C74.1652 21.9328 74.0781 21.883 74.0056 21.8149C73.934 21.7509 73.8753 21.6733 73.8327 21.5864C73.7936 21.494 73.7696 21.3958 73.7615 21.2955C73.7429 21.182 73.7429 21.0662 73.7615 20.9527C73.7925 20.8514 73.8407 20.7565 73.9039 20.6722C73.9586 20.5937 74.0277 20.5266 74.1074 20.4749C74.1913 20.4257 74.2854 20.3972 74.382 20.3917C74.4892 20.3668 74.6004 20.3668 74.7076 20.3917C74.806 20.4229 74.8963 20.4762 74.972 20.5476C75.0406 20.612 75.0959 20.6897 75.1348 20.7761C75.1786 20.8667 75.2029 20.9659 75.206 21.067C75.2304 21.1764 75.2304 21.29 75.206 21.3994C75.1787 21.5002 75.134 21.5952 75.0738 21.6799C75.022 21.7638 74.9525 21.8348 74.8703 21.8876C74.7845 21.9324 74.6914 21.9605 74.5957 21.9708C74.4847 21.9959 74.3693 21.9924 74.26 21.9604Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M74.5041 20.1119H74.2599H74.0972C74.0999 20.0808 74.0999 20.0495 74.0972 20.0184C74.1035 19.9876 74.1035 19.9557 74.0972 19.9249L74.6567 19.5613L75.2263 19.1977H75.0432H74.8499H74.6567H74.4736H74.3311H74.2599C74.2626 19.1701 74.2626 19.1423 74.2599 19.1146V19.0107L74.5956 19.0938L74.8805 19.1562L75.1348 19.2081L75.4094 19.2601H75.6637C75.6602 19.2876 75.6602 19.3156 75.6637 19.3432C75.6594 19.3707 75.6594 19.3987 75.6637 19.4263C75.2874 19.6548 74.9008 19.8937 74.5142 20.1534H74.6363H74.7482H74.9924H75.1958H75.3382H75.501C75.4962 20.1704 75.4962 20.1884 75.501 20.2054C75.4965 20.2399 75.4965 20.2748 75.501 20.3093H75.3789L75.145 20.2469L74.8296 20.1742L74.5041 20.1119Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M75.3485 18.2936H75.6537V18.2105C75.6592 18.1761 75.6592 18.1411 75.6537 18.1067C75.6619 18.0356 75.6532 17.9636 75.6284 17.8968C75.6036 17.8299 75.5634 17.77 75.5113 17.7223C75.3772 17.6091 75.2071 17.55 75.0332 17.5561C74.9556 17.5453 74.8769 17.5453 74.7992 17.5561C74.7367 17.5768 74.6782 17.6085 74.6263 17.6496C74.5734 17.6831 74.5338 17.7346 74.5144 17.795C74.4808 17.8551 74.4632 17.9232 74.4635 17.9924V18.2105H75.3384L75.3485 18.2936ZM74.9111 18.5845H74.3313C74.3369 18.5606 74.3369 18.5357 74.3313 18.5118C74.3362 18.4808 74.3362 18.4493 74.3313 18.4183V18.0339C74.3199 17.9234 74.3199 17.812 74.3313 17.7015C74.3512 17.6094 74.3931 17.5237 74.4534 17.4522C74.5046 17.3808 74.5754 17.3266 74.6568 17.2964C74.764 17.2557 74.8783 17.2381 74.9925 17.2444C75.0975 17.2514 75.2006 17.2759 75.2977 17.3171C75.3847 17.3539 75.4633 17.409 75.5281 17.4788C75.5929 17.5486 75.6427 17.6316 75.6741 17.7223C75.6896 17.8186 75.6896 17.9169 75.6741 18.0132C75.6741 18.117 75.6741 18.2001 75.6741 18.2833C75.6796 18.3455 75.6796 18.408 75.6741 18.4702V18.6468H75.4096H75.1044L74.9111 18.5845Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M74.6365 16.9434H74.3618C74.3668 16.9194 74.3668 16.8947 74.3618 16.8707C74.3663 16.8362 74.3663 16.8013 74.3618 16.7668V16.6733C74.3618 16.6733 74.3618 16.6733 74.3618 16.6006H74.9417H75.2163H75.5215H75.786V16.6214C75.781 16.6523 75.781 16.6839 75.786 16.7149V16.8084C75.786 16.8084 75.786 16.8707 75.786 16.8915H75.5215H75.2163H74.9417L74.6365 16.9434Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M74.6463 15.8104L74.9108 15.8831L75.1651 15.935V15.5507L74.9312 15.6857L74.6972 15.8104H74.6463ZM75.5619 14.917C75.5569 14.948 75.5569 14.9795 75.5619 15.0105V15.1871C75.5619 15.1871 75.5619 15.2494 75.5619 15.2806L75.3686 15.3741L75.1753 15.4883L75.277 15.5818C75.2827 15.6267 75.2827 15.6721 75.277 15.7169C75.277 15.7169 75.277 15.7896 75.277 15.8208C75.2713 15.8551 75.2713 15.8903 75.277 15.9246L75.4906 15.9766H75.7144C75.7144 15.9766 75.7144 15.9766 75.7144 16.0285V16.122C75.7144 16.122 75.7144 16.122 75.7144 16.1844L75.5619 16.1324H75.3381L75.0634 16.0597L74.7684 15.987L74.4836 15.9143L74.2598 15.8727L74.6158 15.6753L74.982 15.4572L75.3177 15.2494L75.5619 14.917Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M74.3617 14.9179L74.1074 15.001C74.1124 14.977 74.1124 14.9523 74.1074 14.9283C74.1074 14.9283 74.1074 14.866 74.1074 14.8348V14.7413C74.1074 14.7413 74.1074 14.7413 74.1074 14.6686L74.3719 14.6063L74.6669 14.5335L74.9314 14.4608L75.1552 14.3985H75.3383L75.2773 14.1699C75.2773 14.0972 75.2773 14.0141 75.1959 13.9414H75.2773C75.2773 13.9414 75.2773 14.0245 75.2773 14.0868V14.305V14.5128V14.627C75.2806 14.6511 75.2806 14.6756 75.2773 14.6997L75.023 14.7517L74.728 14.8244L74.4533 14.8971L74.3617 14.9179Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M74.4531 13.7436H74.3107H74.2497V13.6813C74.2464 13.6572 74.2464 13.6327 74.2497 13.6086C74.2404 13.5739 74.244 13.5369 74.2599 13.5047C74.2529 13.4845 74.2529 13.4625 74.2599 13.4424H74.3209H74.4633H74.5244C74.5296 13.4663 74.5296 13.4912 74.5244 13.5151C74.5304 13.5389 74.5304 13.564 74.5244 13.5878C74.5288 13.6118 74.5288 13.6365 74.5244 13.6605V13.7229C74.5146 13.7312 74.5032 13.7374 74.4909 13.7409C74.4786 13.7445 74.4658 13.7454 74.4531 13.7436Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M53.5892 11.1878H53.4671H53.3654H53.2942C53.2942 11.1878 53.2942 11.1878 53.2332 11.2501L53.4875 11.406L53.7316 11.5618C53.7373 11.5274 53.7373 11.4923 53.7316 11.4579C53.7412 11.424 53.7412 11.388 53.7316 11.354C53.7399 11.3164 53.7399 11.2774 53.7316 11.2397C53.6958 11.1966 53.6548 11.1582 53.6095 11.1255L53.5892 11.1878ZM53.4264 11.8215L53.1619 11.6657L52.8975 11.541V11.4787V11.3956C52.8975 11.3956 52.9483 11.3125 52.9687 11.2605L53.0399 11.1255C53.0774 11.0586 53.1218 10.9959 53.1721 10.9385C53.2072 10.9011 53.2502 10.8723 53.2978 10.8543C53.3453 10.8364 53.3963 10.8296 53.4468 10.8346C53.5013 10.8377 53.554 10.8556 53.5994 10.8866C53.6622 10.924 53.7175 10.9733 53.7621 11.032C53.7929 11.0828 53.8137 11.1393 53.8232 11.1982C53.8696 11.2667 53.9009 11.3447 53.9147 11.4267C53.8926 11.4963 53.8619 11.5626 53.8232 11.6241L53.7723 11.7176C53.8639 11.7176 53.9452 11.8319 54.0368 11.8734L54.3115 12.0293V12.0916V12.1747L54.2606 12.2578V12.3201L53.9147 12.1331L53.6502 11.9773L53.4264 11.8215Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M54.1286 10.3881C54.1286 10.4712 54.2201 10.5439 54.2608 10.6166C54.3023 10.6966 54.3499 10.773 54.4032 10.8452L54.627 10.5335L54.3829 10.3881H54.1286ZM55.39 10.3881L55.3289 10.4608C55.3336 10.4848 55.3336 10.5095 55.3289 10.5335L55.2781 10.6063V10.679L55.0746 10.6063L54.861 10.5439L54.7796 10.6478L54.6982 10.7517L54.6372 10.8452L54.566 10.9387L54.6982 11.1257L54.8203 11.3023L54.7389 11.1568L54.627 10.9491C54.627 10.8763 54.5355 10.7932 54.4846 10.7101L54.332 10.44C54.271 10.3569 54.2201 10.2634 54.1693 10.1803C54.1184 10.0972 54.0777 10.0141 54.0269 9.94141L54.4032 10.0557L54.8203 10.1699L55.1967 10.2738L55.39 10.3881Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M54.7385 9.34906L54.6877 9.29712V9.35945L54.6266 9.43217L54.5554 9.52566L54.4944 9.60876C54.479 9.51738 54.448 9.42947 54.4028 9.34906L54.4842 9.26595C54.5172 9.23746 54.5479 9.20618 54.5758 9.17246L54.6877 9.0478L54.7996 8.92314L54.9318 8.85043V8.75693L55.003 8.67383C54.9714 8.68761 54.9435 8.70899 54.9216 8.73616L54.8504 8.79849L54.9318 8.99586V9.0478L55.0132 9.12052L55.3896 9.48411L55.6134 9.69187L55.8168 9.85808L55.7558 9.9308L55.6846 10.0035V10.0658L55.4913 9.86847L55.2777 9.66071L54.8911 9.29712C54.8363 9.2985 54.7832 9.31659 54.7385 9.34906Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M56.0409 8.31048C56.0119 8.26761 55.9736 8.23208 55.929 8.2066C55.8992 8.19703 55.8673 8.19703 55.8375 8.2066C55.8042 8.19732 55.7691 8.19732 55.7358 8.2066L55.6239 8.31048L55.9494 8.72601C56.0106 8.67773 56.0508 8.60686 56.0613 8.52863C56.0788 8.45566 56.0717 8.37876 56.0409 8.31048ZM56.0409 8.8195L56.102 8.88183L56.2953 9.12076L56.4784 9.31813H56.4173L56.3461 9.3493L56.2749 9.41163V9.46357L56.0613 9.3493L55.868 9.11037L55.6849 8.89222L55.4814 8.66368L55.2983 8.4663C55.2983 8.4663 55.2983 8.4663 55.3594 8.4663L55.4306 8.31048L55.5221 8.22737L55.6544 8.10272L55.7561 8.01961H55.8375C55.8606 8.01169 55.8856 8.01169 55.9087 8.01961H55.9697H56.1223C56.1733 8.04399 56.2184 8.07945 56.2546 8.12349C56.314 8.19231 56.3466 8.28105 56.3461 8.37281C56.3419 8.46682 56.31 8.55733 56.2546 8.63251C56.3868 8.63251 56.5089 8.72601 56.6513 8.75717L57.048 8.88183C57.0245 8.87707 57.0003 8.87707 56.9768 8.88183L56.9056 8.94416L56.8344 9.00649L56.7632 9.07921L56.0511 8.8195H56.0409Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M56.5494 7.71798L56.3765 7.49983H56.4477L56.5189 7.4375H56.6003L56.7529 7.66604C56.7529 7.73876 56.8546 7.83225 56.9258 7.92575L57.0885 8.15429L57.2717 8.4036L57.4344 8.62175H57.3632H57.292L57.2208 8.6737H57.1598L57.0173 8.44515L56.8444 8.19584L56.6715 7.95691L56.5494 7.71798Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M57.648 7.79153C57.709 7.92658 57.77 8.07201 57.8412 8.21745C57.77 7.9889 57.6886 7.76036 57.6073 7.53182C57.5259 7.30328 57.4343 7.07474 57.3428 6.8462L57.5666 7.01242L57.7904 7.17863L58.0243 7.34484L58.238 7.49027C58.238 7.31367 58.238 7.12669 58.238 6.9397C58.238 6.75271 58.238 6.55533 58.238 6.36835H58.3193L58.3906 6.31641C58.4618 6.4099 58.5431 6.52417 58.6143 6.63844L58.8483 6.97086L59.0009 7.27212C59.0495 7.35232 59.1039 7.42871 59.1637 7.50066H59.0925H59.0111H58.9399H58.8687C58.7975 7.376 58.7059 7.24096 58.6143 7.10591C58.5228 6.97086 58.4312 6.82543 58.3295 6.69038V7.23057V7.77075L57.9124 7.45911L57.4852 7.15785C57.5192 7.37396 57.5737 7.58619 57.648 7.79153Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M60.2114 6.23269C60.1827 6.1557 60.1452 6.08247 60.0995 6.01454C60.06 5.95858 60.0119 5.90948 59.9571 5.8691C59.903 5.83925 59.8447 5.81822 59.7842 5.80677C59.7202 5.79663 59.655 5.79663 59.5909 5.80677C59.5293 5.8326 59.4738 5.87156 59.4282 5.92104C59.385 5.97472 59.3569 6.03934 59.3468 6.10803C59.3307 6.17982 59.3307 6.2544 59.3468 6.32618C59.3381 6.41253 59.3381 6.49955 59.3468 6.58589C59.378 6.67544 59.4226 6.75954 59.479 6.83521C59.5127 6.89599 59.5619 6.94627 59.6214 6.98064C59.6783 6.99435 59.7375 6.99435 59.7944 6.98064H59.9571C60.0151 6.95832 60.0673 6.92279 60.1097 6.87676C60.1608 6.83448 60.1966 6.77598 60.2114 6.71055C60.2227 6.6348 60.2227 6.55775 60.2114 6.48201C60.2233 6.39934 60.2233 6.31536 60.2114 6.23269ZM59.0518 6.70016C59.0374 6.60027 59.0374 6.49879 59.0518 6.3989C59.0611 6.3022 59.0851 6.20757 59.123 6.11842C59.1753 6.02936 59.2405 5.94882 59.3163 5.87949C59.3985 5.80392 59.4956 5.74724 59.6011 5.71328C59.7006 5.68254 59.8028 5.66166 59.9063 5.65095C60.003 5.64695 60.0995 5.66438 60.1891 5.70203C60.2786 5.73969 60.3591 5.79668 60.4251 5.8691C60.4953 5.95576 60.5504 6.05417 60.5878 6.15997C60.6022 6.25986 60.6022 6.36134 60.5878 6.46123C60.5778 6.55904 60.5502 6.65412 60.5064 6.74171C60.4637 6.83432 60.4011 6.91596 60.3233 6.98064C60.2416 7.05449 60.144 7.10785 60.0385 7.13646C59.9407 7.17533 59.8379 7.19982 59.7333 7.20918C59.6424 7.2243 59.5496 7.2243 59.4587 7.20918C59.3709 7.16972 59.2915 7.11328 59.2247 7.04297C59.1473 6.95582 59.0884 6.85325 59.0518 6.74171V6.70016Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M60.8726 5.81763C60.8726 5.72413 60.8726 5.64103 60.8218 5.5787V5.4021H60.9133H61.0049L61.5339 5.81763L62.0527 6.23316V6.04617V5.84879V5.64103C62.0527 5.5787 62.0527 5.51637 62.0527 5.46443C62.0484 5.41605 62.0484 5.36737 62.0527 5.31899C62.0583 5.29509 62.0583 5.27018 62.0527 5.24628H62.1544L62.0527 5.19434C62.0527 5.32938 62.0527 5.44365 62.1035 5.54753C62.0979 5.64094 62.0979 5.73461 62.1035 5.82802C62.0978 5.91795 62.0978 6.00817 62.1035 6.09811C62.0987 6.19154 62.0987 6.28516 62.1035 6.37859V6.62791H62.0221H62.0527C61.7407 6.36474 61.4016 6.08772 61.0354 5.79685C61.0354 5.79685 61.0354 5.87996 61.0354 5.92151C61.0305 5.96291 61.0305 6.00477 61.0354 6.04617V6.2851C61.0354 6.3682 61.0354 6.44092 61.0354 6.50325V6.64868C61.0284 6.67944 61.0284 6.71142 61.0354 6.74218C61.0337 6.76291 61.0337 6.78377 61.0354 6.80451H60.9438H60.8523C60.8497 6.76646 60.8497 6.72828 60.8523 6.69024V6.44092C60.8523 6.33704 60.8523 6.23315 60.8523 6.11888C60.8637 6.01885 60.8705 5.91832 60.8726 5.81763Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M62.5513 5.41267V5.14258H62.8158H62.887C62.887 5.23607 62.887 5.32957 62.887 5.42306C62.887 5.51655 62.887 5.62044 62.887 5.73471V6.01519C62.887 6.13984 62.887 6.24373 62.887 6.32683V6.59692H62.5513C62.5563 6.51043 62.5563 6.42371 62.5513 6.33722C62.5513 6.24373 62.5513 6.13984 62.5513 6.02557V5.73471V5.41267Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M64.4737 5.80726C64.4846 5.72453 64.4846 5.64068 64.4737 5.55794C64.4548 5.49223 64.4274 5.42936 64.3923 5.37096C64.3524 5.32123 64.3042 5.27904 64.2499 5.2463C64.1991 5.21937 64.144 5.20179 64.0872 5.19436C64.0236 5.1789 63.9574 5.1789 63.8939 5.19436C63.8371 5.2279 63.7884 5.27405 63.7515 5.3294C63.7095 5.39054 63.6753 5.45686 63.6497 5.52678C63.6441 5.61325 63.6441 5.70001 63.6497 5.78649C63.6373 5.87955 63.6373 5.9739 63.6497 6.06697C63.6669 6.1366 63.6943 6.20316 63.7311 6.26434C63.7716 6.308 63.82 6.34327 63.8735 6.36822C63.9273 6.37887 63.9825 6.37887 64.0363 6.36822C64.0935 6.37896 64.1521 6.37896 64.2092 6.36822C64.2705 6.34202 64.3233 6.29884 64.3618 6.24357C64.413 6.18705 64.4512 6.11964 64.4737 6.04619C64.5079 5.94961 64.5251 5.84763 64.5246 5.74493L64.4737 5.80726ZM63.2327 5.80726C63.2394 5.70677 63.2635 5.60829 63.3039 5.51639C63.3437 5.4248 63.4027 5.34324 63.4768 5.27746C63.5524 5.21128 63.6384 5.1586 63.7311 5.12164C63.8386 5.10008 63.9492 5.10008 64.0566 5.12164C64.1615 5.12017 64.2655 5.14141 64.3618 5.18397C64.4528 5.21415 64.5364 5.26415 64.6066 5.33051C64.6769 5.39687 64.7322 5.47801 64.7687 5.56833C64.7904 5.68153 64.7904 5.79794 64.7687 5.91114C64.7708 6.01284 64.7462 6.11326 64.6975 6.20201C64.6612 6.29281 64.6015 6.37181 64.5246 6.43055C64.4515 6.50015 64.3648 6.55324 64.2703 6.58638C64.1658 6.60312 64.0594 6.60312 63.9549 6.58638C63.8535 6.59804 63.7511 6.59804 63.6497 6.58638C63.5578 6.55921 63.474 6.50924 63.4056 6.44094C63.3345 6.37478 63.2788 6.29312 63.2428 6.20201C63.2113 6.07276 63.2078 5.93803 63.2327 5.80726Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M65.8474 6.30612L65.6948 6.75281H65.5117C65.6134 6.53466 65.7152 6.30612 65.8067 6.08796C65.8983 5.86981 65.9898 5.64127 66.0814 5.40234H66.2035C66.2035 5.48545 66.2035 5.57894 66.2035 5.68282C66.2035 5.78671 66.2035 5.86981 66.2035 5.96331C66.2083 6.05673 66.2083 6.15036 66.2035 6.24379L66.2645 6.50349L66.6205 6.08796C66.7528 5.94253 66.8647 5.7971 66.9867 5.65166C67.1088 5.50623 66.9867 5.65166 67.0681 5.65166H67.1495C67.1495 5.77632 67.1495 5.91137 67.1495 6.04641L67.0885 6.45155C67.0885 6.5866 67.0885 6.71126 67.0885 6.82553C67.0885 6.9398 67.0885 7.03329 67.0885 7.10601H67.0071H66.9257H66.8443H66.7629C66.7977 6.95516 66.8248 6.8026 66.8443 6.64893C66.8443 6.48272 66.9054 6.3165 66.9257 6.15029L66.5798 6.55543C66.4578 6.69048 66.3459 6.82553 66.234 6.97096H66.2238C66.2238 6.79436 66.1526 6.61776 66.1221 6.45155C66.0916 6.28534 66.061 6.10874 66.0203 5.92175C65.9593 6.00486 65.8983 6.15029 65.8474 6.30612Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M68.7158 7.02205C68.752 6.94683 68.776 6.86615 68.787 6.78312C68.7974 6.71427 68.7974 6.64421 68.787 6.57535C68.7726 6.51439 68.7447 6.45756 68.7057 6.40914C68.6666 6.3569 68.6136 6.31723 68.5531 6.29487C68.4901 6.27489 68.4227 6.27489 68.3598 6.29487C68.2953 6.303 68.2331 6.32419 68.1767 6.3572C68.1121 6.4005 68.0539 6.45296 68.0038 6.51303C67.9513 6.57819 67.91 6.65195 67.8817 6.73118C67.8387 6.8191 67.8112 6.91403 67.8003 7.01166C67.7847 7.07999 67.7847 7.15109 67.8003 7.21942C67.811 7.27872 67.8394 7.33317 67.8817 7.37524C67.9208 7.41798 67.9698 7.4501 68.0241 7.46874C68.0808 7.48416 68.1404 7.48416 68.197 7.46874C68.2573 7.48259 68.3199 7.48259 68.3801 7.46874C68.4523 7.41475 68.5144 7.34788 68.5632 7.27136C68.6208 7.18908 68.6718 7.10223 68.7158 7.01166V7.02205ZM67.5663 6.54419C67.6086 6.451 67.6672 6.36651 67.7393 6.29487C67.8106 6.23067 67.8936 6.18127 67.9834 6.14944C68.0766 6.12336 68.175 6.12336 68.2682 6.14944C68.486 6.16572 68.6929 6.25314 68.8583 6.39875C68.9275 6.46498 68.9859 6.54214 69.0312 6.6273C69.0516 6.72314 69.0516 6.82232 69.0312 6.91816C69.0309 7.02955 69.0066 7.1395 68.96 7.2402C68.9231 7.33646 68.8637 7.42206 68.787 7.48952C68.7141 7.5515 68.6316 7.60065 68.5429 7.63495C68.4462 7.66092 68.3446 7.66092 68.2479 7.63495C68.1393 7.63069 68.0324 7.60604 67.9325 7.56223C67.8395 7.51535 67.751 7.4597 67.6681 7.39602C67.5988 7.3298 67.5404 7.25264 67.4951 7.16748C67.4545 7.07615 67.4337 6.97693 67.4341 6.87661C67.4536 6.7572 67.4988 6.64368 67.5663 6.54419Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M69.4075 7.09503C69.4481 7.02355 69.4922 6.9542 69.5397 6.88727L69.6211 6.73145L69.6923 6.79377H69.7839C69.7839 7.02231 69.8449 7.25085 69.8856 7.46901C69.9263 7.68716 69.9568 7.9157 69.9975 8.14424L70.0992 7.98842L70.2009 7.81182L70.3128 7.64561L70.4044 7.48978L70.4858 7.35474V7.28202C70.5041 7.30845 70.5286 7.32986 70.557 7.34435C70.5836 7.35187 70.6117 7.35187 70.6384 7.34435L70.4349 7.63522L70.2722 7.87415L70.1908 8.1754C70.145 8.26164 70.094 8.34491 70.0382 8.42472L69.9059 8.63248L69.8347 8.58054H69.7737C69.7127 8.14424 69.6414 7.68716 69.5499 7.21969L69.4889 7.32357C69.4618 7.35568 69.4379 7.39048 69.4176 7.42745L69.2854 7.63522L69.1735 7.77026L69.0921 7.90531C69.0941 7.93298 69.0941 7.96075 69.0921 7.98842L69.0107 7.93648L69.082 7.84298L69.2244 7.63522L69.4075 7.37551V7.09503Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M70.6792 8.75739L70.5978 8.85088L70.5266 8.93399V8.98593L70.608 9.05864C70.6587 9.10892 70.7196 9.1471 70.7864 9.17045C70.8533 9.19381 70.9244 9.20174 70.9946 9.19369C71.1611 9.15217 71.3085 9.05307 71.4116 8.91321C71.4658 8.85509 71.5104 8.78844 71.5439 8.71583C71.5533 8.6504 71.5533 8.58389 71.5439 8.51846C71.5582 8.45698 71.5582 8.39295 71.5439 8.33147C71.5121 8.27285 71.4708 8.22016 71.4218 8.17565L71.3303 8.09254L71.259 8.03021L71.1878 8.11332L71.0658 8.31069L70.9946 8.3938L70.7504 8.67428L70.6792 8.75739ZM70.7402 8.2172L70.9335 7.97827C70.9965 7.91388 71.0544 7.8444 71.1065 7.77051L71.2082 7.89517L71.3303 7.98866L71.493 8.13409C71.5757 8.20921 71.6507 8.29275 71.7168 8.38341C71.7707 8.45767 71.8088 8.54258 71.8287 8.63273C71.8541 8.72089 71.8541 8.81466 71.8287 8.90282C71.7919 9.01118 71.7329 9.11033 71.6558 9.19369C71.5869 9.2742 71.5042 9.3411 71.4116 9.39107C71.326 9.43442 71.2325 9.45918 71.137 9.46378C71.0471 9.48898 70.9522 9.48898 70.8623 9.46378C70.7728 9.43126 70.69 9.38193 70.6182 9.31835L70.4249 9.14175L70.2825 9.0067L70.1909 8.90282V8.85088C70.252 8.85088 70.313 8.72622 70.374 8.66389L70.5876 8.43535L70.7402 8.2172Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M72.2255 9.20359L72.4188 9.0166V9.07893L72.4798 9.15165L72.5409 9.21398L72.6019 9.26592L72.3883 9.44252L72.1645 9.65028L71.9509 9.84766L71.7271 10.0554L71.5439 10.2528V10.1905C71.5298 10.1615 71.5088 10.1365 71.4829 10.1177L71.4219 10.045V9.99309L71.6253 9.81649L71.8491 9.61912L72.0526 9.42174L72.2255 9.20359Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M72.9071 10.2327L72.6427 10.295L72.3884 10.3678L72.6223 10.6794L72.7647 10.4613L72.9071 10.2327ZM72.5206 11.4274H72.4596V11.3546L72.4087 11.2819L72.3477 11.2092L72.4799 11.0326L72.602 10.8352L72.5206 10.7313L72.4494 10.6171L72.3782 10.534L72.2256 10.3885L72.0018 10.4613L71.7983 10.534H71.9509L72.1849 10.4716L72.4494 10.3989L72.7444 10.3262L73.0292 10.2431L73.2428 9.9834C73.1818 10.0977 73.1106 10.2119 73.0292 10.3366L72.8054 10.7002L72.602 11.043L72.5206 11.4274Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M73.609 11.0533L73.8328 10.8975V10.9702V11.0533V11.1364V11.1987L73.5886 11.3234L73.3241 11.4792L73.08 11.6246L72.8867 11.7493L72.724 11.8428C72.762 11.9158 72.8063 11.9854 72.8562 12.0506C72.8943 12.1203 72.9386 12.1864 72.9884 12.2479C72.9884 12.2479 72.9884 12.1752 72.9274 12.1233L72.8155 11.9259L72.7036 11.7493L72.6426 11.635C72.6426 11.635 72.6426 11.635 72.6426 11.5727L72.8765 11.448L73.141 11.2922L73.3852 11.1468L73.609 11.0533Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M63.5586 8.24805C61.2741 8.25355 59.0759 9.1398 57.4038 10.7295C55.7318 12.3191 54.7092 14.4949 54.5408 16.8215C54.3724 19.1481 55.0705 21.4538 56.4954 23.2772C57.9204 25.1007 59.9671 26.3074 62.2259 26.6559H62.4803C62.5675 26.656 62.6538 26.6381 62.7342 26.6034C62.8145 26.5687 62.8872 26.5179 62.948 26.4539C63.0087 26.39 63.0562 26.3142 63.0877 26.2311C63.1192 26.148 63.134 26.0593 63.1313 25.9703V21.4722L59.2759 17.5351L63.6094 13.1097L67.9429 17.5351L64.0875 21.4514V25.9703C64.0875 26.4689 64.3724 26.7702 64.7793 26.6663C67.0433 26.3356 69.1012 25.143 70.5403 23.3278C71.9794 21.5126 72.6933 19.2089 72.5389 16.8787C72.3844 14.5485 71.3731 12.364 69.7076 10.7634C68.0421 9.16272 65.8456 8.26422 63.5586 8.24805ZM65.4913 25.0354V22.1163L69.9469 17.5766L63.5992 11.084L57.2414 17.5766L61.7885 22.2098V25.0354C59.9762 24.5703 58.3928 23.4448 57.3365 21.8707C56.2802 20.2965 55.8237 18.3823 56.0532 16.4885C56.2826 14.5946 57.182 12.8518 58.5822 11.588C59.9823 10.3241 61.7865 9.62657 63.6552 9.62657C65.5239 9.62657 67.3281 10.3241 68.7282 11.588C70.1283 12.8518 71.0278 14.5946 71.2572 16.4885C71.4866 18.3823 71.0302 20.2965 69.9739 21.8707C68.9176 23.4448 67.3342 24.5703 65.5219 25.0354H65.4913Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M53.1826 36.9298L53.7624 37.1168C53.7007 37.405 53.5424 37.6622 53.3148 37.844C53.0923 38.0132 52.8188 38.0978 52.5417 38.0829C52.3617 38.0891 52.1825 38.0562 52.0159 37.9864C51.8493 37.9165 51.6991 37.8114 51.5753 37.6778C51.4434 37.5252 51.3423 37.3476 51.2777 37.1551C51.2131 36.9626 51.1864 36.759 51.1989 36.5559C51.171 36.1359 51.3062 35.7216 51.5753 35.4028C51.7047 35.2616 51.8629 35.1513 52.0387 35.0794C52.2145 35.0076 52.4036 34.9762 52.5926 34.9873C52.8589 34.9649 53.1243 35.0405 53.3407 35.2006C53.5571 35.3606 53.7103 35.5945 53.7726 35.8599L53.1826 36.0053C53.1574 35.8586 53.0816 35.7259 52.969 35.6313C52.8514 35.5422 52.7085 35.4948 52.5621 35.4963C52.4622 35.4948 52.3633 35.5153 52.2719 35.5566C52.1806 35.5978 52.0991 35.6588 52.0331 35.7352C51.8812 35.964 51.8095 36.2386 51.8296 36.5143C51.804 36.8003 51.8759 37.0865 52.0331 37.3246C52.0952 37.4032 52.1745 37.4657 52.2646 37.5072C52.3547 37.5487 52.4531 37.568 52.5519 37.5635C52.6814 37.5467 52.8025 37.4885 52.8977 37.3973C53.036 37.2738 53.1352 37.1111 53.1826 36.9298Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M54.2607 35.8597H54.8304V38.0309H54.2607V35.8597ZM54.2607 35.0391H54.8304V35.5689H54.2607V35.0391Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M56.3765 35.86V36.3586H55.9492V37.2416C55.9372 37.3417 55.9372 37.4428 55.9492 37.5429C55.9438 37.5542 55.941 37.5666 55.941 37.5792C55.941 37.5918 55.9438 37.6043 55.9492 37.6156C55.9824 37.626 56.0178 37.626 56.051 37.6156C56.1274 37.6078 56.2025 37.5904 56.2747 37.5636V38.0103C56.1318 38.0657 55.9801 38.0939 55.8272 38.0934C55.7363 38.1102 55.6433 38.1102 55.5525 38.0934C55.4807 38.066 55.4175 38.0194 55.3694 37.9584C55.3306 37.888 55.3064 37.8102 55.2982 37.7299C55.2834 37.5952 55.2834 37.4593 55.2982 37.3247V36.3586H55.0337V35.9015H55.2982V35.4756L55.8577 35.1328V35.9015L56.3765 35.86Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M57.7497 35.86V36.3586H57.3631V37.2416C57.3541 37.3418 57.3541 37.4427 57.3631 37.5429C57.3631 37.5429 57.3631 37.6052 57.414 37.6156C57.4433 37.6277 57.4762 37.6277 57.5055 37.6156C57.5821 37.6089 57.6574 37.5914 57.7293 37.5636L57.7802 38.0103C57.6375 38.0667 57.4856 38.0949 57.3326 38.0934C57.2418 38.1102 57.1488 38.1102 57.0579 38.0934C56.9891 38.0658 56.9292 38.019 56.885 37.9584C56.8382 37.8911 56.8101 37.8121 56.8036 37.7299C56.7942 37.595 56.7942 37.4596 56.8036 37.3247V36.3586H56.5493V35.9015H56.8036V35.4756L57.3733 35.1328V35.9015L57.7497 35.86Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M59.3064 36.9922L58.9707 37.0753C58.8646 37.0946 58.7617 37.1296 58.6655 37.1792C58.6316 37.1992 58.6034 37.228 58.5837 37.2626C58.5641 37.2973 58.5537 37.3366 58.5536 37.3766C58.5536 37.4174 58.5617 37.4578 58.5775 37.4953C58.5932 37.5328 58.6162 37.5666 58.6452 37.5947C58.7063 37.6512 58.7866 37.6811 58.869 37.6778C58.9728 37.678 59.0735 37.6413 59.1538 37.574C59.2183 37.5297 59.2652 37.4634 59.286 37.387C59.2997 37.294 59.2997 37.1995 59.286 37.1065V36.9922H59.3064ZM59.3064 35.6002H58.9503L58.3908 34.9873H59.0012L59.286 35.6002H59.3064ZM58.5841 36.5248L58.0755 36.4313C58.1226 36.2474 58.226 36.0836 58.3705 35.9638C58.5603 35.8476 58.7803 35.7932 59.0012 35.808C59.1921 35.7975 59.383 35.8294 59.5607 35.9015C59.6685 35.9457 59.7583 36.0264 59.815 36.13C59.8803 36.2946 59.9081 36.4722 59.8964 36.6494V37.3246C59.8821 37.4628 59.8821 37.6021 59.8964 37.7402C59.9183 37.8411 59.9524 37.9388 59.9981 38.031H59.4386L59.3878 37.8648C59.3844 37.8407 59.3844 37.8162 59.3878 37.7921C59.2998 37.8879 59.1921 37.9624 59.0724 38.0103C58.964 38.0611 58.8457 38.0859 58.7265 38.083C58.5362 38.093 58.3497 38.0258 58.2077 37.896C58.1345 37.8369 58.076 37.7609 58.0371 37.6742C57.9981 37.5875 57.9799 37.4926 57.9839 37.3974C57.9816 37.283 58.0135 37.1706 58.0755 37.0753C58.1329 36.9761 58.2179 36.8966 58.3196 36.8468C58.4633 36.7844 58.6135 36.7391 58.7672 36.7117C58.9515 36.6884 59.1325 36.6431 59.3064 36.5767V36.5144C59.3111 36.4733 59.3072 36.4317 59.2949 36.3923C59.2826 36.3528 59.2623 36.3166 59.2352 36.2858C59.1443 36.2273 59.0368 36.2017 58.93 36.2131C58.8474 36.207 58.7652 36.2289 58.696 36.2754C58.6573 36.3053 58.6248 36.3427 58.6003 36.3855C58.5759 36.4283 58.56 36.4756 58.5536 36.5248H58.5841Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M62.2259 38.0309V35.5377H61.3511V35.0391H63.6908V35.5377H62.8159V38.0309H62.2259Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M65.237 36.9923L64.9013 37.0754C64.7952 37.0947 64.6924 37.1297 64.5962 37.1793C64.5622 37.1993 64.534 37.228 64.5144 37.2627C64.4947 37.2973 64.4843 37.3366 64.4843 37.3766C64.4847 37.457 64.5136 37.5345 64.5656 37.5948C64.6287 37.6479 64.7077 37.6773 64.7894 37.6779C64.8965 37.6788 65.0006 37.6421 65.0844 37.574C65.149 37.5298 65.1959 37.4635 65.2167 37.387C65.2303 37.2941 65.2303 37.1995 65.2167 37.1066V36.9923H65.237ZM64.4843 36.5248L63.9756 36.4313C64.0192 36.2459 64.1233 36.081 64.2706 35.9639C64.4605 35.8476 64.6805 35.7933 64.9013 35.808C65.0923 35.7975 65.2831 35.8294 65.4608 35.9015C65.5667 35.949 65.6555 36.0289 65.7151 36.1301C65.7804 36.2947 65.8082 36.4723 65.7965 36.6495V37.3247C65.7822 37.4628 65.7822 37.6021 65.7965 37.7402C65.8184 37.8412 65.8526 37.9389 65.8982 38.0311H65.3388V37.8649C65.3354 37.8408 65.3354 37.8163 65.3388 37.7922C65.2508 37.8879 65.1431 37.9625 65.0234 38.0103C64.915 38.0611 64.7967 38.086 64.6775 38.083C64.5832 38.0892 64.4886 38.0758 64.3994 38.0436C64.3103 38.0115 64.2284 37.9613 64.1587 37.8961C64.0965 37.8383 64.047 37.7677 64.0137 37.6889C63.9804 37.61 63.9639 37.5248 63.9655 37.439C63.9631 37.3246 63.995 37.2122 64.057 37.1169C64.1091 37.0135 64.1957 36.9324 64.3011 36.8884C64.4441 36.8242 64.5945 36.7788 64.7487 36.7534C64.9325 36.7273 65.1132 36.682 65.2879 36.6183V36.556C65.2936 36.514 65.2892 36.4712 65.275 36.4314C65.2608 36.3915 65.2373 36.3559 65.2065 36.3274C65.1202 36.2672 65.0152 36.2413 64.9115 36.2547C64.8289 36.2487 64.7467 36.2706 64.6775 36.3171C64.6335 36.337 64.5938 36.3656 64.5606 36.4013C64.5275 36.4369 64.5015 36.4789 64.4843 36.5248Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M66.8847 38.0307H66.3252V35.8596H66.844V36.1712C66.907 36.0596 66.9898 35.961 67.0881 35.8803C67.1618 35.8345 67.246 35.8094 67.3323 35.8076C67.4626 35.8086 67.5899 35.8483 67.6985 35.9219L67.5256 36.4205C67.4482 36.3637 67.3563 36.3312 67.2611 36.327C67.1917 36.3255 67.1238 36.3474 67.0678 36.3894C66.9978 36.4551 66.9512 36.5428 66.9356 36.6387C66.9096 36.8803 66.9096 37.1242 66.9356 37.3658L66.8847 38.0307Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M68.3904 36.899C68.3766 37.0751 68.4118 37.2516 68.4922 37.408C68.5302 37.4753 68.5851 37.531 68.6513 37.5694C68.7175 37.6077 68.7925 37.6273 68.8685 37.6262C68.9333 37.627 68.9973 37.6124 69.0556 37.5835C69.1139 37.5547 69.1648 37.5124 69.2042 37.46C69.3048 37.3111 69.3516 37.1311 69.3365 36.9509C69.3556 36.7639 69.3087 36.576 69.2042 36.4211C69.1648 36.3687 69.1139 36.3264 69.0556 36.2976C68.9973 36.2687 68.9333 36.2541 68.8685 36.2549C68.8048 36.2544 68.7418 36.268 68.6838 36.2949C68.6258 36.3219 68.5743 36.3614 68.5328 36.4107C68.4301 36.5511 68.3796 36.7241 68.3904 36.899ZM69.896 38.0313H69.3772V37.7197C69.2961 37.8315 69.1919 37.9237 69.072 37.9897C68.9062 38.0856 68.7123 38.1173 68.5255 38.0791C68.3387 38.041 68.1716 37.9355 68.0547 37.782C67.8853 37.5355 67.7944 37.2417 67.7944 36.9405C67.7944 36.6394 67.8853 36.3456 68.0547 36.0991C68.1353 36.0071 68.2341 35.9336 68.3446 35.8834C68.4551 35.8333 68.5747 35.8077 68.6956 35.8082C68.8155 35.8082 68.9338 35.8363 69.0414 35.8903C69.149 35.9444 69.243 36.0229 69.3161 36.1199V35.0811H69.8756L69.896 38.0313Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M70.9032 36.9504C70.8942 37.1251 70.9445 37.2976 71.0457 37.4387C71.0919 37.4945 71.1496 37.5392 71.2146 37.5698C71.2796 37.6003 71.3505 37.6158 71.422 37.6153C71.4919 37.6143 71.5607 37.598 71.6239 37.5676C71.6871 37.5371 71.7431 37.4932 71.7883 37.4387C71.8987 37.298 71.9532 37.1199 71.9408 36.94C71.9516 36.7635 71.8972 36.5893 71.7883 36.4518C71.7431 36.3973 71.6871 36.3534 71.6239 36.3229C71.5607 36.2924 71.4919 36.2762 71.422 36.2752C71.3505 36.2747 71.2796 36.2902 71.2146 36.3207C71.1496 36.3512 71.0919 36.396 71.0457 36.4518C70.943 36.5961 70.8926 36.7724 70.9032 36.9504ZM70.3234 36.9504C70.321 36.7538 70.3701 36.5601 70.4658 36.3895C70.5501 36.2165 70.6852 36.0748 70.8524 35.9843C71.0272 35.8876 71.2232 35.8376 71.422 35.8389C71.6368 35.8462 71.845 35.9159 72.0224 36.0398C72.1997 36.1637 72.3387 36.3367 72.4231 36.5385C72.5074 36.7403 72.5337 36.9625 72.4987 37.179C72.4638 37.3955 72.369 37.5973 72.2257 37.7607C72.1253 37.8685 72.0034 37.9531 71.8683 38.0086C71.7332 38.0642 71.5879 38.0895 71.4424 38.0827C71.248 38.0839 71.0562 38.0376 70.8829 37.9477C70.7135 37.8617 70.5748 37.7236 70.4862 37.5529C70.3761 37.3609 70.3198 37.1417 70.3234 36.9193V36.9504Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M74.6974 36.7221V37.5324H75.2773C75.4127 37.5422 75.5487 37.5422 75.6842 37.5324C75.7671 37.5164 75.8423 37.4725 75.8978 37.4077C75.9272 37.374 75.9496 37.3345 75.9636 37.2916C75.9776 37.2488 75.9829 37.2034 75.9792 37.1584C75.9822 37.0776 75.9609 36.9979 75.9181 36.9299C75.8763 36.8626 75.8107 36.8143 75.735 36.7948C75.5597 36.7682 75.3814 36.7682 75.2061 36.7948L74.6974 36.7221ZM74.6974 35.5378V36.2235H75.5214C75.6098 36.2216 75.6938 36.1843 75.7554 36.1196C75.8104 36.0567 75.8409 35.9754 75.8409 35.891C75.8409 35.8067 75.8104 35.7254 75.7554 35.6625C75.7276 35.6298 75.6932 35.6036 75.6546 35.5856C75.616 35.5677 75.574 35.5585 75.5316 35.5586H74.6873L74.6974 35.5378ZM74.1074 35.0392H75.2773C75.45 35.026 75.6234 35.026 75.7961 35.0392C75.9088 35.0568 76.0165 35.0994 76.1114 35.1639C76.2011 35.2315 76.2771 35.3161 76.3352 35.4132C76.4018 35.5242 76.437 35.6518 76.437 35.782C76.437 35.9121 76.4018 36.0397 76.3352 36.1508C76.2616 36.2694 76.1555 36.3633 76.03 36.4208C76.1934 36.4639 76.3393 36.5584 76.4471 36.6909C76.5424 36.8232 76.5925 36.9838 76.5895 37.148C76.5884 37.2849 76.5571 37.4198 76.498 37.5428C76.433 37.662 76.3468 37.7677 76.2437 37.8544C76.1218 37.9348 75.9816 37.9813 75.8368 37.9895C75.7452 37.9895 75.5011 37.9895 75.1247 37.9895H74.1074V35.0392Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M78.2374 36.9921L77.8916 37.0752C77.7864 37.0982 77.684 37.1331 77.5864 37.1791C77.5537 37.2006 77.5265 37.2296 77.507 37.264C77.4876 37.2983 77.4764 37.3369 77.4745 37.3765C77.4745 37.4173 77.4826 37.4577 77.4983 37.4952C77.5141 37.5327 77.5371 37.5665 77.566 37.5946C77.6282 37.6493 77.7078 37.6789 77.7898 37.6778C77.8969 37.6786 78.001 37.6419 78.0848 37.5739C78.1462 37.5264 78.1922 37.4613 78.2171 37.3869C78.2255 37.2936 78.2255 37.1997 78.2171 37.1064V36.9921H78.2374ZM77.4847 36.5247L76.976 36.4312C77.0156 36.244 77.1204 36.0778 77.271 35.9637C77.4561 35.845 77.6737 35.7904 77.8916 35.8079C78.0858 35.7978 78.2799 35.8297 78.4612 35.9014C78.5671 35.9489 78.6559 36.0287 78.7155 36.1299C78.776 36.2957 78.8003 36.473 78.7867 36.6493V37.3246C78.7789 37.4629 78.7789 37.6017 78.7867 37.7401C78.8132 37.8396 78.8472 37.9369 78.8885 38.0309H78.329C78.3019 37.9784 78.2814 37.9225 78.268 37.8647C78.2693 37.8405 78.2693 37.8162 78.268 37.792C78.1854 37.8894 78.0806 37.9643 77.9628 38.0102C77.8541 38.06 77.736 38.0848 77.6169 38.0829C77.5226 38.089 77.428 38.0756 77.3388 38.0435C77.2497 38.0113 77.1678 37.9611 77.0981 37.8959C77.0342 37.8395 76.9836 37.7692 76.9501 37.69C76.9167 37.6108 76.9012 37.525 76.9048 37.4388C76.9025 37.3245 76.9344 37.2121 76.9964 37.1168C77.0473 37.0161 77.1296 36.9357 77.2303 36.8882C77.374 36.8258 77.5242 36.7805 77.6779 36.7532C77.8652 36.7281 78.0493 36.6828 78.2273 36.6182V36.5558C78.2306 36.514 78.2251 36.4719 78.2111 36.4325C78.197 36.393 78.1748 36.3571 78.1459 36.3273C78.0561 36.2663 77.9477 36.2405 77.8407 36.2546C77.7584 36.2513 77.677 36.273 77.6067 36.3169C77.544 36.3699 77.5009 36.4432 77.4847 36.5247Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8853 38.0307H79.3462V35.8596H79.865V36.1712C79.928 36.0596 80.0108 35.961 80.1091 35.8803C80.1828 35.8345 80.267 35.8094 80.3533 35.8076C80.4831 35.8119 80.6095 35.8513 80.7195 35.9219L80.5466 36.4205C80.46 36.3706 80.3607 36.3489 80.2617 36.3582C80.1892 36.3567 80.118 36.3785 80.0583 36.4205C79.9957 36.4904 79.9534 36.5767 79.9362 36.6698C79.9048 36.9112 79.9048 37.1557 79.9362 37.397L79.8853 38.0307Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M81.3806 36.9512C81.3698 37.1277 81.4243 37.3019 81.5332 37.4394C81.5783 37.4939 81.6344 37.5379 81.6975 37.5683C81.7607 37.5988 81.8296 37.6151 81.8994 37.616C81.9696 37.6172 82.0392 37.602 82.1027 37.5713C82.1662 37.5407 82.222 37.4956 82.2656 37.4394C82.3761 37.2987 82.4306 37.1207 82.4182 36.9408C82.429 36.7643 82.3746 36.59 82.2656 36.4525C82.222 36.3964 82.1662 36.3513 82.1027 36.3206C82.0392 36.29 81.9696 36.2747 81.8994 36.2759C81.8296 36.2769 81.7607 36.2932 81.6975 36.3236C81.6344 36.3541 81.5783 36.3981 81.5332 36.4525C81.4227 36.5932 81.3682 36.7713 81.3806 36.9512ZM80.8008 36.9512C80.8033 36.7552 80.8521 36.5627 80.9432 36.3902C81.0329 36.2213 81.1667 36.081 81.3298 35.9851C81.5046 35.8884 81.7006 35.8383 81.8994 35.8396C82.0478 35.8268 82.197 35.8474 82.3367 35.8999C82.4764 35.9524 82.6031 36.0356 82.7079 36.1436C82.8127 36.2516 82.8929 36.3817 82.9431 36.5249C82.9932 36.668 83.012 36.8206 82.9981 36.972C83.0037 37.1236 82.9786 37.2747 82.9243 37.4159C82.8701 37.5571 82.7878 37.6854 82.6827 37.7926C82.5823 37.9004 82.4605 37.985 82.3253 38.0406C82.1902 38.0961 82.045 38.1214 81.8994 38.1147C81.7051 38.1158 81.5132 38.0695 81.3399 37.9796C81.1705 37.8936 81.0318 37.7555 80.9432 37.5849C80.8436 37.3786 80.798 37.1496 80.811 36.92L80.8008 36.9512Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M85.3073 36.5045L84.7478 36.6084C84.7349 36.5108 84.6922 36.42 84.6257 36.3487C84.549 36.2918 84.456 36.2625 84.3612 36.2656C84.2985 36.265 84.2363 36.2777 84.1786 36.3027C84.1208 36.3277 84.0687 36.3646 84.0255 36.411C83.9322 36.559 83.8893 36.7342 83.9034 36.9096C83.8855 37.1031 83.9321 37.2972 84.0357 37.4602C84.0757 37.5113 84.1269 37.5521 84.1853 37.5792C84.2436 37.6063 84.3074 37.6189 84.3714 37.616C84.468 37.6207 84.5628 37.5873 84.6359 37.5225C84.7167 37.4341 84.7668 37.3209 84.7783 37.2005L85.3378 37.294C85.2946 37.5244 85.1762 37.7331 85.0021 37.8861C84.8136 38.0261 84.5838 38.0958 84.351 38.0835C84.2121 38.0885 84.0736 38.0643 83.9441 38.0125C83.8146 37.9607 83.6969 37.8823 83.5983 37.7822C83.4041 37.5524 83.3055 37.2542 83.3236 36.9512C83.3129 36.7986 83.3327 36.6453 83.3816 36.5007C83.4306 36.3561 83.5078 36.2231 83.6084 36.1097C83.7071 36.0097 83.8248 35.9313 83.9543 35.8795C84.0837 35.8276 84.2222 35.8035 84.3612 35.8085C84.5779 35.7994 84.7918 35.8613 84.9716 35.9851C85.1371 36.1151 85.2552 36.298 85.3073 36.5045Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M87.586 36.504L87.0367 36.6079C87.0249 36.5078 86.9779 36.4155 86.9044 36.3481C86.8316 36.2913 86.7417 36.2619 86.6501 36.265C86.5871 36.2625 86.5243 36.2742 86.4663 36.2993C86.4083 36.3245 86.3564 36.3624 86.3144 36.4105C86.2168 36.5568 86.1702 36.7325 86.1822 36.9091C86.1687 37.1022 86.215 37.2948 86.3144 37.4597C86.356 37.5116 86.409 37.5528 86.4691 37.5799C86.5292 37.607 86.5947 37.6192 86.6603 37.6155C86.7538 37.6197 86.8452 37.5861 86.9146 37.522C86.9991 37.435 87.0528 37.3217 87.0672 37.2L87.6165 37.2935C87.5733 37.5239 87.455 37.7326 87.2808 37.8856C87.0923 38.0256 86.8625 38.0953 86.6298 38.083C86.4925 38.0865 86.3559 38.0617 86.2283 38.0099C86.1007 37.9581 85.9846 37.8805 85.8872 37.7817C85.7097 37.5406 85.6138 37.2471 85.6138 36.9455C85.6138 36.6438 85.7097 36.3504 85.8872 36.1092C85.9858 36.0091 86.1035 35.9307 86.233 35.8789C86.3625 35.8271 86.501 35.803 86.6399 35.808C86.86 35.7966 87.0778 35.8586 87.2605 35.9846C87.4259 36.1127 87.5413 36.2969 87.586 36.504Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M88.5728 35.0391V36.1402C88.6496 36.0382 88.7481 35.9552 88.8609 35.8977C88.9737 35.8401 89.0977 35.8094 89.2238 35.8078C89.3458 35.81 89.4664 35.8347 89.5798 35.8805C89.6742 35.923 89.7551 35.9913 89.8138 36.0779C89.8672 36.1524 89.9052 36.2372 89.9257 36.3272C89.9335 36.4725 89.9335 36.6182 89.9257 36.7635V38.0309H89.3662V36.8882C89.3835 36.7433 89.3835 36.5968 89.3662 36.4519C89.3468 36.3914 89.3072 36.3399 89.2543 36.3064C89.1937 36.2674 89.1224 36.2492 89.0509 36.2545C88.9623 36.2527 88.8747 36.2742 88.7966 36.3168C88.7238 36.373 88.6675 36.4484 88.6338 36.535C88.5963 36.6665 88.5792 36.8032 88.5829 36.9401V37.9789H88.0234V35.0391H88.5728Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M91.7876 36.7635C91.7925 36.6167 91.7455 36.4729 91.6553 36.3584C91.6196 36.3176 91.5757 36.2853 91.5264 36.2638C91.4772 36.2422 91.424 36.232 91.3705 36.2337C91.313 36.2316 91.2557 36.2427 91.2029 36.266C91.1501 36.2894 91.1031 36.3245 91.0653 36.3688C90.9799 36.4733 90.9363 36.6068 90.9432 36.7427L91.7876 36.7635ZM91.7876 37.3453L92.347 37.4388C92.2893 37.6341 92.1671 37.8029 92.0012 37.9166C91.7748 38.0721 91.5001 38.1369 91.2299 38.0985C90.9596 38.0602 90.7127 37.9215 90.5363 37.7088C90.3874 37.4899 90.3124 37.2273 90.3227 36.9609C90.3079 36.6534 90.41 36.352 90.6075 36.1195C90.6936 36.0191 90.8001 35.9392 90.9196 35.8854C91.0391 35.8316 91.1687 35.8051 91.2993 35.8078C91.4397 35.8055 91.5791 35.8329 91.7087 35.8884C91.8382 35.9438 91.9551 36.026 92.052 36.1298C92.2569 36.4139 92.3512 36.7657 92.3165 37.1167H90.933C90.9316 37.2617 90.9823 37.4021 91.0755 37.5115C91.1148 37.5573 91.1633 37.5941 91.2177 37.6192C91.2721 37.6443 91.3311 37.6571 91.3908 37.6569C91.471 37.657 91.5492 37.6315 91.6146 37.5842C91.6761 37.5344 91.725 37.4703 91.757 37.3972L91.7876 37.3453Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M94.3914 36.899C94.3794 37.0738 94.4109 37.2489 94.4829 37.408C94.5226 37.4761 94.5794 37.5322 94.6473 37.5706C94.7152 37.6089 94.7919 37.6281 94.8695 37.6262C94.9328 37.627 94.9954 37.6123 95.052 37.5834C95.1087 37.5545 95.1577 37.5122 95.195 37.46C95.2992 37.3125 95.3496 37.1324 95.3374 36.9509C95.3516 36.7643 95.3051 36.5781 95.2052 36.4211C95.1647 36.3673 95.1122 36.3241 95.052 36.2952C94.9918 36.2663 94.9258 36.2525 94.8593 36.2549C94.7969 36.2529 94.7349 36.2659 94.6783 36.293C94.6217 36.3201 94.5722 36.3604 94.5338 36.4107C94.4311 36.5511 94.3806 36.7241 94.3914 36.899ZM95.8969 38.0313H95.368V37.7197C95.2918 37.836 95.1864 37.9293 95.0628 37.9897C94.9536 38.0509 94.8312 38.083 94.7067 38.0832C94.5865 38.0831 94.4677 38.056 94.3587 38.004C94.2497 37.9519 94.1533 37.8762 94.076 37.782C93.8913 37.5438 93.8005 37.2436 93.8217 36.9405C93.7974 36.6371 93.8885 36.3358 94.076 36.0991C94.1553 36.0055 94.2539 35.9308 94.3647 35.8806C94.4754 35.8303 94.5957 35.8056 94.7169 35.8082C94.8364 35.8105 94.954 35.8395 95.0612 35.8934C95.1685 35.9473 95.2628 36.0246 95.3374 36.1199V35.0811H95.8969V38.0313Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M97.7181 36.7633C97.7182 36.6236 97.6716 36.488 97.5859 36.379C97.5494 36.3393 97.5052 36.3077 97.4562 36.2862C97.4072 36.2648 97.3544 36.2539 97.301 36.2543C97.2434 36.2515 97.186 36.2621 97.133 36.2855C97.0801 36.309 97.0332 36.3445 96.9958 36.3894C96.9105 36.4939 96.8669 36.6274 96.8738 36.7633H97.7181ZM97.6571 37.397L98.2166 37.4905C98.1552 37.684 98.0338 37.8517 97.8707 37.9684C97.7 38.0861 97.4966 38.1444 97.2909 38.1346C97.1338 38.1482 96.9759 38.121 96.8319 38.0556C96.6878 37.9902 96.5623 37.8888 96.4669 37.7606C96.3179 37.5416 96.2429 37.2791 96.2533 37.0127C96.2438 36.8601 96.264 36.7072 96.3129 36.5627C96.3618 36.4183 96.4384 36.2852 96.5381 36.1712C96.6241 36.0709 96.7307 35.991 96.8502 35.9372C96.9696 35.8833 97.0992 35.8568 97.2298 35.8596C97.3703 35.8573 97.5097 35.8847 97.6392 35.9401C97.7688 35.9955 97.8857 36.0778 97.9826 36.1816C98.1875 36.4657 98.2818 36.8174 98.2471 37.1685H96.8636C96.8584 37.3122 96.9055 37.4529 96.9958 37.5632C97.0379 37.6084 97.0883 37.6445 97.1442 37.6695C97.2001 37.6945 97.2604 37.7078 97.3214 37.7087C97.4016 37.7087 97.4798 37.6833 97.5452 37.6359C97.6029 37.5681 97.6415 37.4855 97.6571 37.397Z" fill="#1A1A18" />
                <path d="M99.2948 35.0391H98.7354V38.0412H99.2948V35.0391Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M101.767 38.0309L100.719 35.0391H101.36L102.102 37.2517L102.824 35.0391H103.455L102.397 38.0309H101.767Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M104.92 36.9923L104.584 37.0754C104.478 37.0947 104.375 37.1297 104.279 37.1793C104.246 37.2007 104.219 37.2298 104.2 37.2641C104.18 37.2985 104.169 37.337 104.167 37.3766C104.167 37.4174 104.175 37.4578 104.191 37.4953C104.207 37.5328 104.23 37.5667 104.259 37.5948C104.32 37.6513 104.4 37.6811 104.483 37.6779C104.586 37.6781 104.687 37.6414 104.767 37.574C104.832 37.5298 104.879 37.4635 104.9 37.387C104.913 37.2941 104.913 37.1995 104.9 37.1066V36.9923H104.92ZM104.167 36.5248L103.659 36.4313C103.706 36.2474 103.809 36.0837 103.954 35.9639C104.143 35.8476 104.363 35.7933 104.584 35.808C104.775 35.7975 104.966 35.8294 105.144 35.9015C105.252 35.9458 105.341 36.0264 105.398 36.1301C105.463 36.2947 105.491 36.4723 105.479 36.6495V37.3247C105.465 37.4628 105.465 37.6021 105.479 37.7402C105.501 37.8412 105.536 37.9389 105.581 38.0311H105.022L104.971 37.8649C104.968 37.8408 104.968 37.8163 104.971 37.7922C104.883 37.8879 104.775 37.9625 104.656 38.0103C104.547 38.0611 104.429 38.086 104.31 38.083C104.119 38.0919 103.933 38.0249 103.791 37.8961C103.73 37.8378 103.682 37.7668 103.65 37.6879C103.619 37.6091 103.604 37.5241 103.608 37.439C103.602 37.3256 103.63 37.2132 103.689 37.1169C103.747 37.0178 103.832 36.9382 103.933 36.8884C104.077 36.826 104.227 36.7807 104.381 36.7534C104.565 36.7287 104.746 36.6834 104.92 36.6183V36.556C104.925 36.5149 104.921 36.4733 104.909 36.4339C104.896 36.3945 104.876 36.3582 104.849 36.3274C104.758 36.2689 104.65 36.2433 104.544 36.2547C104.461 36.2487 104.379 36.2706 104.31 36.3171C104.24 36.3673 104.19 36.4407 104.167 36.5248Z" fill="#1A1A18" />
                <path d="M106.588 35.0391H106.029V38.0412H106.588V35.0391Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M108.765 36.899C108.752 37.0695 108.78 37.2408 108.847 37.3976C108.885 37.4649 108.94 37.5206 109.006 37.559C109.072 37.5973 109.147 37.6169 109.223 37.6158C109.288 37.6166 109.352 37.602 109.41 37.5731C109.469 37.5443 109.519 37.502 109.559 37.4496C109.66 37.3007 109.706 37.1207 109.691 36.9405C109.71 36.7535 109.663 36.5656 109.559 36.4107C109.521 36.3564 109.471 36.3126 109.412 36.2835C109.353 36.2545 109.288 36.2411 109.223 36.2445C109.16 36.244 109.097 36.2576 109.039 36.2846C108.981 36.3115 108.929 36.351 108.888 36.4004C108.788 36.5421 108.742 36.715 108.755 36.8886L108.765 36.899ZM110.261 38.0313H109.742V37.7197C109.664 37.8339 109.559 37.9266 109.437 37.9897C109.327 38.0486 109.205 38.0806 109.081 38.0832C108.961 38.0831 108.842 38.056 108.733 38.004C108.624 37.9519 108.527 37.8762 108.45 37.782C108.263 37.5448 108.168 37.2448 108.186 36.9405C108.166 36.6377 108.257 36.3382 108.44 36.0991C108.521 36.0071 108.619 35.9336 108.73 35.8834C108.84 35.8333 108.96 35.8077 109.081 35.8082C109.201 35.8082 109.319 35.8363 109.427 35.8903C109.534 35.9444 109.628 36.0229 109.701 36.1199V35.0811H110.261V38.0313Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M110.82 35.8597H111.38V38.0309H110.82V35.8597ZM110.82 35.0391H111.38V35.5689H110.82V35.0391Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M113.109 38.0309V35.0391H113.689L114.89 37.0336V35.0391H115.439V38.0309H114.849L113.659 36.0779V38.0309H113.109Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M116.507 36.9506C116.496 37.1271 116.55 37.3014 116.659 37.4389C116.706 37.4947 116.763 37.5394 116.828 37.5699C116.893 37.6004 116.964 37.616 117.036 37.6155C117.106 37.6145 117.174 37.5982 117.238 37.5678C117.301 37.5373 117.357 37.4933 117.402 37.4389C117.512 37.2982 117.567 37.1201 117.554 36.9402C117.565 36.7637 117.511 36.5895 117.402 36.452C117.357 36.3975 117.301 36.3535 117.238 36.3231C117.174 36.2926 117.106 36.2763 117.036 36.2754C116.964 36.2748 116.893 36.2904 116.828 36.3209C116.763 36.3514 116.706 36.3962 116.659 36.452C116.549 36.5927 116.494 36.7707 116.507 36.9506ZM115.937 36.9506C115.936 36.7554 115.981 36.5629 116.069 36.3896C116.158 36.2163 116.296 36.0749 116.466 35.9845C116.64 35.8865 116.837 35.8364 117.036 35.8391C117.182 35.8362 117.327 35.8636 117.462 35.9196C117.597 35.9756 117.72 36.0591 117.822 36.165C117.925 36.2708 118.006 36.3969 118.059 36.5355C118.113 36.6741 118.138 36.8224 118.134 36.9714C118.138 37.1227 118.111 37.2731 118.057 37.414C118.003 37.5549 117.922 37.6834 117.819 37.7921C117.719 37.8999 117.597 37.9844 117.462 38.04C117.326 38.0955 117.181 38.1208 117.036 38.1141C116.841 38.1164 116.649 38.0701 116.476 37.979C116.304 37.8931 116.162 37.7553 116.069 37.5843C115.98 37.3752 115.945 37.1463 115.968 36.9194L115.937 36.9506Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M119.548 35.86V36.3586H119.162V37.2416C119.153 37.3418 119.153 37.4427 119.162 37.5429C119.155 37.5667 119.155 37.5918 119.162 37.6156H119.253C119.333 37.6092 119.412 37.5917 119.487 37.5636V38.0103C119.348 38.0666 119.2 38.0948 119.05 38.0934C118.959 38.1102 118.866 38.1102 118.775 38.0934C118.703 38.066 118.64 38.0194 118.592 37.9584C118.549 37.8889 118.522 37.811 118.511 37.7299C118.501 37.595 118.501 37.4596 118.511 37.3247V36.3586H118.256V35.9015H118.511V35.4756L119.08 35.1328V35.9015L119.548 35.86Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M120.382 36.9509C120.372 37.1274 120.426 37.3016 120.535 37.4391C120.58 37.4936 120.636 37.5375 120.699 37.568C120.762 37.5985 120.831 37.6147 120.901 37.6157C120.973 37.6162 121.044 37.6007 121.109 37.5702C121.174 37.5397 121.231 37.4949 121.278 37.4391C121.388 37.2984 121.443 37.1204 121.43 36.9405C121.441 36.764 121.386 36.5897 121.278 36.4522C121.231 36.3964 121.174 36.3517 121.109 36.3211C121.044 36.2906 120.973 36.2751 120.901 36.2756C120.831 36.2766 120.762 36.2929 120.699 36.3233C120.636 36.3538 120.58 36.3977 120.535 36.4522C120.424 36.5929 120.37 36.771 120.382 36.9509ZM119.813 36.9509C119.812 36.7557 119.857 36.5632 119.945 36.3899C120.034 36.2166 120.172 36.0752 120.342 35.9848C120.513 35.888 120.706 35.8379 120.901 35.8393C121.048 35.835 121.194 35.8613 121.33 35.9167C121.467 35.9721 121.591 36.0553 121.695 36.1614C121.891 36.3859 121.999 36.6761 121.999 36.9768C121.999 37.2776 121.891 37.5678 121.695 37.7923C121.594 37.9001 121.472 37.9847 121.337 38.0402C121.202 38.0958 121.057 38.1211 120.911 38.1143C120.717 38.1167 120.525 38.0703 120.352 37.9793C120.178 37.8963 120.035 37.7577 119.945 37.5845C119.845 37.3783 119.8 37.1493 119.813 36.9197V36.9509Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M51.2803 40.4414H51.6363V42.5918H51.2803V40.4414ZM51.2803 39.6104H51.6363V40.0363H51.2803V39.6104Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M52.043 41.9577H52.399C52.4119 42.0873 52.474 42.2066 52.5719 42.2901C52.6789 42.3751 52.8133 42.4159 52.9483 42.4044C53.079 42.4194 53.2102 42.3821 53.3145 42.3005C53.3523 42.2737 53.3831 42.2379 53.4043 42.1963C53.4256 42.1547 53.4367 42.1085 53.4366 42.0615C53.4376 42.0225 53.4287 41.9839 53.4109 41.9493C53.3931 41.9148 53.3669 41.8855 53.3349 41.8642C53.2176 41.8093 53.0948 41.7675 52.9687 41.7395C52.7799 41.7008 52.5958 41.6416 52.4194 41.5629C52.3205 41.5228 52.238 41.4495 52.1854 41.3551C52.1327 41.2676 52.1045 41.167 52.104 41.0643C52.1035 40.9706 52.1244 40.8781 52.165 40.7942C52.2119 40.7132 52.2742 40.6425 52.3481 40.5864C52.4168 40.5398 52.4924 40.5048 52.5719 40.4825C52.6765 40.4668 52.7827 40.4668 52.8873 40.4825C53.0363 40.4778 53.1848 40.5025 53.3247 40.5553C53.4362 40.5918 53.5351 40.6603 53.6096 40.7526C53.6777 40.8547 53.7197 40.9725 53.7316 41.0954H53.3756C53.3657 40.9948 53.3184 40.9018 53.2433 40.8357C53.1443 40.7682 53.0265 40.7354 52.9076 40.7422C52.7867 40.733 52.666 40.762 52.5618 40.8253C52.5291 40.8468 52.5019 40.8759 52.4824 40.9102C52.463 40.9445 52.4518 40.9831 52.4499 41.0227C52.435 41.0629 52.435 41.1072 52.4499 41.1474C52.4828 41.1947 52.5289 41.2309 52.5821 41.2513L52.8975 41.3448L53.4366 41.511C53.5309 41.5535 53.6119 41.6218 53.6706 41.7083C53.7337 41.7992 53.7658 41.9087 53.7621 42.02C53.7596 42.1464 53.7207 42.2692 53.6502 42.3732C53.576 42.4822 53.474 42.5685 53.3552 42.6225C53.2112 42.6874 53.0549 42.7193 52.8975 42.716C52.6739 42.7296 52.4522 42.6676 52.2668 42.5394C52.1227 42.3814 52.0427 42.1735 52.043 41.9577Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M55.6239 41.8128H55.98C55.9564 42.0376 55.8511 42.2451 55.685 42.3945C55.5225 42.541 55.3116 42.619 55.095 42.6127C54.9637 42.6162 54.8333 42.5911 54.7123 42.5392C54.5913 42.4872 54.4825 42.4096 54.3931 42.3114C54.2035 42.0795 54.1087 41.7818 54.1286 41.4804C54.1253 41.2705 54.1634 41.0621 54.2405 40.8675C54.3099 40.7005 54.4315 40.5616 54.5863 40.4727C54.7414 40.383 54.9168 40.3364 55.095 40.3377C55.2989 40.3293 55.4997 40.3915 55.6646 40.5143C55.8185 40.6417 55.9201 40.8233 55.9495 41.0233L55.5934 41.0752C55.5721 40.9514 55.5113 40.8382 55.4205 40.7532C55.3364 40.6774 55.2275 40.6366 55.1153 40.6389C55.031 40.6352 54.9469 40.651 54.8694 40.6852C54.7918 40.7194 54.7229 40.771 54.6677 40.8363C54.5393 41.0204 54.4781 41.2446 54.4948 41.47C54.4763 41.6955 54.5376 41.9204 54.6677 42.1037C54.7188 42.1685 54.784 42.2202 54.8583 42.2544C54.9325 42.2887 55.0136 42.3047 55.095 42.301C55.2245 42.3045 55.3508 42.2603 55.451 42.1764C55.5437 42.0761 55.604 41.9492 55.6239 41.8128Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M56.2852 42.592V40.4417H56.6107V40.7637C56.6655 40.6466 56.7454 40.5436 56.8446 40.4624C56.9094 40.4138 56.988 40.3882 57.0684 40.3897C57.2015 40.3936 57.3313 40.433 57.4448 40.504L57.3228 40.8468C57.242 40.8001 57.151 40.7751 57.0583 40.7741C56.9807 40.7706 56.9047 40.7965 56.8446 40.8468C56.7788 40.8942 56.7319 40.9643 56.7124 41.0442C56.6707 41.1821 56.6501 41.3257 56.6514 41.4701V42.592H56.2852Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M57.6584 40.4414H57.9839V42.5918H57.6177V40.4414H57.6584ZM57.6584 39.6104H57.9839V40.0363H57.6177L57.6584 39.6104Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M59.3573 42.2803V42.6023C59.2659 42.6109 59.174 42.6109 59.0826 42.6023C58.9761 42.6095 58.8699 42.5842 58.7774 42.5296C58.7095 42.4936 58.6557 42.4349 58.6248 42.3634C58.6061 42.2221 58.6061 42.0788 58.6248 41.9375V40.7221H58.3604V40.4416H58.6248V39.9014L58.9809 39.6729V40.4416H59.3471V40.7221H59.0012V41.9894C58.9913 42.0583 58.9913 42.1283 59.0012 42.1972C59.0157 42.2259 59.0367 42.2508 59.0623 42.2699C59.1022 42.2815 59.1444 42.2815 59.1843 42.2699L59.3573 42.2803Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M60.4965 42.2803L60.5474 42.6023C60.456 42.6109 60.3641 42.6109 60.2727 42.6023C60.1883 42.6185 60.1013 42.6149 60.0184 42.5919C59.9505 42.5559 59.8966 42.4973 59.8658 42.4257C59.8471 42.2844 59.8471 42.1411 59.8658 41.9998V40.7221H59.5098V40.4416H59.7743V39.9014L60.1303 39.6729V40.4416H60.4965V40.7221H60.1303V41.9894C60.1204 42.0583 60.1204 42.1283 60.1303 42.1972L60.1913 42.2699C60.2346 42.2823 60.2803 42.2823 60.3236 42.2699L60.4965 42.2803Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M61.0865 41.5529C61.0687 41.7768 61.134 41.9993 61.2696 42.1762C61.3228 42.2446 61.3911 42.2991 61.4689 42.3352C61.5467 42.3713 61.6318 42.388 61.7172 42.3839C61.8024 42.3864 61.887 42.369 61.9646 42.333C62.0422 42.297 62.1106 42.2433 62.1648 42.1762C62.2925 41.9917 62.3536 41.7678 62.3378 41.5425C62.3521 41.3222 62.287 41.1042 62.1547 40.9296C62.1015 40.8638 62.0347 40.811 61.959 40.7751C61.8833 40.7391 61.8007 40.721 61.7172 40.7218C61.6322 40.7201 61.5478 40.7379 61.4704 40.7739C61.3929 40.8098 61.3243 40.863 61.2696 40.9296C61.134 41.1065 61.0687 41.329 61.0865 41.5529ZM60.7203 41.5529C60.7056 41.3886 60.7272 41.223 60.7835 41.0684C60.8399 40.9137 60.9295 40.774 61.0458 40.6595C61.2342 40.5 61.4726 40.4152 61.7172 40.4206C61.8492 40.4156 61.9808 40.4389 62.1034 40.489C62.2261 40.5391 62.3371 40.6149 62.4293 40.7114C62.625 40.9327 62.7274 41.2239 62.7142 41.5217C62.7262 41.7512 62.6807 41.9801 62.5819 42.1866C62.5018 42.3443 62.378 42.4743 62.2259 42.5605C62.0698 42.6477 61.8951 42.6941 61.7172 42.6956C61.5828 42.7007 61.4488 42.6765 61.3243 42.6245C61.1997 42.5726 61.0875 42.4941 60.995 42.3943C60.8966 42.2799 60.8216 42.1464 60.7744 42.0019C60.7273 41.8574 60.7089 41.7048 60.7203 41.5529Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M64.2705 42.5912V40.4408H64.596V40.7421C64.6692 40.6272 64.7707 40.534 64.8904 40.472C65.01 40.41 65.1436 40.3813 65.2776 40.3889C65.4 40.3868 65.5214 40.4116 65.6336 40.4616C65.7265 40.4965 65.8077 40.5577 65.8676 40.6382C65.9212 40.724 65.9591 40.819 65.9795 40.9187C65.9933 41.036 65.9933 41.1546 65.9795 41.2719V42.5912H65.6235V41.2926C65.6387 41.1789 65.6387 41.0636 65.6235 40.9498C65.5932 40.8753 65.5395 40.8132 65.4709 40.7732C65.393 40.7297 65.3053 40.7082 65.2166 40.7109C65.0708 40.7047 64.9284 40.7569 64.8198 40.8563C64.7559 40.9351 64.7084 41.0265 64.6804 41.1248C64.6524 41.2231 64.6445 41.3262 64.6571 41.4277V42.5912H64.2705Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M66.824 41.3139H68.0142C68.011 41.1652 67.965 41.0206 67.8819 40.8984C67.8259 40.839 67.7588 40.7918 67.6845 40.7595C67.6102 40.7272 67.5303 40.7106 67.4496 40.7106C67.3689 40.7106 67.289 40.7272 67.2147 40.7595C67.1404 40.7918 67.0732 40.839 67.0173 40.8984C66.9029 41.0233 66.8346 41.1848 66.824 41.3555V41.3139ZM68.0142 41.906H68.3804C68.3357 42.1131 68.2203 42.2973 68.0549 42.4255C67.8787 42.558 67.663 42.6241 67.4445 42.6124C67.3089 42.6146 67.1743 42.5891 67.0485 42.5373C66.9227 42.4856 66.8083 42.4087 66.7121 42.3112C66.5185 42.0851 66.4198 41.7903 66.4374 41.4905C66.4182 41.1811 66.5166 40.876 66.7121 40.6387C66.805 40.5394 66.9173 40.4613 67.0417 40.4094C67.1662 40.3575 67.3 40.333 67.4343 40.3374C67.5632 40.3334 67.6914 40.3573 67.8106 40.4074C67.9298 40.4575 68.0373 40.5328 68.1261 40.6283C68.3219 40.8613 68.4205 41.1634 68.4007 41.4697C68.4048 41.5008 68.4048 41.5322 68.4007 41.5632H66.8036C66.8061 41.7654 66.8781 41.9603 67.0071 42.1138C67.0649 42.175 67.1346 42.2232 67.2116 42.2554C67.2887 42.2876 67.3714 42.303 67.4547 42.3008C67.5749 42.3062 67.6933 42.2695 67.7904 42.1969C67.8865 42.1194 67.9632 42.0197 68.0142 41.906Z" fill="#1A1A18" />
                <path d="M69.1837 39.6104H68.8276V42.6125H69.1837V39.6104Z" fill="#1A1A18" />
                <path d="M70.0992 39.6104H69.7432V42.6125H70.0992V39.6104Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M72.0222 41.5527C71.8317 41.6224 71.6335 41.6678 71.4321 41.6877C71.325 41.7014 71.2193 41.7258 71.1168 41.7605C71.0587 41.7873 71.0093 41.8306 70.9744 41.8851C70.9583 41.9428 70.9583 42.004 70.9744 42.0617C70.9747 42.1075 70.9849 42.1526 71.0042 42.1939C71.0236 42.2352 71.0516 42.2717 71.0863 42.3006C71.1291 42.339 71.1792 42.3681 71.2334 42.3859C71.2876 42.4038 71.3449 42.4101 71.4016 42.4045C71.5329 42.4069 71.6625 42.3747 71.778 42.311C71.8804 42.2513 71.9622 42.1605 72.012 42.0513C72.0581 41.9361 72.0789 41.812 72.073 41.6877V41.5527H72.0222ZM72.0222 42.3734C71.9064 42.4759 71.776 42.56 71.6356 42.6227C71.5081 42.6686 71.3741 42.6932 71.2389 42.6954C71.0439 42.7072 70.8516 42.6443 70.6997 42.5188C70.6377 42.4631 70.5883 42.3943 70.555 42.3172C70.5216 42.24 70.505 42.1564 70.5064 42.0721C70.507 41.9707 70.5315 41.871 70.5776 41.7812C70.6291 41.6846 70.7069 41.6053 70.8014 41.5527C70.8782 41.4982 70.9645 41.4593 71.0558 41.4384L71.3813 41.3761C71.5987 41.3586 71.8136 41.3168 72.0222 41.2514V41.1372C72.0289 41.0792 72.0233 41.0204 72.0058 40.9648C71.9882 40.9092 71.9591 40.8581 71.9204 40.8151C71.8038 40.7252 71.6594 40.6809 71.5135 40.6905C71.3815 40.6798 71.2496 40.7126 71.1371 40.784C71.042 40.8669 70.9774 40.9805 70.954 41.106H70.598C70.6232 40.9702 70.6789 40.8421 70.7607 40.732C70.8449 40.6318 70.9538 40.5565 71.0761 40.5139C71.2336 40.4614 71.3988 40.4368 71.5644 40.4412C71.7126 40.4361 71.8606 40.4572 72.0018 40.5035C72.0989 40.5363 72.1863 40.5934 72.2561 40.6697C72.3134 40.7418 72.3519 40.8276 72.368 40.919C72.3766 41.0331 72.3766 41.1477 72.368 41.2618V41.7501C72.3527 41.9679 72.3527 42.1866 72.368 42.4045C72.3801 42.4968 72.4113 42.5853 72.4596 42.6642H72.1239C72.0766 42.5638 72.0523 42.4537 72.0527 42.3422L72.0222 42.3734Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M74.1177 42.6125V39.6104H74.5042V42.2593H75.9487V42.6125H74.1177Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M76.3657 40.4414H76.7319V42.5918H76.3657V40.4414ZM76.3657 39.6104H76.7319V40.0363H76.3657V39.6104Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M77.1387 41.9576H77.4948C77.504 42.086 77.5625 42.2054 77.6575 42.29C77.7685 42.3748 77.9059 42.4155 78.0441 42.4043C78.1744 42.4162 78.3046 42.3793 78.4103 42.3004C78.448 42.2736 78.4788 42.2378 78.5001 42.1962C78.5213 42.1546 78.5324 42.1084 78.5324 42.0615C78.5313 42.0216 78.5205 41.9827 78.501 41.9483C78.4814 41.9138 78.4537 41.8849 78.4205 41.8641C78.3038 41.8074 78.1809 41.7655 78.0543 41.7394C77.8655 41.7007 77.6814 41.6415 77.5049 41.5628C77.4077 41.5201 77.3259 41.4475 77.271 41.3551C77.2183 41.2675 77.1901 41.1669 77.1896 41.0642C77.1876 40.9691 77.2123 40.8754 77.2608 40.7941C77.304 40.7136 77.3628 40.6429 77.4337 40.5863C77.5061 40.5398 77.585 40.5048 77.6677 40.4825C77.7688 40.4668 77.8717 40.4668 77.9729 40.4825C78.1252 40.478 78.277 40.5026 78.4205 40.5552C78.5278 40.5944 78.6227 40.6626 78.6951 40.7525C78.7633 40.8546 78.8053 40.9725 78.8172 41.0954H78.4713C78.4578 40.9935 78.4069 40.9006 78.3289 40.8357C78.2337 40.7682 78.1191 40.7353 78.0034 40.7422C77.8793 40.7334 77.7554 40.7623 77.6473 40.8253C77.6159 40.8472 77.5903 40.8767 77.5725 40.9111C77.5548 40.9455 77.5456 40.9838 77.5456 41.0226C77.5298 41.0626 77.5298 41.1073 77.5456 41.1473C77.5829 41.1946 77.6322 41.2305 77.688 41.2512L78.0034 41.3447L78.5324 41.5109C78.628 41.5559 78.7117 41.6236 78.7765 41.7083C78.8319 41.8022 78.8601 41.9103 78.8579 42.0199C78.8589 42.1453 78.8235 42.2682 78.7562 42.3731C78.6781 42.4825 78.5727 42.5686 78.451 42.6224C78.3107 42.6878 78.1576 42.7198 78.0034 42.7159C77.7798 42.7295 77.5581 42.6675 77.3727 42.5393C77.2952 42.4651 77.2342 42.3747 77.1939 42.2744C77.1535 42.174 77.1347 42.066 77.1387 41.9576Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1195 42.2803V42.6023C80.0281 42.6109 79.9362 42.6109 79.8448 42.6023C79.7382 42.6108 79.6316 42.5854 79.5396 42.5296C79.4717 42.4936 79.4179 42.4349 79.387 42.3634C79.3629 42.2225 79.3629 42.0784 79.387 41.9375V40.7221H79.1226V40.4416H79.387V39.9014L79.7431 39.6729V40.4416H80.1093V40.7221H79.7533V41.9894C79.7434 42.0583 79.7434 42.1283 79.7533 42.1972C79.7642 42.2282 79.7859 42.2541 79.8143 42.2699C79.8542 42.2815 79.8965 42.2815 79.9364 42.2699L80.1195 42.2803Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M81.8282 41.5527C81.6378 41.6224 81.4396 41.6678 81.2382 41.6877C81.1312 41.7027 81.0257 41.727 80.9229 41.7605C80.8667 41.7904 80.818 41.8331 80.7804 41.8851C80.7643 41.9428 80.7643 42.004 80.7804 42.0617C80.7808 42.1075 80.791 42.1526 80.8103 42.1939C80.8296 42.2352 80.8576 42.2717 80.8923 42.3006C80.98 42.3762 81.0933 42.4135 81.2077 42.4045C81.3389 42.4069 81.4686 42.3747 81.5841 42.311C81.6888 42.2543 81.7714 42.1627 81.818 42.0513C81.8642 41.9361 81.885 41.812 81.8791 41.6877V41.5527H81.8282ZM81.8282 42.3734C81.7125 42.4759 81.5821 42.56 81.4417 42.6227C81.3141 42.6686 81.1802 42.6932 81.0449 42.6954C80.8499 42.7072 80.6576 42.6443 80.5058 42.5188C80.4453 42.4618 80.3969 42.3928 80.3637 42.316C80.3304 42.2391 80.313 42.1561 80.3125 42.0721C80.3131 41.9707 80.3375 41.871 80.3837 41.7812C80.4284 41.6925 80.495 41.6173 80.577 41.5631C80.6537 41.5085 80.7401 41.4697 80.8313 41.4488L81.1568 41.3865C81.3743 41.369 81.5892 41.3272 81.7977 41.2618V41.1372C81.8045 41.0792 81.7989 41.0204 81.7813 40.9648C81.7638 40.9092 81.7347 40.8581 81.696 40.8151C81.5802 40.7234 81.4352 40.679 81.2891 40.6905C81.1571 40.6798 81.0252 40.7126 80.9127 40.784C80.8175 40.8669 80.7529 40.9805 80.7296 41.106H80.3837C80.4055 40.9709 80.4577 40.8429 80.5363 40.732C80.6204 40.6318 80.7293 40.5565 80.8517 40.5139C81.0092 40.4614 81.1743 40.4368 81.3399 40.4412C81.4882 40.4361 81.6361 40.4572 81.7774 40.5035C81.8757 40.5335 81.9638 40.5911 82.0317 40.6697C82.089 40.7418 82.1275 40.8276 82.1436 40.919C82.1521 41.0331 82.1521 41.1477 82.1436 41.2618V41.7501C82.1436 42.0929 82.1436 42.311 82.1436 42.4045C82.165 42.4942 82.1957 42.5812 82.2351 42.6642H81.8587C81.8355 42.5582 81.8355 42.4482 81.8587 42.3422L81.8282 42.3734Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M51.5139 46.5492C51.5028 46.774 51.5635 46.9964 51.6869 47.1829C51.7353 47.2462 51.7969 47.2978 51.8673 47.3337C51.9377 47.3697 52.0151 47.3891 52.0938 47.3907C52.1726 47.3899 52.2502 47.3707 52.3207 47.3347C52.3912 47.2987 52.4528 47.2468 52.5007 47.1829C52.6202 47.0067 52.6776 46.7942 52.6634 46.5804C52.6791 46.3486 52.6182 46.1182 52.4905 45.9259C52.4431 45.8614 52.3817 45.809 52.3111 45.773C52.2404 45.7369 52.1625 45.7182 52.0836 45.7182C52.0046 45.7182 51.9267 45.7369 51.8561 45.773C51.7855 45.809 51.7241 45.8614 51.6767 45.9259C51.5533 46.1081 51.4958 46.3285 51.5139 46.5492ZM52.6532 47.588V47.3699C52.5918 47.4723 52.5048 47.5561 52.4011 47.6127C52.2974 47.6693 52.1807 47.6967 52.0632 47.6919C51.8966 47.6916 51.7338 47.641 51.5953 47.5465C51.4478 47.4469 51.3313 47.3063 51.2596 47.1413C51.1825 46.954 51.1444 46.7524 51.1477 46.5492C51.1442 46.3504 51.1788 46.1528 51.2494 45.9675C51.3141 45.8012 51.4279 45.6596 51.575 45.5623C51.7129 45.4666 51.8761 45.4159 52.0429 45.4169C52.163 45.416 52.2816 45.4445 52.3888 45.5C52.4876 45.5493 52.5719 45.6246 52.6329 45.7182V44.6689H52.9889V47.6711L52.6532 47.588Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M53.8333 46.3417H55.0133C55.0119 46.1958 54.9657 46.0541 54.8811 45.9366C54.8285 45.8669 54.7605 45.8109 54.6828 45.773C54.605 45.7351 54.5196 45.7164 54.4335 45.7184C54.3559 45.7179 54.2791 45.7332 54.2074 45.7636C54.1358 45.7939 54.0708 45.8386 54.0164 45.895C53.8999 46.0133 53.834 46.1739 53.8333 46.3417ZM55.0133 46.9442H55.3897C55.3418 47.1565 55.2211 47.3444 55.0495 47.4739C54.8779 47.6034 54.6667 47.6661 54.4538 47.6506C54.3166 47.6542 54.18 47.6293 54.0524 47.5776C53.9247 47.5258 53.8087 47.4482 53.7112 47.3494C53.6144 47.238 53.5403 47.1081 53.4931 46.9672C53.446 46.8263 53.4267 46.6773 53.4366 46.5287C53.4271 46.2301 53.5248 45.9383 53.7112 45.708C53.8041 45.6088 53.9165 45.5307 54.0409 45.4788C54.1654 45.4268 54.2991 45.4023 54.4335 45.4068C54.5633 45.4027 54.6924 45.4276 54.8118 45.4796C54.9313 45.5316 55.0382 45.6096 55.1252 45.708C55.3204 45.9373 55.4191 46.2359 55.3999 46.5391V46.6326H53.813C53.8117 46.8336 53.8802 47.0287 54.0063 47.1831C54.0639 47.2452 54.1333 47.2947 54.2103 47.3286C54.2872 47.3625 54.3701 47.3802 54.4538 47.3805C54.5753 47.3829 54.6938 47.3426 54.7895 47.2663C54.8939 47.1841 54.9718 47.0719 55.0133 46.9442Z" fill="#1A1A18" />
                <path d="M56.1934 44.6377H55.8374V47.6399H56.1934V44.6377Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M58.3298 46.0609H59.0825C59.2721 46.0809 59.4627 46.0332 59.6217 45.9258C59.7151 45.832 59.7726 45.7071 59.7838 45.5738C59.795 45.4404 59.7592 45.3074 59.6827 45.1987C59.6255 45.111 59.5385 45.0481 59.4386 45.0221C59.3204 45.0061 59.2007 45.0061 59.0825 45.0221H58.3298V46.0609ZM57.9839 47.6399V44.6689H59.0011C59.15 44.6546 59.2999 44.6546 59.4487 44.6689C59.5789 44.6866 59.7038 44.7326 59.815 44.8039C59.9163 44.8786 59.9969 44.9788 60.0489 45.0948C60.1114 45.2239 60.1428 45.3663 60.1405 45.5103C60.1443 45.7474 60.0608 45.9773 59.9065 46.1544C59.7874 46.2563 59.6495 46.3326 59.501 46.379C59.3525 46.4254 59.1963 46.4409 59.0418 46.4245H58.2891V47.6399H57.9839Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M61.9916 46.5498C61.8008 46.6184 61.6028 46.6637 61.4016 46.6849C61.2944 46.6986 61.1888 46.7229 61.0863 46.7576C61.0282 46.7845 60.9788 46.8277 60.9438 46.8823C60.9292 46.9402 60.9292 47.0009 60.9438 47.0589C60.9418 47.105 60.9509 47.151 60.9704 47.1927C60.99 47.2343 61.0193 47.2704 61.0557 47.2978C61.0986 47.3362 61.1486 47.3652 61.2029 47.3831C61.2571 47.4009 61.3144 47.4073 61.3711 47.4017C61.4992 47.4052 61.6259 47.3729 61.7373 47.3082C61.8449 47.252 61.9309 47.1605 61.9815 47.0485C62.0286 46.9336 62.0494 46.8092 62.0425 46.6849V46.5498H61.9916ZM61.9916 47.3705C61.8743 47.4709 61.7443 47.5548 61.6051 47.6198C61.4775 47.6658 61.3436 47.6903 61.2083 47.6925C61.0133 47.7044 60.8211 47.6414 60.6692 47.5159C60.6061 47.4612 60.5559 47.3925 60.5224 47.3152C60.489 47.2378 60.4731 47.1538 60.4759 47.0692C60.4728 46.9674 60.4975 46.8667 60.5471 46.7784C60.5916 46.6927 60.6541 46.6182 60.7302 46.5602C60.8106 46.5056 60.9003 46.4668 60.9947 46.4459L61.3101 46.3836C61.5309 46.367 61.7493 46.3252 61.9611 46.259V46.1655C61.9679 46.1075 61.9623 46.0487 61.9447 45.9931C61.9272 45.9375 61.8981 45.8865 61.8594 45.8434C61.7396 45.7519 61.5915 45.7076 61.4423 45.7188C61.3104 45.7095 61.1789 45.7422 61.0659 45.8123C60.9779 45.8994 60.9176 46.0117 60.893 46.1343H60.5369C60.5687 45.9791 60.6348 45.8334 60.7302 45.7084C60.8125 45.6061 60.922 45.5303 61.0456 45.4902C61.2031 45.4378 61.3682 45.4132 61.5339 45.4175C61.682 45.4133 61.8299 45.4344 61.9713 45.4798C62.0672 45.5151 62.1541 45.5719 62.2256 45.6461C62.279 45.7206 62.317 45.8054 62.3375 45.8954C62.3461 46.0095 62.3461 46.1241 62.3375 46.2382V46.7472C62.3222 46.9651 62.3222 47.1838 62.3375 47.4017C62.3479 47.4943 62.3793 47.5832 62.429 47.6614H62.0527C62.0323 47.5655 62.0323 47.4663 62.0527 47.3705H61.9916Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M63.7009 47.3075L63.7518 47.64H63.4771C63.3719 47.646 63.2668 47.6246 63.172 47.5776C63.1085 47.5359 63.056 47.4788 63.0194 47.4114C62.9962 47.267 62.9962 47.1196 63.0194 46.9751V45.7078H62.7549V45.4273H63.0194V44.8871L63.3754 44.6689V45.4273H63.7416V45.7078H63.3754V46.9855C63.3661 47.051 63.3661 47.1174 63.3754 47.1829C63.3864 47.2139 63.4081 47.2398 63.4364 47.2556C63.4763 47.2677 63.5187 47.2677 63.5585 47.2556L63.7009 47.3075Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M64.0874 47.6401V45.469H64.4129V45.791C64.4706 45.6758 64.5501 45.5734 64.6469 45.4898C64.7117 45.4411 64.7903 45.4155 64.8707 45.4171C65.0038 45.421 65.1335 45.4604 65.2471 45.5313L65.125 45.8741C65.0443 45.8275 64.9533 45.8025 64.8605 45.8014C64.783 45.798 64.7069 45.8239 64.6469 45.8741C64.581 45.9216 64.5341 45.9916 64.5146 46.0715C64.473 46.2094 64.4524 46.3531 64.4536 46.4974V47.6401H64.0874Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M65.4199 45.4687H65.7861V47.6399H65.4199V45.4687ZM65.4199 44.6377H65.7861V45.0636H65.4199V44.6377Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M66.3354 47.6404V45.4693H66.6508V45.7705C66.7228 45.6683 66.8124 45.5803 66.9153 45.5108C67.0344 45.4471 67.1674 45.415 67.3019 45.4173C67.4381 45.4104 67.5731 45.4467 67.6884 45.5212C67.7909 45.5847 67.867 45.6846 67.902 45.8017C67.9672 45.6842 68.0619 45.5867 68.1764 45.5191C68.2908 45.4515 68.4209 45.4164 68.5531 45.4173C68.6419 45.4117 68.731 45.4244 68.8149 45.4548C68.8988 45.4851 68.9758 45.5324 69.0414 45.5939C69.166 45.748 69.2279 45.9451 69.2143 46.1445V47.6404H68.8583V46.2691C68.8791 46.1664 68.8791 46.0603 68.8583 45.9575C68.839 45.8886 68.7955 45.8295 68.7362 45.7913C68.6659 45.7745 68.5928 45.7745 68.5226 45.7913C68.3834 45.7883 68.2485 45.8405 68.1462 45.9367C68.0345 46.0727 67.9796 46.2481 67.9936 46.425V47.6923H67.6375V46.2276C67.6488 46.0993 67.6165 45.9709 67.546 45.864C67.5124 45.8207 67.4688 45.7866 67.419 45.7648C67.3693 45.7431 67.315 45.7343 67.2612 45.7393C67.1601 45.7363 67.0605 45.7653 66.9763 45.8225C66.8893 45.8772 66.8243 45.9619 66.7932 46.0614C66.7462 46.2052 66.7255 46.3566 66.7322 46.5081V47.6404H66.3354Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M69.9876 46.55C69.9765 46.7747 70.0372 46.9971 70.1605 47.1836C70.2177 47.2499 70.2882 47.3028 70.3672 47.3386C70.4462 47.3745 70.5318 47.3925 70.6183 47.3914C70.7029 47.3926 70.7865 47.3735 70.8625 47.3356C70.9385 47.2977 71.0047 47.2421 71.0557 47.1733C71.1752 46.9883 71.2388 46.7715 71.2388 46.55C71.2388 46.3284 71.1752 46.1117 71.0557 45.9267C71.0026 45.8609 70.9357 45.8081 70.8601 45.7722C70.7844 45.7362 70.7018 45.718 70.6183 45.7189C70.5318 45.7178 70.4462 45.7358 70.3672 45.7717C70.2882 45.8075 70.2177 45.8604 70.1605 45.9267C70.0344 46.1079 69.9733 46.3281 69.9876 46.55ZM69.6112 46.55C69.5992 46.385 69.6231 46.2193 69.6811 46.0649C69.7391 45.9104 69.8299 45.771 69.9469 45.6566C70.1353 45.4971 70.3736 45.4122 70.6183 45.4176C70.7503 45.4127 70.8819 45.436 71.0045 45.4861C71.1271 45.5362 71.2382 45.612 71.3304 45.7085C71.5224 45.9314 71.621 46.2224 71.605 46.5188C71.615 46.747 71.5732 46.9746 71.483 47.1836C71.4001 47.3393 71.2769 47.4686 71.1269 47.5576C70.9709 47.6448 70.7962 47.6912 70.6183 47.6927C70.4827 47.6948 70.3481 47.6693 70.2223 47.6176C70.0965 47.5658 69.9821 47.4889 69.8859 47.3914C69.7875 47.2769 69.7125 47.1435 69.6653 46.999C69.6182 46.8545 69.5998 46.7018 69.6112 46.55Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M72.0322 47.6405V45.4694H72.3577V45.7707C72.4307 45.6581 72.5308 45.5665 72.6483 45.5046C72.7659 45.4428 72.897 45.4128 73.0291 45.4175C73.1517 45.4133 73.2736 45.4382 73.3852 45.4902C73.4851 45.5222 73.5713 45.5882 73.6293 45.6772C73.6847 45.7582 73.7228 45.8502 73.7412 45.9473C73.7496 46.0648 73.7496 46.1829 73.7412 46.3005V47.6405H73.3852V46.3212C73.4066 46.208 73.4066 46.0916 73.3852 45.9784C73.3623 45.9027 73.3111 45.8393 73.2428 45.8018C73.1646 45.7592 73.077 45.7378 72.9885 45.7395C72.8435 45.7383 72.7029 45.7899 72.5917 45.8849C72.526 45.9629 72.4767 46.0539 72.4469 46.1522C72.4171 46.2505 72.4076 46.354 72.4188 46.4563V47.6405H72.0322Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M74.3208 45.4687H74.6768V47.6399H74.3208V45.4687ZM74.3208 44.6377H74.6768V45.0636H74.3208V44.6377Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M75.4603 46.5494C75.4447 46.7762 75.5098 47.0012 75.6434 47.183C75.6981 47.2496 75.7667 47.3028 75.8442 47.3388C75.9216 47.3747 76.006 47.3925 76.091 47.3908C76.1769 47.3912 76.2618 47.3719 76.3393 47.3341C76.4168 47.2963 76.485 47.2411 76.5386 47.1726C76.6521 46.9854 76.7122 46.7696 76.7122 46.5494C76.7122 46.3291 76.6521 46.1133 76.5386 45.9261C76.4826 45.8611 76.4138 45.809 76.3366 45.7732C76.2595 45.7374 76.1758 45.7187 76.091 45.7183C76.006 45.7166 75.9216 45.7344 75.8442 45.7703C75.7667 45.8063 75.6981 45.8595 75.6434 45.9261C75.5096 46.1039 75.4444 46.3257 75.4603 46.5494ZM75.0941 46.5494C75.0829 46.3854 75.1061 46.2208 75.1622 46.0667C75.2184 45.9126 75.3062 45.7726 75.4196 45.656C75.608 45.4965 75.8464 45.4116 76.091 45.417C76.2232 45.4107 76.3551 45.4335 76.478 45.4836C76.6008 45.5338 76.7117 45.6103 76.8031 45.7079C76.9019 45.8162 76.9782 45.9438 77.0271 46.0831C77.0761 46.2224 77.0968 46.3705 77.0879 46.5182C77.0979 46.7464 77.0561 46.974 76.9659 47.183C76.8821 47.3414 76.7548 47.4714 76.5997 47.557C76.4436 47.6442 76.2689 47.6906 76.091 47.6921C75.9567 47.6965 75.8229 47.672 75.6985 47.6201C75.574 47.5682 75.4617 47.49 75.3688 47.3908C75.2704 47.2763 75.1954 47.1429 75.1482 46.9984C75.1011 46.8539 75.0827 46.7012 75.0941 46.5494Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M78.6851 47.6405V44.6695H79.2649L79.9668 46.7471C80.0278 46.9445 80.0787 47.0899 80.099 47.1834C80.099 47.0795 80.1906 46.9133 80.2618 46.7056L80.9637 44.6279H81.4825V47.6301H81.1163V45.1266L80.2618 47.6405H79.9058L79.0614 45.085V47.6405H78.6851Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M82.3064 46.5494C82.2953 46.7742 82.356 46.9966 82.4794 47.1831C82.5354 47.248 82.6042 47.3001 82.6814 47.3359C82.7585 47.3717 82.8422 47.3904 82.927 47.3908C83.0131 47.3929 83.0985 47.3742 83.1762 47.3363C83.254 47.2984 83.3219 47.2424 83.3746 47.1727C83.494 46.9877 83.5577 46.771 83.5577 46.5494C83.5577 46.3278 83.494 46.1111 83.3746 45.9261C83.3194 45.8601 83.2507 45.8073 83.1734 45.7714C83.0961 45.7355 83.0119 45.7174 82.927 45.7183C82.8422 45.7187 82.7585 45.7375 82.6814 45.7733C82.6042 45.8091 82.5354 45.8612 82.4794 45.9261C82.3532 46.1074 82.2921 46.3275 82.3064 46.5494ZM81.9301 46.5494C81.9181 46.3844 81.9419 46.2188 82 46.0643C82.058 45.9099 82.1488 45.7704 82.2658 45.656C82.4515 45.4988 82.6858 45.4142 82.927 45.4171C83.0607 45.4106 83.1942 45.4332 83.3187 45.4834C83.4432 45.5335 83.5558 45.6101 83.6492 45.708C83.8413 45.9308 83.9399 46.2218 83.9239 46.5182C83.9338 46.7465 83.892 46.974 83.8018 47.1831C83.719 47.3387 83.5958 47.4681 83.4458 47.5571C83.2865 47.6455 83.1082 47.6919 82.927 47.6921C82.7926 47.6966 82.6588 47.672 82.5344 47.6201C82.4099 47.5682 82.2976 47.4901 82.2047 47.3908C82.1063 47.2764 82.0314 47.1429 81.9842 46.9984C81.937 46.8539 81.9186 46.7013 81.9301 46.5494Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M84.3511 47.6405V45.4694H84.6766V45.7707C84.7496 45.6581 84.8496 45.5665 84.9672 45.5046C85.0847 45.4428 85.2158 45.4128 85.348 45.4175C85.4706 45.4133 85.5925 45.4382 85.704 45.4902C85.804 45.5222 85.8901 45.5882 85.9482 45.6772C86.0036 45.7582 86.0417 45.8502 86.0601 45.9473C86.0685 46.0648 86.0685 46.1829 86.0601 46.3005V47.6405H85.6939V46.3212C85.7141 46.2079 85.7141 46.0918 85.6939 45.9784C85.6676 45.9046 85.6173 45.8423 85.5514 45.8018C85.4733 45.7592 85.3857 45.7378 85.2971 45.7395C85.1522 45.7383 85.0115 45.7899 84.9004 45.8849C84.8346 45.9629 84.7853 46.0539 84.7556 46.1522C84.7258 46.2505 84.7163 46.354 84.7275 46.4563V47.6405H84.3511Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M86.8739 46.5492C86.8628 46.774 86.9235 46.9964 87.0468 47.1829C87.0931 47.2473 87.1536 47.2997 87.2234 47.3358C87.2932 47.3719 87.3704 47.3907 87.4486 47.3907C87.5269 47.3907 87.604 47.3719 87.6738 47.3358C87.7436 47.2997 87.8041 47.2473 87.8505 47.1829C87.9784 47.0105 88.04 46.7961 88.0234 46.5804C88.039 46.3486 87.9781 46.1182 87.8505 45.9259C87.807 45.8662 87.7522 45.816 87.6892 45.7785C87.6263 45.741 87.5565 45.717 87.4842 45.7078C87.4066 45.7069 87.3298 45.7253 87.2607 45.7615C87.1916 45.7977 87.1321 45.8506 87.0875 45.9155C86.9642 46.0978 86.9066 46.3181 86.9247 46.5388L86.8739 46.5492ZM88.0132 47.588V47.3699C87.9494 47.4724 87.8604 47.556 87.7551 47.6125C87.6498 47.669 87.5319 47.6964 87.413 47.6919C87.2467 47.6899 87.0844 47.6394 86.9451 47.5465C86.8006 47.4463 86.6875 47.3056 86.6196 47.1413C86.5341 46.9564 86.4923 46.7536 86.4975 46.5492C86.4972 46.3497 86.5352 46.1521 86.6094 45.9675C86.6702 45.8019 86.7806 45.6602 86.9247 45.5623C87.0669 45.4675 87.233 45.417 87.4029 45.4169C87.5229 45.4172 87.6412 45.4456 87.7487 45.5C87.8438 45.555 87.9269 45.6292 87.9929 45.7182V44.6689H88.3489V47.6711L88.0132 47.588Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M88.9189 45.4687H89.275V47.6399H88.9189V45.4687ZM88.9189 44.6377H89.275V45.0636H88.9189V44.6377Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M91.1874 46.5498C90.997 46.6195 90.7988 46.6649 90.5974 46.6849C90.4902 46.6986 90.3846 46.7229 90.2821 46.7576C90.224 46.7845 90.1746 46.8277 90.1396 46.8823C90.125 46.9402 90.125 47.0009 90.1396 47.0589C90.14 47.1046 90.1502 47.1498 90.1695 47.1911C90.1888 47.2324 90.2169 47.2688 90.2515 47.2978C90.2944 47.3362 90.3445 47.3652 90.3987 47.3831C90.4529 47.4009 90.5102 47.4073 90.5669 47.4017C90.6981 47.4041 90.8278 47.3719 90.9433 47.3082C91.0457 47.2484 91.1275 47.1577 91.1773 47.0485C91.2244 46.9336 91.2452 46.8092 91.2383 46.6849V46.5498H91.1874ZM91.1874 47.3705C91.0717 47.4731 90.9413 47.5571 90.8009 47.6198C90.6733 47.6658 90.5394 47.6903 90.4041 47.6925C90.2091 47.7044 90.0168 47.6414 89.865 47.5159C89.8019 47.4612 89.7517 47.3925 89.7183 47.3152C89.6848 47.2378 89.6689 47.1538 89.6717 47.0692C89.6734 46.968 89.6978 46.8685 89.7429 46.7784C89.7876 46.6897 89.8542 46.6145 89.9362 46.5602C90.0129 46.5057 90.0993 46.4669 90.1905 46.4459L90.516 46.3836C90.7335 46.3661 90.9484 46.3243 91.1569 46.259V46.1655C91.1637 46.1075 91.1581 46.0487 91.1405 45.9931C91.123 45.9375 91.0939 45.8865 91.0552 45.8434C90.9385 45.7535 90.7942 45.7092 90.6483 45.7188C90.5163 45.7081 90.3844 45.7409 90.2719 45.8123C90.1767 45.8952 90.1121 46.0088 90.0888 46.1343H89.7327C89.7645 45.9791 89.8306 45.8334 89.926 45.7084C90.0101 45.6082 90.119 45.5328 90.2414 45.4902C90.3989 45.4378 90.564 45.4132 90.7297 45.4175C90.8778 45.4133 91.0257 45.4344 91.1671 45.4798C91.2641 45.5126 91.3516 45.5698 91.4214 45.6461C91.4748 45.7206 91.5128 45.8054 91.5333 45.8954C91.5419 46.0095 91.5419 46.1241 91.5333 46.2382V46.7472C91.518 46.9651 91.518 47.1838 91.5333 47.4017C91.5437 47.4943 91.5751 47.5832 91.6248 47.6614H91.2485C91.2179 47.5678 91.2075 47.4686 91.2179 47.3705H91.1874Z" fill="#1A1A18" />
                <path d="M92.4586 44.6377H92.1025V47.6399H92.4586V44.6377Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M93.2928 46.3418H94.483C94.4786 46.1947 94.4288 46.0529 94.3406 45.9366C94.289 45.8651 94.2216 45.807 94.1437 45.7673C94.0659 45.7276 93.98 45.7073 93.893 45.7081C93.7365 45.7082 93.5865 45.7717 93.4759 45.8847C93.3594 46.003 93.2935 46.1636 93.2928 46.3314V46.3418ZM94.4728 46.9443H94.8492C94.8006 47.1497 94.686 47.3326 94.5237 47.4637C94.3451 47.5912 94.1311 47.6567 93.9133 47.6507C93.776 47.6543 93.6395 47.6294 93.5118 47.5776C93.3842 47.5258 93.2682 47.4482 93.1707 47.3494C93.0739 47.2381 92.9998 47.1082 92.9526 46.9673C92.9054 46.8264 92.8862 46.6773 92.8961 46.5288C92.8845 46.228 92.9864 45.9342 93.1809 45.7081C93.371 45.5177 93.6267 45.4111 93.893 45.4111C94.1592 45.4111 94.4149 45.5177 94.6051 45.7081C94.7928 45.9411 94.8873 46.238 94.8695 46.5391V46.6326H93.2724C93.2703 46.8357 93.3429 47.0322 93.4759 47.1832C93.5735 47.2931 93.7074 47.3624 93.852 47.3777C93.9965 47.3931 94.1415 47.3534 94.2592 47.2663C94.3602 47.1831 94.4346 47.071 94.4728 46.9443Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M96.4463 47.6405V45.4694H96.7718V45.7707C96.8448 45.6581 96.9449 45.5665 97.0624 45.5046C97.1799 45.4428 97.3111 45.4128 97.4432 45.4175C97.5657 45.4144 97.6873 45.4392 97.7992 45.4902C97.8978 45.5249 97.9832 45.5903 98.0434 45.6772C98.0988 45.7582 98.1369 45.8502 98.1553 45.9473C98.1637 46.0648 98.1637 46.1829 98.1553 46.3005V47.6405H97.7891V46.3212C97.8093 46.2079 97.8093 46.0918 97.7891 45.9784C97.7588 45.9039 97.7051 45.8418 97.6365 45.8018C97.5618 45.7596 97.4777 45.7381 97.3923 45.7395C97.2474 45.7383 97.1067 45.7899 96.9956 45.8849C96.9298 45.9629 96.8806 46.0539 96.8508 46.1522C96.821 46.2505 96.8115 46.354 96.8227 46.4563V47.6405H96.4463Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M98.9998 46.3416H100.19C100.186 46.1946 100.136 46.0528 100.048 45.9365C99.995 45.8668 99.927 45.8108 99.8493 45.7729C99.7715 45.735 99.6861 45.7163 99.6 45.7183C99.5225 45.7178 99.4456 45.7331 99.3739 45.7635C99.3023 45.7938 99.2373 45.8385 99.1829 45.8949C99.0664 46.0132 99.0005 46.1738 98.9998 46.3416ZM100.18 46.9441H100.556C100.508 47.1564 100.388 47.3443 100.216 47.4738C100.044 47.6033 99.8332 47.666 99.6203 47.6505C99.4831 47.6541 99.3465 47.6292 99.2189 47.5775C99.0912 47.5257 98.9752 47.4481 98.8777 47.3493C98.7809 47.2379 98.7068 47.108 98.6596 46.9671C98.6125 46.8262 98.5933 46.6772 98.6031 46.5286C98.5937 46.23 98.6913 45.9382 98.8777 45.7079C98.9728 45.6117 99.0855 45.5355 99.2095 45.4838C99.3334 45.4321 99.4661 45.4059 99.6 45.4067C99.7298 45.4026 99.8589 45.4275 99.9784 45.4795C100.098 45.5315 100.205 45.6095 100.292 45.7079C100.487 45.9372 100.586 46.2358 100.566 46.539V46.6325H98.9795C98.9783 46.8335 99.0467 47.0286 99.1728 47.1831C99.2304 47.2451 99.2998 47.2946 99.3768 47.3285C99.4537 47.3624 99.5366 47.3801 99.6203 47.3804C99.7418 47.3828 99.8603 47.3425 99.956 47.2662C100.063 47.187 100.142 47.0737 100.18 46.9441Z" fill="#1A1A18" />
                <path d="M101.36 44.6377H101.004V47.6399H101.36V44.6377Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M104.859 47.287V47.6402H102.916C102.901 47.5543 102.901 47.4663 102.916 47.3805C102.973 47.2369 103.052 47.1036 103.15 46.9857C103.296 46.8187 103.456 46.6657 103.628 46.5287C103.864 46.3413 104.078 46.1288 104.269 45.895C104.372 45.7688 104.433 45.6122 104.442 45.4483C104.444 45.38 104.432 45.312 104.406 45.2492C104.379 45.1864 104.34 45.1302 104.29 45.0847C104.236 45.0318 104.172 44.9907 104.102 44.964C104.032 44.9372 103.957 44.9252 103.883 44.9289C103.806 44.9251 103.729 44.938 103.657 44.9666C103.585 44.9953 103.52 45.039 103.466 45.0951C103.413 45.1536 103.372 45.2224 103.346 45.2974C103.32 45.3723 103.309 45.4519 103.313 45.5314H102.937C102.949 45.2857 103.055 45.0546 103.232 44.8873C103.42 44.7376 103.654 44.6604 103.893 44.6692C104.139 44.6566 104.379 44.7423 104.564 44.9081C104.644 44.9834 104.707 45.0752 104.749 45.1773C104.791 45.2794 104.811 45.3895 104.809 45.5002C104.81 45.6219 104.786 45.7424 104.737 45.8534C104.679 45.9867 104.6 46.1094 104.503 46.217C104.33 46.4077 104.143 46.5848 103.944 46.7468C103.798 46.8671 103.659 46.9954 103.527 47.1312C103.467 47.1896 103.416 47.256 103.374 47.3286L104.859 47.287Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M105.612 46.1543C105.572 46.5104 105.632 46.8706 105.785 47.1932C105.827 47.2681 105.888 47.3305 105.961 47.3742C106.034 47.4179 106.117 47.4414 106.202 47.4425C106.286 47.4404 106.369 47.4165 106.442 47.3729C106.515 47.3293 106.576 47.2674 106.619 47.1932C106.767 46.8691 106.823 46.5093 106.782 46.1543C106.814 45.8198 106.758 45.4825 106.619 45.1779C106.575 45.1021 106.512 45.0393 106.438 44.9956C106.363 44.9519 106.278 44.9288 106.192 44.9285C106.113 44.9279 106.035 44.9475 105.966 44.9856C105.897 45.0238 105.838 45.0792 105.795 45.1467C105.641 45.4689 105.577 45.8286 105.612 46.1855V46.1543ZM105.246 46.1543C105.236 45.8667 105.27 45.5793 105.347 45.3025C105.405 45.1097 105.515 44.9371 105.663 44.8039C105.818 44.6842 106.008 44.6219 106.202 44.6273C106.346 44.6248 106.489 44.6568 106.619 44.7208C106.734 44.7945 106.834 44.8897 106.914 45.0013C106.993 45.1404 107.052 45.291 107.087 45.4479C107.142 45.679 107.166 45.9167 107.158 46.1543C107.163 46.4451 107.129 46.7352 107.056 47.0166C106.993 47.2094 106.881 47.3815 106.731 47.5152C106.58 47.6349 106.393 47.6974 106.202 47.6918C106.074 47.6975 105.946 47.6723 105.83 47.6181C105.713 47.5639 105.61 47.4824 105.531 47.3802C105.308 47.014 105.208 46.5837 105.246 46.1543Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M107.89 46.1544C107.851 46.5104 107.91 46.8706 108.063 47.1932C108.105 47.2689 108.165 47.3319 108.239 47.3757C108.312 47.4196 108.395 47.4427 108.48 47.4427C108.565 47.4427 108.649 47.4196 108.722 47.3757C108.795 47.3319 108.856 47.2689 108.898 47.1932C109.05 46.8706 109.11 46.5104 109.07 46.1544C109.101 45.8189 109.041 45.4812 108.898 45.1779C108.854 45.1036 108.794 45.0418 108.721 44.9981C108.648 44.9545 108.565 44.9306 108.48 44.9286C108.401 44.9258 108.323 44.9446 108.253 44.9829C108.184 45.0213 108.125 45.0778 108.084 45.1467C107.925 45.4675 107.858 45.8276 107.89 46.1855V46.1544ZM107.524 46.1544C107.512 45.8659 107.55 45.5775 107.636 45.3025C107.692 45.1085 107.801 44.9352 107.951 44.8039C108.102 44.6842 108.289 44.6217 108.48 44.6273C108.625 44.6236 108.768 44.6557 108.898 44.7208C109.014 44.7918 109.115 44.8874 109.193 45.0013C109.275 45.1399 109.337 45.2905 109.376 45.448C109.421 45.6804 109.442 45.9173 109.437 46.1544C109.446 46.4454 109.412 46.7362 109.335 47.0166C109.276 47.2087 109.167 47.3809 109.02 47.5152C108.865 47.6349 108.675 47.6972 108.48 47.6918C108.354 47.6984 108.227 47.6735 108.112 47.6192C107.997 47.5649 107.896 47.4829 107.819 47.3802C107.593 47.0153 107.489 46.5849 107.524 46.1544Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M111.706 47.287V47.6402H109.763C109.747 47.5543 109.747 47.4663 109.763 47.3805C109.819 47.2369 109.898 47.1036 109.997 46.9857C110.142 46.8187 110.302 46.6657 110.475 46.5287C110.71 46.3413 110.925 46.1288 111.116 45.895C111.218 45.7688 111.279 45.6122 111.288 45.4483C111.291 45.38 111.278 45.312 111.252 45.2492C111.225 45.1864 111.186 45.1302 111.136 45.0847C111.082 45.0318 111.018 44.9907 110.948 44.964C110.878 44.9372 110.804 44.9252 110.729 44.9289C110.653 44.9246 110.578 44.9372 110.508 44.966C110.437 44.9947 110.374 45.0387 110.322 45.0951C110.266 45.1516 110.222 45.2198 110.194 45.2951C110.166 45.3704 110.154 45.451 110.159 45.5314H109.793C109.795 45.4099 109.822 45.2901 109.871 45.1794C109.919 45.0686 109.99 44.9693 110.078 44.8873C110.266 44.7376 110.501 44.6604 110.739 44.6692C110.985 44.6566 111.226 44.7423 111.411 44.9081C111.49 44.9834 111.553 45.0752 111.595 45.1773C111.637 45.2794 111.658 45.3895 111.655 45.5002C111.657 45.6219 111.632 45.7424 111.583 45.8534C111.525 45.9867 111.446 46.1094 111.35 46.217C111.206 46.4044 111.049 46.5814 110.882 46.7468C110.736 46.8671 110.597 46.9954 110.464 47.1312C110.405 47.1896 110.354 47.256 110.312 47.3286L111.706 47.287Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M74.1786 12.0296L74.4125 11.8945C74.4125 11.9569 74.4125 12.0088 74.4837 12.0711C74.5549 12.1335 74.4837 12.175 74.5651 12.2373L74.6872 12.4867L74.7686 12.6217H74.6872V12.5074L74.5041 12.4659C74.5058 12.4278 74.5058 12.3897 74.5041 12.3516C74.5041 12.3516 74.5041 12.2997 74.5041 12.2789L74.3006 12.3724L74.0972 12.4763L74.2091 12.6944L74.321 12.9022H74.2701C74.2181 12.7514 74.1534 12.6054 74.0768 12.4659H73.9649L73.8429 12.5282L73.6089 12.6529C73.6501 12.7234 73.6875 12.7962 73.7208 12.871L73.853 13.0788H73.8022L73.7411 12.9541L73.6496 12.7464L73.2427 12.7568C73.2427 12.7568 73.2427 12.6736 73.1816 12.6529V12.5802L73.4156 12.4763L73.6903 12.3308L73.9446 12.2062L74.1786 12.0296Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0207 34.6547C13.0044 34.9705 13.1104 35.2802 13.3157 35.5169C13.4104 35.6159 13.5236 35.6945 13.6485 35.7482C13.7734 35.8018 13.9075 35.8295 14.043 35.8295C14.1786 35.8295 14.3127 35.8018 14.4376 35.7482C14.5625 35.6945 14.6756 35.6159 14.7704 35.5169C14.9774 35.2653 15.0796 34.9409 15.0552 34.6132C15.0591 34.3921 15.0175 34.1727 14.9331 33.9691C14.8577 33.7947 14.734 33.6466 14.5771 33.5432C14.4182 33.4426 14.2351 33.3887 14.0481 33.3873C13.7792 33.383 13.5195 33.4876 13.3259 33.6782C13.2058 33.8194 13.1165 33.985 13.0639 34.1641C13.0113 34.3432 12.9966 34.5317 13.0207 34.717V34.6547ZM12.624 34.6547C12.6011 34.2224 12.7433 33.7979 13.0207 33.4705C13.1516 33.3307 13.3098 33.2206 13.485 33.1472C13.6603 33.0739 13.8486 33.0389 14.038 33.0445C14.2987 33.0381 14.556 33.1065 14.7806 33.2419C15.0018 33.372 15.1791 33.5676 15.2892 33.8029C15.407 34.0595 15.4661 34.3401 15.4621 34.6235C15.4667 34.9082 15.4039 35.1897 15.279 35.4442C15.1646 35.6771 14.9837 35.8691 14.7602 35.9948C14.5393 36.1196 14.2905 36.1841 14.038 36.1818C13.7799 36.1855 13.526 36.1156 13.3049 35.9797C13.0838 35.8439 12.9043 35.6476 12.7867 35.413C12.6752 35.173 12.6195 34.9099 12.624 34.6443V34.6547Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9199 36.1198V33.9279H16.276V34.2811C16.3309 34.1611 16.4108 34.0548 16.5099 33.9694C16.5778 33.9193 16.6602 33.8937 16.7439 33.8967C16.8768 33.902 17.0062 33.9413 17.1203 34.011L16.988 34.3642C16.9091 34.3121 16.8175 34.2833 16.7236 34.2811C16.646 34.2777 16.57 34.3035 16.5099 34.3538C16.4434 34.4051 16.3966 34.4786 16.3777 34.5616C16.3353 34.6993 16.3147 34.8431 16.3167 34.9875V36.1406L15.9199 36.1198Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5374 34.9876C17.5146 35.2103 17.5765 35.4335 17.7103 35.6109C17.7593 35.6762 17.8225 35.729 17.8949 35.7651C17.9673 35.8011 18.0469 35.8195 18.1274 35.8187C18.2067 35.8178 18.2848 35.7998 18.3567 35.7657C18.4286 35.7317 18.4926 35.6825 18.5445 35.6213C18.6765 35.443 18.7382 35.2205 18.7174 34.998C18.7319 34.7793 18.6707 34.5624 18.5445 34.3851C18.492 34.322 18.4264 34.2716 18.3525 34.2375C18.2787 34.2034 18.1983 34.1864 18.1172 34.1877C18.0391 34.1865 17.9618 34.2037 17.8912 34.2379C17.8207 34.2721 17.7588 34.3225 17.7103 34.3851C17.584 34.5584 17.5227 34.7721 17.5374 34.9876ZM17.2322 36.3589H17.5883C17.5985 36.4512 17.642 36.5364 17.7103 36.5978C17.8207 36.675 17.9534 36.7116 18.0867 36.7017C18.2233 36.7115 18.3592 36.675 18.4733 36.5978C18.5653 36.5277 18.633 36.4294 18.6666 36.3173C18.6757 36.1547 18.6757 35.9917 18.6666 35.8291C18.5971 35.921 18.5076 35.9951 18.4052 36.0456C18.3028 36.0961 18.1903 36.1216 18.0766 36.1199C17.9444 36.1269 17.8126 36.1013 17.6921 36.0453C17.5717 35.9892 17.4663 35.9044 17.3848 35.7979C17.2185 35.5704 17.1324 35.2922 17.1407 35.0084C17.1404 34.8089 17.1784 34.6112 17.2526 34.4267C17.3173 34.2604 17.4311 34.1188 17.5781 34.0215C17.7266 33.9239 17.9 33.8733 18.0766 33.8761C18.198 33.8753 18.3181 33.9029 18.4274 33.957C18.5368 34.011 18.6325 34.0899 18.7073 34.1877V33.928H19.043V35.8291C19.0534 36.0758 19.0189 36.3224 18.9412 36.5562C18.8654 36.696 18.7526 36.8112 18.6157 36.8887C18.4455 36.976 18.2568 37.0189 18.0664 37.0133C17.8463 37.0247 17.6285 36.9627 17.4459 36.8367C17.3766 36.7792 17.3214 36.7062 17.2843 36.6233C17.2473 36.5404 17.2294 36.4499 17.2322 36.3589Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.9859 35.0184C20.7911 35.0875 20.59 35.1362 20.3857 35.1638C20.2779 35.1737 20.1718 35.1982 20.0703 35.2366C20.0112 35.2564 19.9607 35.2968 19.9279 35.3508C19.8972 35.4046 19.8798 35.4652 19.8771 35.5274C19.8742 35.5752 19.8829 35.6229 19.9024 35.6664C19.9219 35.7099 19.9517 35.7478 19.989 35.7768C20.0318 35.8152 20.0819 35.8442 20.1361 35.862C20.1903 35.8799 20.2476 35.8862 20.3043 35.8806C20.4357 35.8845 20.5657 35.8522 20.6807 35.7871C20.7883 35.731 20.8743 35.6395 20.9248 35.5274C20.9718 35.4089 20.9926 35.2812 20.9859 35.1535V35.0184ZM20.9859 35.8495C20.8683 35.9547 20.7341 36.039 20.5891 36.0988C20.463 36.1498 20.3281 36.1745 20.1924 36.1715C19.9945 36.182 19.7994 36.1193 19.6431 35.9949C19.5822 35.9367 19.5342 35.8657 19.5026 35.7868C19.471 35.7079 19.4565 35.623 19.46 35.5378C19.4579 35.4361 19.4825 35.3357 19.5312 35.247C19.5751 35.1609 19.6377 35.0862 19.7143 35.0288C19.7947 34.9742 19.8844 34.9354 19.9788 34.9145L20.3043 34.8522C20.5252 34.8356 20.7436 34.7938 20.9554 34.7275V34.6341C20.9621 34.5761 20.9565 34.5173 20.939 34.4617C20.9214 34.4061 20.8923 34.355 20.8536 34.312C20.7339 34.2205 20.5857 34.1762 20.4366 34.1874C20.306 34.1792 20.1758 34.208 20.0602 34.2705C19.9655 34.3581 19.9012 34.4748 19.8771 34.6029L19.521 34.5509C19.5417 34.4137 19.5979 34.2846 19.6838 34.177C19.7624 34.0684 19.8729 33.9884 19.9991 33.9484C20.1566 33.896 20.3218 33.8714 20.4874 33.8757C20.639 33.8707 20.7903 33.8918 20.935 33.938C21.0302 33.9748 21.1168 34.0314 21.1893 34.1043C21.2445 34.1816 21.2827 34.2702 21.3012 34.364C21.3098 34.4781 21.3098 34.5927 21.3012 34.7068V35.2054C21.3012 35.5482 21.3012 35.7664 21.3012 35.8599C21.321 35.95 21.3517 36.0373 21.3928 36.1196H21.0164C20.9989 36.0304 20.9989 35.9386 21.0164 35.8495H20.9859Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.9219 36.1199V33.928H22.2474V34.2812C22.3201 34.1651 22.4212 34.0705 22.5408 34.0067C22.6603 33.9429 22.7942 33.9122 22.929 33.9176C23.0516 33.9134 23.1734 33.9383 23.285 33.9903C23.3849 34.0223 23.4711 34.0883 23.5291 34.1773C23.5879 34.2576 23.6295 34.3496 23.6512 34.4474C23.6592 34.5685 23.6592 34.6899 23.6512 34.811V36.1614H23.285V34.7902C23.3002 34.6765 23.3002 34.5611 23.285 34.4474C23.2521 34.3718 23.1992 34.3071 23.1324 34.2604C23.0542 34.2178 22.9667 34.1963 22.8781 34.1981C22.7278 34.1942 22.5819 34.2501 22.4712 34.3539C22.4049 34.4315 22.3553 34.5225 22.3255 34.6209C22.2957 34.7193 22.2864 34.823 22.2983 34.9252V36.1199H21.9219Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M24.231 33.9274H24.5972V36.1193H24.231V33.9274ZM24.231 33.0859H24.5972V33.5119H24.231V33.0859Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M24.9536 36.1193V35.818L26.3269 34.2182H25.035V33.917H26.7949V34.1559L25.625 35.5583L25.4012 35.8076H25.8691H26.8864V36.1297L24.9536 36.1193Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M27.0288 36.1193V35.818L28.4021 34.2182H27.1102V33.917H28.87V34.1559L27.7002 35.5583L27.4764 35.8076H27.9443H28.9616V36.1297L27.0288 36.1193Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M30.6705 35.0184C30.476 35.0886 30.2748 35.1374 30.0703 35.1638C29.9625 35.1737 29.8564 35.1982 29.7549 35.2366C29.6958 35.2564 29.6453 35.2968 29.6125 35.3508C29.5818 35.4046 29.5643 35.4652 29.5616 35.5274C29.5604 35.575 29.5699 35.6221 29.5893 35.6653C29.6087 35.7086 29.6375 35.7467 29.6735 35.7768C29.7644 35.8539 29.8814 35.8912 29.9991 35.8806C30.1303 35.8831 30.26 35.8509 30.3755 35.7871C30.4779 35.7274 30.5596 35.6367 30.6094 35.5274C30.6564 35.4089 30.6772 35.2812 30.6705 35.1535V35.0184ZM30.6705 35.8495C30.5547 35.9521 30.4244 36.0361 30.2839 36.0988C30.1539 36.1488 30.0159 36.1735 29.877 36.1715C29.682 36.1833 29.4897 36.1204 29.3378 35.9949C29.2739 35.9386 29.2234 35.8682 29.1899 35.789C29.1564 35.7098 29.1409 35.624 29.1446 35.5378C29.1425 35.4361 29.1671 35.3357 29.2158 35.247C29.2628 35.1631 29.325 35.0891 29.3989 35.0288C29.4802 34.9759 29.5696 34.9373 29.6634 34.9145L29.9889 34.8522C30.2098 34.8356 30.4282 34.7938 30.6399 34.7275V34.6341C30.6467 34.5761 30.6411 34.5173 30.6236 34.4617C30.606 34.4061 30.5769 34.355 30.5382 34.312C30.4184 34.2205 30.2703 34.1762 30.1211 34.1874C29.9906 34.1792 29.8604 34.208 29.7447 34.2705C29.6501 34.3581 29.5858 34.4748 29.5616 34.6029L29.2056 34.5509C29.2263 34.4137 29.2824 34.2846 29.3684 34.177C29.4488 34.0704 29.5586 33.9908 29.6837 33.9484C29.8412 33.896 30.0064 33.8714 30.172 33.8757C30.3236 33.8707 30.4749 33.8918 30.6196 33.938C30.7161 33.972 30.8033 34.029 30.8739 34.1043C30.9324 34.1809 30.9741 34.2694 30.996 34.364C31.003 34.4781 31.003 34.5926 30.996 34.7068V35.2054C30.9787 35.4232 30.9787 35.6421 30.996 35.8599C31.0064 35.9525 31.0377 36.0414 31.0875 36.1196H30.701C30.688 36.03 30.688 35.939 30.701 35.8495H30.6705Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M31.4131 36.1193V35.818L32.7864 34.2182H31.5352V33.917H33.295V34.1559L32.1252 35.5583L31.9014 35.8076H32.3591H33.3764V36.1297L31.4131 36.1193Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M33.6816 33.9274H34.0479V36.1193H33.6816V33.9274ZM33.6816 33.0859H34.0479V33.5119H33.6816V33.0859Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M34.8412 35.019C34.8218 35.2463 34.8872 35.4726 35.0243 35.6527C35.0775 35.7211 35.1458 35.7756 35.2236 35.8117C35.3014 35.8478 35.3865 35.8645 35.4719 35.8605C35.5587 35.8637 35.645 35.8467 35.7244 35.8107C35.8037 35.7747 35.8739 35.7207 35.9297 35.6527C36.063 35.4671 36.1246 35.2377 36.1026 35.0086C36.1218 34.7895 36.0601 34.5709 35.9297 34.3957C35.8739 34.3278 35.8037 34.2737 35.7244 34.2377C35.645 34.2017 35.5587 34.1847 35.4719 34.188C35.3865 34.1839 35.3014 34.2006 35.2236 34.2367C35.1458 34.2728 35.0775 34.3273 35.0243 34.3957C34.8887 34.5726 34.8234 34.7951 34.8412 35.019ZM34.4648 35.019C34.452 34.8524 34.4755 34.685 34.5335 34.5287C34.5915 34.3725 34.6827 34.2313 34.8005 34.1152C34.9889 33.9557 35.2273 33.8709 35.4719 33.8763C35.6068 33.8728 35.741 33.8967 35.8667 33.9466C35.9925 33.9965 36.1073 34.0715 36.2043 34.1672C36.3951 34.3949 36.4934 34.6885 36.479 34.9879C36.4898 35.2195 36.448 35.4506 36.3569 35.6631C36.2731 35.8215 36.1459 35.9515 35.9907 36.0371C35.8327 36.1286 35.6536 36.1752 35.4719 36.1721C35.3359 36.178 35.2001 36.1542 35.0738 36.1023C34.9475 36.0503 34.8335 35.9714 34.7395 35.8708C34.5412 35.6352 34.4424 35.3289 34.4648 35.019Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M36.9062 36.1201V33.9282H37.2419V34.2814C37.3139 34.1646 37.4148 34.0694 37.5346 34.0055C37.6543 33.9417 37.7886 33.9114 37.9235 33.9178C38.046 33.9147 38.1676 33.9396 38.2796 33.9906C38.3795 34.0225 38.4657 34.0885 38.5237 34.1775C38.5791 34.2586 38.6172 34.3506 38.6356 34.4476C38.6436 34.5687 38.6436 34.6902 38.6356 34.8112V36.1617H38.2694V34.7905C38.2896 34.6771 38.2896 34.561 38.2694 34.4476C38.2364 34.3721 38.1836 34.3074 38.1168 34.2607C38.039 34.2171 37.9512 34.1956 37.8625 34.1983C37.7155 34.196 37.5734 34.2519 37.4657 34.3541C37.3995 34.4318 37.3498 34.5227 37.32 34.6211C37.2902 34.7195 37.281 34.8232 37.2928 34.9255V36.1201H36.9062Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M39.4899 34.8106H40.6903C40.689 34.6616 40.6428 34.5165 40.558 34.3951C40.5038 34.3246 40.4342 34.2682 40.3546 34.2303C40.2751 34.1924 40.188 34.1741 40.1003 34.177C40.0227 34.1764 39.9458 34.1918 39.8742 34.2221C39.8026 34.2524 39.7376 34.2971 39.6832 34.3536C39.565 34.4758 39.496 34.6389 39.4899 34.8106ZM40.6903 35.4131H41.0667C41.0148 35.6253 40.8917 35.8121 40.7188 35.9412C40.546 36.0702 40.3343 36.1334 40.1206 36.1195C39.9816 36.1245 39.8431 36.1004 39.7137 36.0486C39.5842 35.9968 39.4665 35.9184 39.3678 35.8183C39.1727 35.589 39.074 35.2904 39.0932 34.9872C39.0741 34.6729 39.1764 34.3634 39.378 34.125C39.4709 34.0258 39.5832 33.9476 39.7077 33.8957C39.8321 33.8438 39.9659 33.8193 40.1003 33.8238C40.2315 33.8203 40.3619 33.8453 40.483 33.8973C40.604 33.9492 40.7128 34.0268 40.8022 34.125C40.998 34.358 41.0966 34.6601 41.0768 34.9665V35.0703H39.4696C39.4721 35.2725 39.5441 35.4674 39.673 35.6209C39.7308 35.6821 39.8005 35.7303 39.8776 35.7625C39.9546 35.7947 40.0374 35.8101 40.1206 35.8079C40.244 35.8132 40.3656 35.7767 40.4665 35.704C40.5626 35.6265 40.6393 35.5268 40.6903 35.4131Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.17162 40.109C7.16052 40.3337 7.22121 40.5561 7.34455 40.7426C7.39297 40.806 7.45462 40.8575 7.52502 40.8935C7.59541 40.9294 7.67277 40.9489 7.75146 40.9504C7.82957 40.9516 7.90691 40.9345 7.97747 40.9002C8.04804 40.866 8.10993 40.8156 8.15836 40.753C8.28458 40.5757 8.34578 40.3588 8.33129 40.1401C8.34335 39.9024 8.27512 39.6677 8.13801 39.4753C8.08803 39.4111 8.02464 39.3592 7.95249 39.3232C7.88033 39.2873 7.80122 39.2683 7.72094 39.2675C7.64211 39.2683 7.5645 39.2874 7.494 39.3234C7.42349 39.3594 7.36195 39.4114 7.31403 39.4753C7.18917 39.6607 7.1316 39.8848 7.15127 40.109H7.17162ZM8.32112 41.2101V40.9296C8.25886 41.0336 8.17019 41.1185 8.06452 41.1752C7.95886 41.2319 7.84013 41.2583 7.72094 41.2517C7.55529 41.2551 7.39238 41.2081 7.253 41.1166C7.10473 41.018 6.98803 40.8771 6.9173 40.7115C6.83311 40.5224 6.7914 40.3166 6.79523 40.109C6.78831 39.892 6.82645 39.6761 6.90713 39.4753C6.96479 39.3052 7.07559 39.1592 7.22248 39.0598C7.36465 38.9649 7.53078 38.9144 7.70059 38.9143C7.82086 38.9122 7.93975 38.9407 8.04646 38.9974C8.14395 39.0531 8.23025 39.1271 8.30078 39.2156V38.1768H8.65682V41.2101H8.32112Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.51152 39.9015H10.7119C10.7097 39.7509 10.6597 39.6051 10.5695 39.4859C10.5222 39.423 10.4624 39.371 10.394 39.3334C10.3256 39.2958 10.2502 39.2734 10.1727 39.2678C10.0958 39.2653 10.0192 39.2787 9.94754 39.3073C9.87586 39.3359 9.81059 39.379 9.75566 39.434C9.69413 39.4945 9.64527 39.5671 9.61204 39.6475C9.57882 39.7278 9.56192 39.8143 9.56238 39.9015H9.51152ZM10.7119 40.504H11.0883C11.0391 40.7146 10.9206 40.9017 10.7526 41.0338C10.5728 41.1576 10.3589 41.2195 10.1422 41.2104C10.004 41.2167 9.8659 41.1942 9.73647 41.1442C9.60703 41.0942 9.48895 41.0177 9.38945 40.9195C9.19524 40.6897 9.09667 40.3915 9.11479 40.0885C9.09506 39.7758 9.19335 39.4672 9.38945 39.2262C9.48257 39.1238 9.59616 39.0429 9.72251 38.9892C9.84887 38.9354 9.98506 38.91 10.1219 38.9146C10.2531 38.9111 10.3835 38.9362 10.5046 38.9881C10.6256 39.0401 10.7344 39.1177 10.8238 39.2159C11.0174 39.4542 11.1155 39.7585 11.0984 40.0677V40.1612H9.49118C9.48903 40.3643 9.56164 40.5607 9.69463 40.7118C9.74981 40.777 9.81875 40.8287 9.89626 40.8628C9.97378 40.897 10.0579 40.9129 10.1422 40.9091C10.2624 40.9145 10.3808 40.8779 10.4779 40.8052C10.5796 40.7309 10.6602 40.6306 10.7119 40.5144V40.504Z" fill="#1A1A18" />
                <path d="M11.9019 38.1768H11.5356V41.2101H11.9019V38.1768Z" fill="#1A1A18" />
                <path d="M12.8174 38.1768H12.4512V41.2101H12.8174V38.1768Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6616 39.9006H14.862C14.8627 39.7512 14.8163 39.6055 14.7297 39.4851C14.6713 39.4115 14.5965 39.3533 14.5116 39.3153C14.4266 39.2774 14.334 39.2608 14.2414 39.267C14.1645 39.2645 14.0879 39.2779 14.0162 39.3065C13.9445 39.335 13.8793 39.3781 13.8243 39.4332C13.7628 39.4937 13.714 39.5663 13.6807 39.6466C13.6475 39.727 13.6306 39.8134 13.6311 39.9006H13.6616ZM14.862 40.5032L15.2587 40.5135C15.2095 40.7242 15.091 40.9112 14.923 41.0433C14.7432 41.1671 14.5293 41.229 14.3126 41.2199C14.1744 41.2263 14.0363 41.2038 13.9069 41.1538C13.7774 41.1037 13.6594 41.0273 13.5599 40.9291C13.3656 40.6992 13.2671 40.401 13.2852 40.098C13.2655 39.7853 13.3638 39.4768 13.5599 39.2358C13.6553 39.1364 13.7693 39.0576 13.8951 39.004C14.0209 38.9505 14.156 38.9233 14.2923 38.9241C14.4235 38.9207 14.554 38.9457 14.675 38.9977C14.796 39.0496 14.9048 39.1272 14.9942 39.2254C15.1878 39.4637 15.286 39.7681 15.2689 40.0772V40.1707H13.6412C13.6391 40.3738 13.7117 40.5703 13.8447 40.7213C13.9003 40.786 13.9694 40.8372 14.0468 40.8714C14.1242 40.9055 14.208 40.9217 14.2923 40.9187C14.4155 40.9225 14.5366 40.8861 14.6382 40.8148C14.7355 40.7344 14.8124 40.631 14.862 40.5135V40.5032Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8867 41.2101V38.1768H17.2936L18.85 40.5557V38.1768H19.2264V41.2101H18.8297L17.2631 38.8208V41.2101H16.8867Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.2202 40.1093C21.0249 40.1738 20.8239 40.2191 20.62 40.2443C20.5129 40.258 20.4072 40.2824 20.3047 40.317C20.2466 40.3439 20.1972 40.3872 20.1623 40.4417C20.1256 40.4927 20.1076 40.5552 20.1114 40.6183C20.1102 40.6658 20.1197 40.713 20.1391 40.7562C20.1585 40.7994 20.1873 40.8375 20.2233 40.8676C20.3139 40.9367 20.4259 40.9699 20.5387 40.9611C20.6699 40.9635 20.7996 40.9313 20.915 40.8676C21.0226 40.8114 21.1086 40.72 21.1592 40.6079C21.2053 40.4927 21.2261 40.3686 21.2202 40.2443V40.1093ZM21.2202 40.9403C21.1035 41.0442 20.969 41.1252 20.8235 41.1793C20.6973 41.2303 20.5624 41.255 20.4268 41.252C20.2282 41.2676 20.0314 41.2043 19.8774 41.0754C19.8155 41.0197 19.7661 40.9509 19.7327 40.8738C19.6993 40.7966 19.6828 40.713 19.6842 40.6287C19.6847 40.526 19.7128 40.4253 19.7655 40.3378C19.8032 40.2476 19.8671 40.1715 19.9487 40.1197C20.0305 40.0654 20.1196 40.0234 20.2131 39.995C20.3214 39.9842 20.4304 39.9842 20.5387 39.995C20.7593 39.9709 20.9773 39.9257 21.1897 39.86V39.7665C21.1946 39.7086 21.1881 39.6503 21.1707 39.595C21.1532 39.5397 21.1251 39.4885 21.088 39.4444C20.9674 39.3733 20.8305 39.3359 20.6913 39.3359C20.552 39.3359 20.4151 39.3733 20.2945 39.4444C20.1994 39.5274 20.1348 39.641 20.1114 39.7665L19.7554 39.7145C19.7792 39.5801 19.8312 39.4525 19.908 39.3406C19.9962 39.2403 20.1083 39.1651 20.2335 39.1224C20.3904 39.0662 20.5555 39.0381 20.7218 39.0393C20.8743 39.0321 21.0266 39.0569 21.1694 39.112C21.2681 39.141 21.3565 39.1988 21.4237 39.2782C21.481 39.3504 21.5195 39.4361 21.5356 39.5275C21.5441 39.6416 21.5441 39.7563 21.5356 39.8704V40.369C21.5356 40.7118 21.5356 40.93 21.5356 41.0234C21.5553 41.1169 21.586 41.2076 21.6271 41.2935H21.3626C21.2965 41.1875 21.2579 41.0659 21.2507 40.9403H21.2202Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.9629 41.2098V40.9085L23.326 39.2983H22.0443V38.9971H23.794V39.2464L22.6343 40.6384L22.4105 40.8981C22.5629 40.8882 22.7158 40.8882 22.8683 40.8981H23.8855V41.2201L21.9629 41.2098Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M24.231 39.0078H24.5972V41.2101H24.231V39.0078ZM24.231 38.1768H24.5972V38.6027H24.231V38.1768Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M25.4314 40.1087C25.4129 40.3342 25.4742 40.5591 25.6043 40.7424C25.6615 40.8086 25.732 40.8615 25.811 40.8973C25.89 40.9332 25.9756 40.9512 26.0621 40.9501C26.1472 40.9518 26.2315 40.934 26.309 40.8981C26.3864 40.8621 26.455 40.8089 26.5097 40.7424C26.6433 40.5565 26.7081 40.3283 26.6928 40.0983C26.6898 39.8668 26.6028 39.6447 26.4486 39.475C26.3935 39.409 26.3248 39.3562 26.2475 39.3203C26.1701 39.2844 26.086 39.2663 26.0011 39.2672C25.9146 39.2662 25.8289 39.2842 25.7499 39.32C25.6709 39.3559 25.6004 39.4087 25.5433 39.475C25.4132 39.6583 25.3518 39.8831 25.3704 40.1087H25.4314ZM25.055 40.1087C25.0429 39.943 25.0658 39.7767 25.1219 39.6208C25.1781 39.4649 25.2662 39.3231 25.3805 39.2049C25.5701 39.0393 25.8127 38.9505 26.0621 38.9556C26.1965 38.9511 26.3302 38.9757 26.4547 39.0276C26.5791 39.0795 26.6915 39.1576 26.7843 39.2569C26.884 39.3667 26.9606 39.4961 27.0096 39.6372C27.0585 39.7783 27.0788 39.9281 27.0692 40.0775C27.0802 40.3059 27.0384 40.5336 26.9471 40.7424C26.8665 40.9058 26.7386 41.0399 26.5809 41.1267C26.4199 41.2092 26.2422 41.2519 26.0621 41.2514C25.9268 41.2587 25.7915 41.2365 25.6653 41.1863C25.539 41.1362 25.4246 41.0592 25.3297 40.9605C25.2247 40.8468 25.1427 40.713 25.0886 40.5668C25.0345 40.4206 25.0092 40.2649 25.0143 40.1087H25.055Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M27.4658 41.2099V39.0076H27.7913V39.3192C27.8664 39.2031 27.9694 39.1086 28.0906 39.0449C28.2117 38.9812 28.3469 38.9505 28.4831 38.9556C28.6051 38.9579 28.7257 38.9825 28.8391 39.0284C28.9319 39.0735 29.0149 39.1371 29.0833 39.2153C29.1369 39.3012 29.1748 39.3962 29.1952 39.4958C29.2039 39.6169 29.2039 39.7384 29.1952 39.8594V41.2099H28.8289V39.8698C28.8492 39.7565 28.8492 39.6403 28.8289 39.527C28.7987 39.4525 28.745 39.3903 28.6764 39.3504C28.5995 39.3032 28.5117 39.2781 28.422 39.2777C28.2721 39.2759 28.127 39.3315 28.0151 39.4335C27.9512 39.5123 27.9037 39.6036 27.8757 39.7019C27.8477 39.8002 27.8398 39.9033 27.8524 40.0048V41.2099H27.4658Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M29.7651 39.0078H30.1314V41.2101H29.7651V39.0078ZM29.7651 38.1768H30.1314V38.6027H29.7651V38.1768Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M33.8342 38.1768H34.2309V39.922C34.2371 40.1685 34.2027 40.4143 34.1292 40.6491C34.0603 40.8335 33.9308 40.9877 33.763 41.0855C33.55 41.2108 33.3066 41.272 33.061 41.2621C32.8259 41.2686 32.5924 41.2188 32.3795 41.1166C32.2143 41.0174 32.0829 40.8687 32.0031 40.6907C31.921 40.4434 31.8831 40.183 31.8912 39.922V38.1768H32.2879V39.922C32.2766 40.1187 32.3007 40.3159 32.3591 40.5037C32.3785 40.5665 32.4101 40.6246 32.4521 40.6746C32.4941 40.7246 32.5455 40.7654 32.6033 40.7946C32.7331 40.8701 32.8813 40.9062 33.0305 40.8985C33.1424 40.9088 33.2551 40.8958 33.3618 40.86C33.4685 40.8243 33.5669 40.7667 33.6511 40.6907C33.8006 40.4653 33.8656 40.1924 33.8342 39.922V38.1768Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M34.8311 41.2099V39.0076H35.1667V39.3193C35.2394 39.2032 35.3406 39.1086 35.4601 39.0448C35.5797 38.981 35.7135 38.9503 35.8483 38.9557C35.9703 38.9579 36.0909 38.9826 36.2044 39.0284C36.3004 39.0678 36.3846 39.1323 36.4485 39.2154C36.5021 39.3012 36.54 39.3962 36.5604 39.4959C36.5745 39.6166 36.5745 39.7387 36.5604 39.8595V41.2099H36.2145V39.8698C36.2348 39.7565 36.2348 39.6404 36.2145 39.527C36.1843 39.4525 36.1306 39.3904 36.0619 39.3504C35.9851 39.3033 35.8973 39.2782 35.8076 39.2777C35.6606 39.2754 35.5185 39.3313 35.4109 39.4335C35.3451 39.5115 35.2958 39.6025 35.2661 39.7008C35.2363 39.7991 35.2267 39.9026 35.238 40.0049V41.2099H34.8311Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M37.1401 39.0078H37.5064V41.2101H37.1401V39.0078ZM37.1401 38.1768H37.5064V38.6027H37.1401V38.1768Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M38.8593 40.8778V41.1998H38.5846C38.4794 41.2058 38.3744 41.1844 38.2795 41.1375C38.2115 41.1015 38.1577 41.0428 38.1269 40.9713C38.0852 40.83 38.068 40.6823 38.076 40.535V39.2988H37.8115V39.0079H38.076V38.4365L38.4422 38.208V38.9767H38.8084V39.2676H38.4422V40.5557C38.4321 40.6211 38.4321 40.6877 38.4422 40.7531C38.4466 40.769 38.4544 40.7838 38.4649 40.7963C38.4755 40.8089 38.4885 40.8189 38.5033 40.8258C38.5431 40.838 38.5855 40.838 38.6253 40.8258L38.8593 40.8778Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M39.4898 39.9013H40.6902C40.6909 39.7518 40.6446 39.6061 40.5579 39.4857C40.5016 39.4238 40.4335 39.3741 40.3578 39.3399C40.2821 39.3056 40.2004 39.2875 40.1176 39.2865C40.0348 39.2855 39.9527 39.3018 39.8763 39.3342C39.7998 39.3667 39.7306 39.4147 39.6729 39.4753C39.5532 39.6008 39.4842 39.7677 39.4796 39.9428L39.4898 39.9013ZM40.6902 40.5142H41.0666C41.0174 40.7248 40.8989 40.9118 40.7309 41.044C40.5216 41.1765 40.2763 41.2369 40.0309 41.2164C39.7855 41.1958 39.553 41.0953 39.3678 40.9297C39.1735 40.6999 39.075 40.4016 39.0931 40.0986C39.0826 39.9428 39.1023 39.7864 39.1512 39.6385C39.2001 39.4905 39.2771 39.3538 39.3779 39.2364C39.5681 39.0461 39.8237 38.9395 40.09 38.9395C40.3563 38.9395 40.6119 39.0461 40.8021 39.2364C40.9957 39.4747 41.0938 39.7791 41.0767 40.0882V40.1817H39.4695C39.472 40.3839 39.544 40.5788 39.6729 40.7323C39.7286 40.797 39.7976 40.8482 39.875 40.8824C39.9524 40.9165 40.0363 40.9327 40.1205 40.9297C40.2439 40.935 40.3655 40.8984 40.4664 40.8258C40.5651 40.7425 40.642 40.6354 40.6902 40.5142Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0199 45.2099C10.0038 45.4319 10.0651 45.6527 10.1928 45.8331C10.2412 45.8957 10.3031 45.9461 10.3737 45.9803C10.4442 46.0146 10.5216 46.0317 10.5997 46.0305C10.6802 46.0313 10.7599 46.013 10.8323 45.9769C10.9046 45.9409 10.9678 45.8881 11.0168 45.8228C11.1423 45.6334 11.2031 45.407 11.1897 45.1787C11.2056 44.9534 11.1445 44.7295 11.0168 44.545C10.9689 44.4811 10.9073 44.4292 10.8368 44.3932C10.7663 44.3572 10.6887 44.338 10.6099 44.3372C10.5303 44.34 10.4525 44.3611 10.382 44.3988C10.3116 44.4366 10.2504 44.4901 10.203 44.5554C10.0717 44.7464 10.0072 44.9769 10.0199 45.2099ZM9.69434 47.1317V44.0983H10.0199V44.3788C10.0905 44.2744 10.1847 44.1888 10.2945 44.1295C10.4044 44.0715 10.5269 44.0429 10.6506 44.0464C10.8244 44.0403 10.9955 44.0913 11.1388 44.1918C11.2804 44.2926 11.39 44.4334 11.4542 44.597C11.5249 44.7823 11.5594 44.9799 11.5559 45.1787C11.5623 45.3855 11.5241 45.5912 11.444 45.7812C11.3752 45.9539 11.254 46.0995 11.0982 46.1967C10.9553 46.29 10.7896 46.3404 10.62 46.3422C10.5066 46.3417 10.3949 46.3132 10.2945 46.2591C10.2037 46.2109 10.124 46.1437 10.0606 46.0617V47.1005L9.69434 47.1317Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2784 44.9806H13.4788C13.4745 44.8304 13.4247 44.6853 13.3364 44.5651C13.2837 44.4955 13.2158 44.4394 13.138 44.4015C13.0603 44.3636 12.9749 44.3449 12.8888 44.347C12.8097 44.3462 12.7312 44.3614 12.6579 44.3917C12.5846 44.422 12.5178 44.4668 12.4615 44.5236C12.3461 44.6468 12.2807 44.8101 12.2784 44.9806ZM13.4686 45.5832H13.8552C13.8031 45.7897 13.685 45.9725 13.5195 46.1026C13.3228 46.2363 13.0894 46.3024 12.8533 46.2912C12.6173 46.28 12.3909 46.1921 12.2072 46.0402C12.012 45.811 11.9133 45.5123 11.9325 45.2092C11.9149 44.8968 12.0129 44.589 12.2072 44.347C12.3035 44.2494 12.4178 44.1725 12.5436 44.1208C12.6694 44.0691 12.804 44.0435 12.9396 44.0457C13.0711 44.0401 13.2022 44.0643 13.3235 44.1163C13.4448 44.1684 13.5534 44.2471 13.6415 44.347C13.8373 44.58 13.9359 44.882 13.9162 45.1884V45.2923H12.2581C12.2568 45.4934 12.3253 45.6884 12.4513 45.8429C12.5111 45.9043 12.5825 45.9526 12.6613 45.9848C12.74 46.0169 12.8243 46.0323 12.9091 46.0298C13.0293 46.0352 13.1477 45.9986 13.2448 45.926C13.3541 45.8398 13.4328 45.7194 13.4686 45.5832Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3027 46.2898V44.0979H14.6283V44.4303C14.6861 44.3122 14.7655 44.2064 14.8622 44.1187C14.9308 44.0699 15.0127 44.0444 15.0962 44.046C15.2293 44.0499 15.359 44.0893 15.4726 44.1602L15.3505 44.5134C15.2694 44.4577 15.1736 44.4287 15.0759 44.4303C14.9988 44.4303 14.9239 44.4558 14.8622 44.503C14.799 44.5574 14.7529 44.6297 14.73 44.7108C14.6876 44.8486 14.667 44.9923 14.6689 45.1367V46.2898H14.3027Z" fill="#1A1A18" />
                <path d="M17.2017 43.2568H16.8354V46.2902H17.2017V43.2568Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.741 43.6306V43.1943H18.1377V43.5371C18.149 43.6762 18.1244 43.8159 18.0665 43.9423C18.007 44.0524 17.9142 44.1399 17.802 44.1916L17.7104 44.0462C17.7778 44.0155 17.8345 43.9648 17.8732 43.9007C17.9125 43.8163 17.9333 43.7241 17.9342 43.6306H17.741Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7378 46.2902V43.2568H20.8842V43.6204H19.1345V44.545H20.7825V44.8982H19.1345V45.937H20.9554V46.2902H18.7378Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.6983 45.1992C21.6823 45.4213 21.7435 45.6421 21.8712 45.8225C21.9198 45.8852 21.9817 45.9359 22.0521 45.9707C22.1226 46.0056 22.1999 46.0237 22.2782 46.0237C22.3564 46.0237 22.4337 46.0056 22.5042 45.9707C22.5747 45.9359 22.6365 45.8852 22.6851 45.8225C22.8114 45.6493 22.8727 45.4355 22.858 45.22C22.876 44.9848 22.815 44.7503 22.6851 44.5552C22.6355 44.4923 22.5727 44.4416 22.5014 44.4067C22.4301 44.3719 22.3521 44.3538 22.2731 44.3538C22.194 44.3538 22.116 44.3719 22.0447 44.4067C21.9734 44.4416 21.9107 44.4923 21.8611 44.5552C21.7392 44.7458 21.682 44.9721 21.6983 45.1992ZM22.8478 46.29V46.0095C22.786 46.1147 22.6978 46.201 22.5922 46.2595C22.4866 46.3179 22.3676 46.3464 22.2476 46.3419C22.081 46.3417 21.9182 46.2911 21.7797 46.1965C21.6269 46.1032 21.5089 45.9607 21.444 45.7914C21.3606 45.6057 21.3189 45.4034 21.3219 45.1992C21.3208 44.9929 21.3588 44.7883 21.4338 44.5967C21.4947 44.4312 21.605 44.2895 21.7492 44.1916C21.8713 44.1091 22.0122 44.06 22.1583 44.0491C22.3043 44.0382 22.4507 44.0658 22.5833 44.1293C22.6822 44.1786 22.7665 44.2539 22.8275 44.3474V43.3086H23.1835V46.3419L22.8478 46.29Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M25.167 46.2896V45.9675C25.0956 46.0855 24.995 46.1822 24.8754 46.2478C24.7557 46.3135 24.6212 46.3458 24.4855 46.3415C24.3631 46.3436 24.2417 46.3188 24.1294 46.2688C24.0367 46.2236 23.9537 46.1601 23.8853 46.0818C23.8299 45.9969 23.7919 45.9015 23.7734 45.8013C23.7656 45.6872 23.7656 45.5726 23.7734 45.4585V44.0977H24.1396V45.3131C24.1309 45.4445 24.1309 45.5764 24.1396 45.7078C24.1584 45.7991 24.209 45.8804 24.282 45.9364C24.3671 45.9917 24.466 46.0206 24.5669 46.0195C24.6736 46.0166 24.7781 45.9881 24.872 45.9364C24.9626 45.8803 25.0337 45.7968 25.0755 45.6974C25.1228 45.5609 25.1436 45.4162 25.1365 45.2715V44.0977H25.5027V46.2896H25.167Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M27.4661 45.4899H27.8222C27.7974 45.7196 27.6883 45.9311 27.517 46.082C27.3551 46.2295 27.1438 46.3076 26.927 46.3002C26.6615 46.3018 26.406 46.1974 26.2149 46.0093C26.0267 45.7685 25.9325 45.4651 25.9504 45.1575C25.9474 44.9443 25.9855 44.7325 26.0623 44.5342C26.1322 44.3642 26.2582 44.2245 26.4183 44.1395C26.5734 44.0497 26.7488 44.0032 26.927 44.0044C27.1339 43.9976 27.3374 44.0596 27.5068 44.181C27.6559 44.3168 27.7561 44.4997 27.7916 44.7004H27.4356C27.4107 44.5725 27.3466 44.4561 27.2525 44.368C27.1684 44.2922 27.0595 44.2514 26.9473 44.2537C26.862 44.2505 26.7772 44.2676 26.6995 44.3036C26.6218 44.3397 26.5534 44.3937 26.4997 44.4615C26.3662 44.643 26.3044 44.8693 26.3268 45.0952C26.3056 45.3227 26.3632 45.5506 26.4896 45.7392C26.5427 45.8043 26.6098 45.8559 26.6857 45.8902C26.7615 45.9244 26.8441 45.9403 26.927 45.9366C27.0585 45.9413 27.1864 45.8928 27.283 45.8016C27.3708 45.7167 27.4341 45.6089 27.4661 45.4899Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M29.5005 45.1883C29.3058 45.2574 29.1047 45.3061 28.9003 45.3338C28.7923 45.3423 28.6861 45.3668 28.585 45.4065C28.5258 45.4263 28.4754 45.4667 28.4426 45.5208C28.4059 45.5717 28.3879 45.6342 28.3917 45.6974C28.3888 45.7451 28.3976 45.7928 28.4171 45.8363C28.4366 45.8798 28.4663 45.9177 28.5036 45.9467C28.5464 45.9851 28.5965 46.0141 28.6507 46.032C28.705 46.0498 28.7622 46.0562 28.819 46.0506C28.9504 46.0545 29.0804 46.0222 29.1953 45.9571C29.3218 45.9118 29.4295 45.8238 29.5005 45.7077C29.5475 45.5892 29.5683 45.4615 29.5616 45.3338V45.1883H29.5005ZM29.5005 46.0194C29.3813 46.1224 29.2475 46.2064 29.1038 46.2687C28.9776 46.3197 28.8427 46.3445 28.7071 46.3414C28.5091 46.3519 28.3141 46.2892 28.1577 46.1648C28.0968 46.1066 28.0489 46.0356 28.0173 45.9567C27.9857 45.8778 27.9711 45.7929 27.9746 45.7077C27.9726 45.606 27.9972 45.5056 28.0458 45.4169C28.0897 45.3308 28.1524 45.2562 28.2289 45.1987C28.3093 45.1441 28.3991 45.1053 28.4934 45.0845L28.819 45.0221C29.0398 45.0055 29.2582 44.9637 29.47 44.8975V44.804C29.4768 44.746 29.4712 44.6872 29.4536 44.6316C29.4361 44.576 29.407 44.525 29.3683 44.4819C29.2485 44.3904 29.1004 44.3461 28.9512 44.3573C28.8203 44.3442 28.6887 44.3733 28.5748 44.4404C28.4801 44.528 28.4159 44.6447 28.3917 44.7728L28.0357 44.7209C28.0575 44.5858 28.1097 44.4578 28.1883 44.3469C28.2727 44.24 28.3858 44.1606 28.5138 44.1184C28.6713 44.0659 28.8364 44.0413 29.0021 44.0456C29.1536 44.0407 29.3049 44.0617 29.4497 44.108C29.5467 44.1407 29.6342 44.1979 29.704 44.2742C29.7591 44.3515 29.7973 44.4401 29.8159 44.5339C29.8244 44.648 29.8244 44.7626 29.8159 44.8767V45.3753C29.8159 45.7181 29.8159 45.9363 29.8159 46.0298C29.8356 46.1199 29.8664 46.2072 29.9074 46.2895H29.531C29.5202 46.199 29.5271 46.1071 29.5514 46.0194H29.5005Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M30.2637 46.2898V45.9886L31.6268 44.3784H31.2199H30.3451V44.0771H32.0947V44.3161L30.9351 45.7081L30.7113 45.9574H31.169H32.1863V46.2794L30.2637 46.2898Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M32.5522 44.0983H32.9185V46.2902H32.5522V44.0983ZM32.5522 43.2568H32.9185V43.6828H32.5522V43.2568Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M33.6915 45.1887C33.6757 45.414 33.7368 45.6379 33.8645 45.8224C33.9202 45.8903 33.9904 45.9444 34.0698 45.9804C34.1491 46.0164 34.2355 46.0334 34.3222 46.0301C34.4076 46.0342 34.4927 46.0175 34.5706 45.9814C34.6484 45.9453 34.7167 45.8908 34.7698 45.8224C34.9068 45.6382 34.972 45.4087 34.9529 45.1783C34.9692 44.9578 34.9039 44.7391 34.7698 44.5654C34.7162 44.4976 34.6478 44.4436 34.5701 44.4075C34.4924 44.3715 34.4075 44.3544 34.3222 44.3576C34.2355 44.3544 34.1491 44.3714 34.0698 44.4074C33.9904 44.4434 33.9202 44.4974 33.8645 44.5654C33.7367 44.7459 33.6755 44.9666 33.6915 45.1887ZM33.3151 45.1887C33.3031 45.0231 33.3259 44.8567 33.3821 44.7008C33.4382 44.5449 33.5264 44.4031 33.6407 44.2849C33.8317 44.123 34.0741 44.038 34.3222 44.046C34.4559 44.0396 34.5894 44.0621 34.7139 44.1123C34.8384 44.1624 34.9511 44.239 35.0445 44.3369C35.1431 44.4474 35.2191 44.5769 35.268 44.7178C35.317 44.8588 35.3378 45.0083 35.3293 45.1575C35.3401 45.3892 35.2983 45.6203 35.2073 45.8328C35.1234 45.9912 34.9962 46.1212 34.841 46.2067C34.683 46.2983 34.5039 46.3449 34.3222 46.3418C34.1867 46.344 34.052 46.3184 33.9262 46.2667C33.8005 46.215 33.6861 46.1381 33.5898 46.0405C33.3924 45.8043 33.2938 45.4984 33.3151 45.1887Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M35.7568 46.2902V44.0982H36.0824V44.4099C36.1551 44.2915 36.2577 44.1953 36.3794 44.1313C36.5011 44.0673 36.6375 44.038 36.7741 44.0463C36.8965 44.0442 37.0179 44.069 37.1301 44.119C37.2269 44.157 37.3114 44.2218 37.3743 44.306C37.4297 44.3871 37.4678 44.4791 37.4862 44.5761C37.4945 44.6972 37.4945 44.8186 37.4862 44.9397V46.2902H37.12V44.9501C37.1396 44.8402 37.1396 44.7276 37.12 44.6177C37.0897 44.5405 37.0363 44.475 36.9674 44.4307C36.8895 44.3871 36.8018 44.3656 36.7131 44.3683C36.5653 44.369 36.4221 44.4202 36.3062 44.5138C36.2424 44.5948 36.1952 44.6879 36.1672 44.7879C36.1393 44.8878 36.1312 44.9924 36.1434 45.0955V46.2902H35.7568Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M38.3405 44.9808H39.5409C39.5366 44.8306 39.4868 44.6855 39.3984 44.5653C39.3458 44.4956 39.2779 44.4396 39.2001 44.4017C39.1224 44.3638 39.037 44.3451 38.9509 44.3471C38.8733 44.3466 38.7964 44.362 38.7248 44.3923C38.6532 44.4226 38.5882 44.4673 38.5338 44.5237C38.4735 44.583 38.4253 44.654 38.3921 44.7324C38.359 44.8109 38.3414 44.8954 38.3405 44.9808ZM39.5409 45.5833H39.9173C39.8652 45.7899 39.7471 45.9727 39.5816 46.1028C39.3745 46.2421 39.1278 46.3065 38.8807 46.2858C38.6336 46.2651 38.4004 46.1604 38.2184 45.9885C38.0232 45.7592 37.9245 45.4606 37.9438 45.1574C37.9261 44.845 38.0241 44.5372 38.2184 44.2952C38.3147 44.1977 38.4291 44.1208 38.5549 44.069C38.6806 44.0173 38.8153 43.9918 38.9509 43.9939C39.0823 43.9884 39.2134 44.0125 39.3347 44.0646C39.4561 44.1166 39.5646 44.1954 39.6528 44.2952C39.8486 44.5282 39.9472 44.8303 39.9274 45.1366V45.2405H38.3202C38.318 45.4436 38.3906 45.6401 38.5236 45.7911C38.5806 45.8533 38.6502 45.9023 38.7274 45.9345C38.8047 45.9668 38.8878 45.9817 38.9712 45.9781C39.0914 45.9835 39.2098 45.9468 39.3069 45.8742C39.4085 45.7999 39.4892 45.6996 39.5409 45.5833Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M40.4666 46.2902V45.8643H40.8837V46.2902C40.8879 46.4198 40.8599 46.5485 40.8023 46.6641C40.7463 46.7683 40.6563 46.8492 40.548 46.8927L40.4463 46.7369C40.514 46.7019 40.5705 46.6478 40.6091 46.581C40.6542 46.4913 40.6752 46.3909 40.6701 46.2902H40.4666Z" fill="#1A1A18" />
                <path d="M0.366214 48.3467H0V51.38H0.366214V48.3467Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.29878 50.2689C2.10902 50.343 1.91073 50.3919 1.70877 50.4144C1.60158 50.428 1.49596 50.4524 1.39342 50.4871C1.33418 50.5166 1.28208 50.5592 1.24083 50.6117C1.22572 50.6696 1.22572 50.7305 1.24083 50.7883C1.24037 50.8348 1.24915 50.8808 1.26663 50.9236C1.28412 50.9665 1.30995 51.0053 1.34255 51.0376C1.43779 51.1051 1.55239 51.138 1.66808 51.1311C1.79589 51.1318 1.92181 51.0996 2.03429 51.0376C2.14187 50.9815 2.22785 50.89 2.27843 50.7779C2.30343 50.6581 2.30343 50.5342 2.27843 50.4144V50.2689H2.29878ZM2.29878 51.1104C2.18547 51.2137 2.05441 51.2947 1.91222 51.3493C1.78605 51.4003 1.65116 51.425 1.51549 51.422C1.33377 51.4274 1.15641 51.3646 1.01703 51.2454C0.953337 51.1912 0.902762 51.1226 0.869239 51.0451C0.835716 50.9677 0.820149 50.8833 0.823751 50.7987C0.826926 50.6944 0.851168 50.5918 0.894959 50.4975C0.943004 50.414 1.00912 50.343 1.08824 50.2897C1.17009 50.2354 1.25916 50.1934 1.35273 50.165C1.46094 50.1534 1.57004 50.1534 1.67825 50.165C1.89562 50.1411 2.11023 50.0959 2.31912 50.03C2.32225 49.9989 2.32225 49.9676 2.31912 49.9365C2.32402 49.8786 2.31754 49.8203 2.30008 49.765C2.28261 49.7097 2.2545 49.6585 2.2174 49.6145C2.09683 49.5246 1.94932 49.4805 1.80032 49.4898C1.66508 49.4791 1.52993 49.5118 1.41376 49.5833C1.31888 49.6649 1.25722 49.7798 1.24083 49.9053L0.874614 49.8534C0.891038 49.7025 0.939651 49.5571 1.01703 49.4275C1.10524 49.3272 1.2174 49.2521 1.34255 49.2093C1.49942 49.1531 1.66461 49.125 1.83084 49.1262C1.98333 49.119 2.13565 49.1438 2.27843 49.1989C2.37609 49.2304 2.46384 49.2878 2.53275 49.3651C2.59004 49.4373 2.62854 49.523 2.64465 49.6145C2.65321 49.7286 2.65321 49.8432 2.64465 49.9573V50.4559C2.62937 50.6738 2.62937 50.8925 2.64465 51.1104C2.66437 51.2038 2.69512 51.2945 2.7362 51.3805H2.41068C2.37163 51.2946 2.34425 51.2038 2.3293 51.1104H2.29878Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.30322 50.4036H4.66944C4.68401 50.5371 4.72933 50.6653 4.80168 50.7775C4.87495 50.8826 4.97787 50.9623 5.09669 51.0061C5.24076 51.071 5.39701 51.1029 5.55445 51.0996C5.69012 51.1026 5.82501 51.0779 5.95118 51.0269C6.0525 50.9922 6.14117 50.927 6.2055 50.8399C6.25787 50.7638 6.28626 50.6732 6.28688 50.5802C6.29114 50.4896 6.26212 50.4007 6.2055 50.3308C6.13163 50.2522 6.04117 50.1918 5.94101 50.1542C5.76776 50.0948 5.5911 50.0462 5.41204 50.0088C5.21713 49.9637 5.0265 49.9011 4.84237 49.8218C4.71244 49.7532 4.60075 49.6533 4.51685 49.531C4.44151 49.4118 4.40144 49.2729 4.40144 49.131C4.40144 48.9891 4.44151 48.8503 4.51685 48.7311C4.60199 48.5912 4.73111 48.485 4.88306 48.4298C5.0571 48.3565 5.24419 48.3212 5.43238 48.3259C5.63059 48.3195 5.82797 48.3549 6.01222 48.4298C6.17163 48.4919 6.30718 48.6049 6.39878 48.7518C6.4914 48.8939 6.54417 49.0592 6.55137 49.2297H6.17498C6.16801 49.0754 6.10231 48.9301 5.99187 48.8246C5.84551 48.7141 5.66451 48.6623 5.48324 48.6791C5.29702 48.6615 5.11048 48.7092 4.95427 48.8142C4.90393 48.8507 4.86288 48.899 4.83456 48.955C4.80624 49.011 4.79148 49.0732 4.79151 49.1362C4.78989 49.1863 4.80014 49.2361 4.82139 49.2813C4.84265 49.3265 4.87427 49.3658 4.91358 49.3959C5.09265 49.511 5.29387 49.5854 5.50359 49.6141C5.72874 49.662 5.94984 49.728 6.16481 49.8114C6.31622 49.8775 6.44657 49.9855 6.54119 50.1231C6.62256 50.2495 6.66508 50.3978 6.66327 50.549C6.66505 50.7115 6.61898 50.8707 6.53102 51.0061C6.43746 51.1537 6.30286 51.2695 6.14446 51.3385C5.9659 51.4225 5.77137 51.465 5.5748 51.4632C5.34157 51.4707 5.10938 51.4283 4.89323 51.3385C4.70704 51.246 4.55661 51.0923 4.46598 50.9022C4.37077 50.753 4.31472 50.5813 4.30322 50.4036Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.56511 50.5701H8.92115C8.89175 50.7983 8.78352 51.0083 8.61598 51.1622C8.45304 51.3068 8.24155 51.3813 8.02596 51.37C7.89381 51.3763 7.76187 51.3536 7.63904 51.3034C7.5162 51.2532 7.40533 51.1767 7.31388 51.0791C7.12634 50.842 7.03204 50.542 7.0494 50.2377C7.04643 50.0244 7.08445 49.8127 7.16129 49.6144C7.23402 49.4436 7.35893 49.3015 7.51733 49.2092C7.67338 49.1221 7.84811 49.0757 8.02596 49.0742C8.23172 49.0644 8.43402 49.1308 8.59563 49.2612C8.75105 49.3888 8.85586 49.5697 8.89064 49.7702L8.5346 49.8637C8.51265 49.7347 8.44801 49.6174 8.35149 49.5313C8.30916 49.4915 8.25941 49.4609 8.20522 49.4413C8.15104 49.4217 8.09353 49.4134 8.03614 49.417C7.95327 49.4133 7.87069 49.4292 7.79482 49.4635C7.71896 49.4977 7.65185 49.5493 7.59872 49.6144C7.46539 49.8 7.40381 50.0293 7.42578 50.2584C7.40944 50.4856 7.46663 50.7118 7.58854 50.9025C7.64167 50.9676 7.70878 51.0192 7.78465 51.0534C7.86052 51.0877 7.9431 51.1036 8.02596 51.0999C8.15745 51.1046 8.28543 51.0561 8.38201 50.9648C8.48067 50.8552 8.54451 50.7175 8.56511 50.5701Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.23682 49.1777H9.60303V51.38H9.23682V49.1777ZM9.23682 48.3467H9.60303V48.8245H9.23682V48.3467Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4269 50.0714H11.6273C11.628 49.922 11.5817 49.7763 11.4951 49.6559C11.4382 49.5962 11.3702 49.5488 11.295 49.5164C11.2199 49.484 11.1392 49.4673 11.0576 49.4673C10.9761 49.4673 10.8954 49.484 10.8202 49.5164C10.7451 49.5488 10.6771 49.5962 10.6202 49.6559C10.5044 49.784 10.436 49.9493 10.4269 50.1234V50.0714ZM11.6273 50.6636H12.0037C11.9566 50.8696 11.8417 51.053 11.6782 51.183C11.4695 51.3211 11.2224 51.3856 10.9744 51.3669C10.7265 51.3481 10.4913 51.2471 10.3049 51.0791C10.1242 50.8354 10.0264 50.5381 10.0264 50.2325C10.0264 49.9268 10.1242 49.6295 10.3049 49.3858C10.495 49.1955 10.7507 49.0889 11.0169 49.0889C11.2832 49.0889 11.5389 49.1955 11.729 49.3858C11.9229 49.6199 12.0213 49.9212 12.0037 50.2273V50.3311H10.4066C10.4091 50.5334 10.4811 50.7282 10.61 50.8817C10.6666 50.9453 10.7357 50.9958 10.8129 51.0298C10.8901 51.0639 10.9736 51.0807 11.0576 51.0791C11.1804 51.0796 11.3006 51.0435 11.4035 50.9752C11.5054 50.895 11.583 50.7869 11.6273 50.6636Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4614 51.3798V49.1775H12.7869V49.4891C12.862 49.373 12.965 49.2785 13.0862 49.2148C13.2073 49.1511 13.3425 49.1204 13.4787 49.1256C13.6007 49.1278 13.7213 49.1524 13.8347 49.1983C13.9308 49.2377 14.015 49.3021 14.0789 49.3853C14.1343 49.4701 14.1723 49.5655 14.1908 49.6657C14.1995 49.7868 14.1995 49.9083 14.1908 50.0293V51.3798H13.8246V50.0397C13.8448 49.9264 13.8448 49.8102 13.8246 49.6969C13.7943 49.6224 13.7406 49.5603 13.672 49.5203C13.5951 49.4732 13.5073 49.448 13.4176 49.4476C13.2707 49.4453 13.1285 49.5011 13.0209 49.6034C12.9552 49.6814 12.9059 49.7724 12.8761 49.8707C12.8463 49.969 12.8368 50.0725 12.848 50.1748V51.3798H12.4614Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5771 51.3797V51.0784L15.9504 49.4682H14.6585V49.167H16.4184V49.4163L15.2587 50.8187L15.0349 51.0784C15.1873 51.0685 15.3402 51.0685 15.4927 51.0784H16.5099V51.4005L14.5771 51.3797Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.2192 50.2683C18.0259 50.3422 17.8243 50.391 17.6191 50.4137C17.5121 50.4287 17.4066 50.453 17.3037 50.4864C17.2456 50.5133 17.1962 50.5566 17.1613 50.6111C17.1203 50.678 17.104 50.7575 17.1152 50.8355C17.1264 50.9135 17.1643 50.985 17.2223 51.037C17.3168 51.106 17.432 51.139 17.5478 51.1305C17.6759 51.1331 17.8023 51.1008 17.9141 51.037C18.0216 50.9808 18.1076 50.8894 18.1582 50.7773C18.2043 50.6621 18.2251 50.538 18.2192 50.4137V50.2683ZM18.2192 51.1097C18.1059 51.2131 17.9749 51.2941 17.8327 51.3487C17.7027 51.3987 17.5647 51.4234 17.4258 51.4214C17.2272 51.437 17.0305 51.3737 16.8765 51.2448C16.8158 51.1886 16.7679 51.1194 16.7363 51.0422C16.7046 50.965 16.69 50.8817 16.6933 50.7981C16.6914 50.6931 16.7159 50.5894 16.7646 50.4968C16.8119 50.4161 16.874 50.3456 16.9477 50.2891C17.0308 50.2371 17.1195 50.1953 17.2121 50.1644C17.3204 50.1536 17.4294 50.1536 17.5377 50.1644C17.7583 50.1403 17.9763 50.0951 18.1887 50.0294V49.8632C18.1955 49.8052 18.1899 49.7464 18.1724 49.6908C18.1548 49.6352 18.1257 49.5841 18.087 49.5411C17.9664 49.47 17.8295 49.4326 17.6903 49.4326C17.551 49.4326 17.4142 49.47 17.2935 49.5411C17.1984 49.624 17.1338 49.7377 17.1104 49.8632L16.7544 49.8112C16.775 49.674 16.8312 49.5449 16.9171 49.4372C16.9994 49.335 17.109 49.2592 17.2325 49.2191C17.3894 49.1629 17.5545 49.1348 17.7208 49.136C17.8733 49.1288 18.0256 49.1535 18.1684 49.2087C18.2685 49.2346 18.3577 49.2929 18.4227 49.3749C18.4836 49.4447 18.5225 49.5316 18.5346 49.6242C18.5484 49.7381 18.5484 49.8532 18.5346 49.967V50.4657C18.5346 50.8085 18.5346 51.0266 18.5346 51.1201C18.5532 51.2154 18.5876 51.3068 18.6363 51.3902H18.3108C18.2726 51.3017 18.2519 51.2064 18.2498 51.1097H18.2192Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5793 50.0714H21.7796C21.7804 49.922 21.734 49.7763 21.6474 49.6559C21.5905 49.5962 21.5225 49.5488 21.4474 49.5164C21.3723 49.484 21.2915 49.4673 21.21 49.4673C21.1284 49.4673 21.0477 49.484 20.9726 49.5164C20.8974 49.5488 20.8294 49.5962 20.7726 49.6559C20.6528 49.7813 20.5838 49.9483 20.5793 50.1234V50.0714ZM21.7796 50.6636H22.156C22.1087 50.8722 21.9896 51.0565 21.8203 51.183C21.6417 51.3105 21.4278 51.376 21.21 51.37C21.0717 51.3763 20.9337 51.3538 20.8042 51.3038C20.6748 51.2538 20.5567 51.1773 20.4572 51.0791C20.2765 50.8354 20.1787 50.5381 20.1787 50.2325C20.1787 49.9268 20.2765 49.6295 20.4572 49.3858C20.6474 49.1955 20.903 49.0889 21.1693 49.0889C21.4356 49.0889 21.6912 49.1955 21.8814 49.3858C22.0753 49.6199 22.1736 49.9212 22.156 50.2273V50.3311H20.5589C20.5614 50.5334 20.6334 50.7282 20.7624 50.8817C20.818 50.9464 20.8871 50.9976 20.9645 51.0318C21.0419 51.0659 21.1257 51.0821 21.21 51.0791C21.3325 51.0779 21.4522 51.0419 21.5558 50.9752C21.6552 50.8925 21.7322 50.7852 21.7796 50.6636Z" fill="#1A1A18" />
                <path d="M24.1191 48.3467H23.7529V51.38H24.1191V48.3467Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.062 50.2683C25.8687 50.3422 25.667 50.391 25.4618 50.4137C25.3549 50.4287 25.2494 50.453 25.1465 50.4864C25.0884 50.5133 25.039 50.5566 25.0041 50.6111C24.9631 50.678 24.9468 50.7575 24.958 50.8355C24.9691 50.9135 25.0071 50.985 25.0651 51.037C25.1596 51.106 25.2748 51.139 25.3906 51.1305C25.5186 51.1331 25.645 51.1008 25.7568 51.037C25.8644 50.9808 25.9504 50.8894 26.001 50.7773C26.0471 50.6621 26.0679 50.538 26.062 50.4137V50.2683ZM26.062 51.1097C25.9487 51.2131 25.8176 51.2941 25.6754 51.3487C25.5455 51.3987 25.4074 51.4234 25.2685 51.4214C25.07 51.437 24.8732 51.3737 24.7192 51.2448C24.6585 51.1886 24.6107 51.1194 24.579 51.0422C24.5474 50.965 24.5327 50.8817 24.5361 50.7981C24.5342 50.6931 24.5587 50.5894 24.6073 50.4968C24.6546 50.4161 24.7168 50.3456 24.7904 50.2891C24.8735 50.2371 24.9623 50.1953 25.0549 50.1644C25.1632 50.1536 25.2722 50.1536 25.3804 50.1644C25.6011 50.1403 25.8191 50.0951 26.0315 50.0294V49.8632C26.0383 49.8052 26.0327 49.7464 26.0151 49.6908C25.9976 49.6352 25.9684 49.5841 25.9298 49.5411C25.8091 49.47 25.6723 49.4326 25.533 49.4326C25.3938 49.4326 25.2569 49.47 25.1363 49.5411C25.0411 49.624 24.9765 49.7377 24.9532 49.8632L24.5972 49.8112C24.6178 49.674 24.674 49.5449 24.7599 49.4372C24.844 49.337 24.9529 49.2617 25.0753 49.2191C25.2321 49.1629 25.3973 49.1348 25.5635 49.136C25.716 49.1288 25.8684 49.1535 26.0111 49.2087C26.1113 49.2346 26.2005 49.2929 26.2655 49.3749C26.3263 49.4447 26.3653 49.5316 26.3774 49.6242C26.3912 49.7381 26.3912 49.8532 26.3774 49.967V50.4657C26.3774 50.8085 26.3774 51.0266 26.3774 51.1201C26.3959 51.2154 26.4303 51.3068 26.4791 51.3902H26.0925C26.075 51.2976 26.075 51.2024 26.0925 51.1097H26.062Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M30.3144 50.3097L30.7111 50.4136C30.6489 50.7103 30.4913 50.9772 30.2635 51.172C30.0379 51.3449 29.7623 51.4362 29.4802 51.4317C29.2048 51.443 28.9322 51.3707 28.6969 51.2239C28.4882 51.0887 28.3276 50.8883 28.2392 50.6525C28.1368 50.3917 28.085 50.1129 28.0866 49.8319C28.0763 49.5479 28.1358 49.2658 28.2595 49.0112C28.3708 48.7821 28.5484 48.5936 28.7682 48.471C28.9881 48.3544 29.2324 48.2938 29.4802 48.2944C29.7471 48.2768 30.0116 48.3555 30.2274 48.517C30.4431 48.6784 30.5963 48.9122 30.6603 49.1774L30.2737 49.2709C30.2245 49.0796 30.1178 48.9088 29.9685 48.7827C29.8243 48.6841 29.6538 48.6333 29.4802 48.6372C29.2745 48.6274 29.0711 48.6857 28.9004 48.8034C28.7508 48.9137 28.6402 49.0704 28.585 49.2501C28.5122 49.4457 28.4777 49.6539 28.4833 49.863C28.4796 50.0932 28.5175 50.3222 28.5952 50.5383C28.6575 50.7203 28.7797 50.8745 28.9411 50.9746C29.0951 51.0679 29.2706 51.1181 29.4497 51.12C29.6554 51.1159 29.8532 51.0388 30.0092 50.9019C30.1721 50.7443 30.2794 50.5361 30.3144 50.3097Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M32.5522 51.38V51.058C32.4781 51.1726 32.3767 51.2661 32.2575 51.3297C32.1382 51.3933 32.0051 51.4249 31.8707 51.4216C31.7483 51.4237 31.6268 51.3989 31.5146 51.3489C31.4186 51.3095 31.3344 51.245 31.2705 51.1619C31.2151 51.0808 31.177 50.9888 31.1586 50.8918C31.1509 50.7742 31.1509 50.6562 31.1586 50.5386V49.1777H31.535V50.3932C31.5204 50.5243 31.5204 50.6567 31.535 50.7879C31.5428 50.8337 31.5594 50.8775 31.5839 50.9167C31.6083 50.9559 31.6401 50.9898 31.6774 51.0164C31.7563 51.0778 31.8529 51.1107 31.952 51.1099C32.0636 51.1093 32.1728 51.0769 32.2674 51.0164C32.3576 50.9648 32.429 50.8846 32.4708 50.7879C32.5086 50.6458 32.5257 50.4988 32.5217 50.3516V49.1777H32.8879V51.38H32.5522Z" fill="#1A1A18" />
                <path d="M33.814 48.3467H33.4478V51.38H33.814V48.3467Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M35.1667 51.0478L35.2277 51.3698H34.953C34.8445 51.3767 34.7359 51.3553 34.6377 51.3075C34.5698 51.2715 34.5159 51.2128 34.4851 51.1413C34.462 50.9968 34.462 50.8494 34.4851 50.705V49.4688H34.2104V49.1779H34.4851V48.6377L34.8411 48.4092V49.1779H35.2074V49.4688H34.8411V50.7569C34.8318 50.8224 34.8318 50.8888 34.8411 50.9543C34.8529 50.9849 34.8744 51.0105 34.9022 51.027C34.9454 51.04 34.9913 51.04 35.0344 51.027L35.1667 51.0478Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M36.9365 51.38V51.058C36.8624 51.1726 36.7609 51.2661 36.6417 51.3297C36.5225 51.3933 36.3894 51.4249 36.2549 51.4216C36.1324 51.4247 36.0108 51.3998 35.8989 51.3489C35.8029 51.3095 35.7187 51.245 35.6547 51.1619C35.5993 51.0808 35.5612 50.9888 35.5428 50.8918C35.53 50.7744 35.53 50.656 35.5428 50.5386V49.1777H35.8989V50.3932C35.8826 50.5242 35.8826 50.6568 35.8989 50.7879C35.9067 50.8337 35.9233 50.8775 35.9478 50.9167C35.9722 50.9559 36.004 50.9898 36.0413 51.0164C36.1185 51.0809 36.2163 51.1142 36.316 51.1099C36.4246 51.1109 36.531 51.0783 36.6211 51.0164C36.7158 50.9707 36.789 50.8885 36.8246 50.7879C36.8717 50.6477 36.8924 50.4997 36.8856 50.3516V49.1777H37.2518V51.38H36.9365Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M37.832 51.3802V49.1779H38.1576V49.5103C38.2093 49.3913 38.2898 49.2876 38.3915 49.2091C38.4592 49.1572 38.5409 49.1282 38.6255 49.126C38.76 49.1304 38.8905 49.1736 39.0019 49.2506L38.8696 49.5934C38.7907 49.5413 38.6991 49.5125 38.6052 49.5103C38.5285 49.5124 38.4541 49.5377 38.3915 49.5831C38.3273 49.6365 38.281 49.7092 38.2593 49.7908C38.2177 49.9323 38.1971 50.0794 38.1982 50.2271V51.3802H37.832Z" fill="#1A1A18" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M40.5889 50.268C40.3991 50.3421 40.2008 50.391 39.9989 50.4135C39.8884 50.4275 39.7795 50.4519 39.6734 50.4862C39.6153 50.5131 39.5659 50.5563 39.5309 50.6109C39.49 50.6777 39.4737 50.7573 39.4848 50.8353C39.496 50.9133 39.534 50.9847 39.592 51.0368C39.6865 51.1057 39.8016 51.1388 39.9175 51.1303C40.0488 51.1327 40.1784 51.1005 40.2939 51.0368C40.3963 50.977 40.4781 50.8863 40.5279 50.7771C40.574 50.6619 40.5948 50.5378 40.5889 50.4135V50.268ZM40.5889 51.1095C40.4756 51.2128 40.3445 51.2938 40.2023 51.3484C40.0726 51.3995 39.9344 51.4242 39.7954 51.4211C39.6986 51.4297 39.601 51.4185 39.5085 51.3882C39.4159 51.3579 39.3302 51.309 39.2563 51.2445C39.1926 51.1903 39.142 51.1218 39.1085 51.0443C39.075 50.9668 39.0594 50.8825 39.063 50.7978C39.0611 50.6929 39.0856 50.5891 39.1342 50.4966C39.1823 50.4132 39.2484 50.3421 39.3275 50.2888C39.4062 50.2354 39.4918 50.1935 39.5818 50.1642C39.6901 50.1534 39.7991 50.1534 39.9073 50.1642C40.1282 50.1412 40.3463 50.0959 40.5584 50.0291V49.8629C40.5652 49.8049 40.5596 49.7461 40.542 49.6905C40.5245 49.6349 40.4953 49.5839 40.4567 49.5409C40.336 49.4698 40.1992 49.4323 40.0599 49.4323C39.9206 49.4323 39.7838 49.4698 39.6632 49.5409C39.568 49.6238 39.5034 49.7374 39.4801 49.8629L39.124 49.811C39.1492 49.6751 39.2049 49.5471 39.2868 49.437C39.373 49.3344 39.4858 49.2588 39.6123 49.2188C39.7688 49.1609 39.9342 49.1328 40.1006 49.1357C40.2531 49.1294 40.4052 49.1541 40.5482 49.2085C40.6444 49.237 40.7296 49.295 40.7923 49.3747C40.8564 49.4435 40.8989 49.5303 40.9144 49.624C40.9214 49.7381 40.9214 49.8526 40.9144 49.9668V50.4654C40.8971 50.6832 40.8971 50.9021 40.9144 51.1199C40.9247 51.2158 40.956 51.3081 41.006 51.39H40.6906C40.6542 51.3003 40.6302 51.2059 40.6194 51.1095H40.5889Z" fill="#1A1A18" />
            </g>
            <defs>
                <clipPath id="clip0_30_498">
                    <rect width="122" height="57" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default NazioniUniteIcon;