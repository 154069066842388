import React, { useEffect, useState } from 'react';
import '../css/BlobBackground.css';
import Blob from '../icons/Blob';

const BlobBackground: React.FC = () => {

    const [windowHeight, setWindowHeight] = useState(9 * window.innerHeight / 10);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(9 * window.innerHeight / 10);
        };

        window.addEventListener('resize', handleResize);

        // Pulizia alla disattivazione del componente
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Array vuoto indica che l'effetto non dipende da alcuna prop o stato


    return (
        <div className='blobBack'>
            <Blob height={`${windowHeight}`} width={`${windowHeight}`} />
        </div>
    )
}

export default BlobBackground;