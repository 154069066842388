import React from 'react';
import '../css/WrapperScreen.css';

interface WrapperScreenProps {
    children: React.ReactNode;
    style?: React.CSSProperties;
}

const WrapperScreen: React.FC<WrapperScreenProps> = ({ children, style }) => {
    return (
        <div className='wrapper_div' style={{ ...style }}>
            {children}
        </div>
    );
};

export default WrapperScreen;
