import React from 'react';
import { IconInterface } from '../components/IconInterface';

const CultHuRaIcon: React.FC<IconInterface> = ({ width, height, fill }) => {
    return (
        <svg width={`${width ? `${width}px` : '20px'}`} height={`${height ? `${height}px` : '20px'}`} viewBox="0 0 104 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M101.401 12.1238V10.4482H103.06V12.1238H101.401Z" fill="black" />
            <path d="M88.8628 12.1235V10.8788H89.9479L93.5384 0.857422H95.3416L98.932 10.8788H100.017V12.1235H96.1075V10.8788H97.3043L96.4267 8.37343H92.4373L91.5596 10.8788H92.7724V12.1235H88.8628ZM92.8362 7.09683H96.0277L94.6554 3.18723C94.4958 2.72446 94.4639 2.32552 94.4639 2.32552H94.416C94.416 2.32552 94.3682 2.72446 94.2245 3.18723L92.8362 7.09683Z" fill="black" />
            <path d="M78.3286 12.1235V10.8788H79.7329V2.08615H78.3286V0.857422H83.2116C84.0095 0.857422 84.6638 0.889337 85.2861 1.09679C86.467 1.49572 87.2489 2.48509 87.2489 4.01702C87.2489 5.64469 86.3553 6.80959 84.9191 7.17662V7.20853C84.9191 7.20853 85.2063 7.36811 85.3978 7.73513L86.9936 10.5117C87.1691 10.8149 87.3765 10.8788 87.7755 10.8788H88.1106V12.1235H87.1531C86.1957 12.1235 85.9723 11.9479 85.5893 11.2777L83.8499 8.1979C83.5627 7.71917 83.3552 7.57555 82.6691 7.57555H81.3286V10.8788H82.7169V12.1235H78.3286ZM81.3286 6.26703H83.5946C84.8233 6.26703 85.6372 5.46916 85.6372 4.14468C85.6372 3.26701 85.2861 2.66063 84.584 2.37339C84.217 2.22977 83.7382 2.16594 83.1957 2.16594H81.3286V6.26703Z" fill="black" />
            <path d="M74.6123 12.1238V10.4482H76.2719V12.1238H74.6123Z" fill="black" />
            <path d="M62.9412 7.92662V2.08615H61.521V0.857422H65.9572V2.08615H64.537V7.91066C64.537 8.64471 64.6806 9.28301 64.9678 9.74578C65.4306 10.4798 66.3083 10.8947 67.3934 10.8947C68.4625 10.8947 69.3562 10.4798 69.8189 9.71387C70.0902 9.2511 70.2498 8.62875 70.2498 7.89471V2.08615H68.8296V0.857422H73.2498V2.08615H71.8455V7.92662C71.8455 8.99578 71.5583 9.96919 71.0636 10.6713C70.2977 11.7564 68.9253 12.315 67.3934 12.315C65.7976 12.315 64.4572 11.7086 63.6912 10.6713C63.1646 9.95323 62.9412 9.01173 62.9412 7.92662Z" fill="black" />
            <path d="M48.3452 12.1235V10.8788H49.7495V2.08615H48.3452V0.857422H52.7655V2.08615H51.3452V5.83618H57.1857V2.08615H55.7655V0.857422H60.1857V2.08615H58.7815V10.8788H60.1857V12.1235H55.7655V10.8788H57.1857V7.1447H51.3452V10.8788H52.7655V12.1235H48.3452Z" fill="black" />
            <path d="M44.6299 12.1238V10.4482H46.2895V12.1238H44.6299Z" fill="black" />
            <path d="M36.4144 12.1235V10.8788H37.8346V2.16594H35.1857V3.92127H33.7974V0.857422H43.4676V3.92127H42.0793V2.16594H39.4304V10.8788H40.8506V12.1235H36.4144Z" fill="black" />
            <path d="M25.2197 12.1235V10.8788H26.624V2.16594H25.2197V0.857422H29.64V2.16594H28.2197V10.8149H32.1453V8.99578H33.5496V12.1235H25.2197Z" fill="black" />
            <path d="M13.5569 7.92662V2.08615H12.1367V0.857422H16.5729V2.08615H15.1527V7.91066C15.1527 8.64471 15.2963 9.28301 15.5836 9.74578C16.0463 10.4798 16.924 10.8947 18.0091 10.8947C19.0783 10.8947 19.9719 10.4798 20.4346 9.71387C20.7059 9.2511 20.8655 8.62875 20.8655 7.89471V2.08615H19.4453V0.857422H23.8655V2.08615H22.4613V7.92662C22.4613 8.99578 22.174 9.96919 21.6793 10.6713C20.9134 11.7564 19.541 12.315 18.0091 12.315C16.4133 12.315 15.0729 11.7086 14.3069 10.6713C13.7803 9.95323 13.5569 9.01173 13.5569 7.92662Z" fill="black" />
            <path d="M0.549316 6.42669C0.549316 3.12348 3.05465 0.666016 6.30999 0.666016C7.66639 0.666016 10.6026 1.1607 10.6026 2.90007V4.27242H9.11852V3.39476C9.11852 2.40539 7.41107 2.03837 6.37382 2.03837C3.96423 2.03837 2.19294 3.80966 2.19294 6.36286C2.19294 9.02778 4.01211 10.9267 6.46957 10.9267C7.26745 10.9267 9.2781 10.6395 9.2781 9.60225V8.72458H10.7622V10.0969C10.7622 11.7565 7.93766 12.315 6.37382 12.315C2.99082 12.315 0.549316 9.71395 0.549316 6.42669Z" fill="black" />
        </svg>

    )
}

export default CultHuRaIcon;