import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { QuestionProvider } from './components/QuestionProvider';
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material';
import Footer from './components/Footer';
import Header from './components/Header';

//Override button variants
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    'zacco-white': true;
    'zacco-outlined': true;
    'zacco-correct': true;
    'zacco-error': true;
    'zacco-full-rounded': true;
  }
}

const theme = createTheme({
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          height: '10px',
          borderRadius: '30px',
        },
        bar: {
          backgroundColor: 'rgba(136, 92, 49, 1)',
          borderRadius: '30px',
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(136, 92, 49, 1)',
          borderRadius: "30px",
          margin: "10px 0px 0px 0px",
          width: '150px',
          textTransform: 'uppercase',
          '& li': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            textTrasform: 'uppercase',
            fontWeight: 'bold',
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          backgroundColor: 'rgba(136, 92, 49, 1)',
          boxShadow: '0px 8px 20px 0px rgba(136, 92, 49, 0.37)',
          fontFamily: "Museo_Slab_500",
          fontSize: '14px',
          color: '#fff',
          padding: '16px 32px 16px 32px',
          borderRadius: '50px',
          display: 'inline-block',
          width: '100%',
          fontWeight: 'bold',
          '&:hover': {
            backgroundColor: 'rgba(136, 92, 49, 1)',
          }
        },
      },
      variants: [
        {
          props: { variant: 'zacco-white' },
          style: {
            backgroundColor: 'rgba(225, 168, 51, 1)',
            color: 'black',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: 'rgba(225, 168, 51, 1)',
            }
          },
        },
        {
          props: { variant: 'zacco-full-rounded' },
          style: {
            backgroundColor: 'white',
            aspectRatio: '1',
            borderRadius: '100%',
            color: 'black',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: 'white',
            }
          },
        },
        {
          props: { variant: 'zacco-outlined' },
          style: {
            backgroundColor: 'unset',
            color: 'black',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: 'unset',
            }
          },
        },
        {
          props: { variant: 'zacco-error' },
          style: {
            backgroundColor: 'rgba(224, 102, 64, 1)',
            color: 'black',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: 'rgba(224, 102, 64, 1)',
            }
          },
        },
        {
          props: { variant: 'zacco-correct' },
          style: {
            backgroundColor: 'rgba(80, 137, 87, 1)',
            color: 'black',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: 'rgba(80, 137, 87, 1)',
            }
          },
        }
      ],
    },
  }
});

/*
if (process.env.REACT_APP_NODE_ENV !== 'development') {
  console.log = function () { }; //Disattiva i console.log
}
*/

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <QuestionProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Header />
          <App />
          <Footer />
        </BrowserRouter>
      </ThemeProvider>
    </QuestionProvider>
  </React.StrictMode >
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
