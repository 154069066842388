import React, { useState, useCallback, useEffect, createContext, useContext } from 'react';
import all_questions from '../quest/questions.json'
import axios from 'axios';

export interface Options {
    testo: string;
    corretta: boolean;
}

// >> DEFINIZIONE DI UNA DOMANDA <<
export interface Question {
    _id: string;
    images: string[];
    lan: {
        [key: string]: {
            testo: string;
            opzioni: Options[];
        };
    };
    gruppo_eta: string;
}


// Creazione del contesto
const QuestionsContext = createContext({
    isLoading: true,
    lan: "",
    setLan: (value: string) => { },
    languages: [] as string[],
    setLanguages: (values: string[]) => { },
    questions: [] as Question[],
    setQuestions: (values: Question[]) => { },
    childQuestions: [] as Question[],
    setChildQuestions: (values: Question[]) => { },
    adultQuestions: [] as Question[],
    setAdultQuestions: (values: Question[]) => { },
    generateAdultQuiz: () => { },
    generateChildQuiz: () => { },
    shuffleQuiz: (array: any[]) => { },
    loadQuestions: () => { },
});

interface QuestionsContextProps {
    children: React.ReactNode;
}

export const QuestionProvider: React.FC<QuestionsContextProps> = ({ children }) => {

    // >> [CARICAMENTO E ERRORI] <<
    const [isLoading, setIsLoading] = useState<boolean>(true);

    // >> [DOMANDE E LINGUA] <<
    const [lan, setLan] = useState<string>("");
    const [languages, setLanguages] = useState<string[]>([]);

    // >> [CONFIGURAZIONE DELLE DOMANDE / LINGUA CORRENTE E TUTTE LE LINGUE] <<
    const configureQuestions = useCallback((questions: Question[]) => {
        console.log("All'ingresso di configureQuestions lan -> " + lan)
        console.log("All'ingresso di configureQuestions languages -> " + languages)
        setQuestions(questions);
        setChildQuestions(questions.filter((question) => question.gruppo_eta === "bambini"));
        setAdultQuestions(questions.filter((question) => question.gruppo_eta === "adulti"));

        //Setti la prima lingua disponibile se non è stata settata
        setLan((precedent_lan) => {
            if (precedent_lan !== "") return precedent_lan;
            return Object.keys(questions[0].lan)[0]
        });

        //Setta tutte le lingue disponibili se non sono state settate
        setLanguages((precedentLanguages) => {
            if (precedentLanguages?.length > 0) return precedentLanguages;
            return Object.keys(questions[0].lan)
        });
    }, [lan, languages])

    useEffect(() => {
        console.log("Lan  -> " + lan)
        console.log("Languages -> " + languages)
    }, [lan, languages])

    const loadQuestions = useCallback(() => {
        setIsLoading(true);
        configureQuestions(all_questions)
        setIsLoading(false)
    }, [])

    // >> [CARICAMENTO DOMANDE DAL SERVER] <<
    useEffect(() => {
        console.log("Viene fatto il mounting di QuestionsProvider");
        loadQuestions();
    }, []);

    // >> [TUTTE LE DOMANDE] <<
    const [questions, setQuestions] = useState<Question[]>([]);

    // >> [DOMANDE BAMBINO | ADULTO] <<
    const [childQuestions, setChildQuestions] = useState<Question[]>([]);
    const [adultQuestions, setAdultQuestions] = useState<Question[]>([]);

    // >> [GENERA ALMENO 12 DOMANDE PER ADULTO] <<
    const generateAdultQuiz = useCallback(() => {
        if (adultQuestions.length === 0) return [];
        let questions = [...adultQuestions];

        const questionLength = questions.length;
        if (questionLength < 12) return questions;

        const quiz = [];
        for (let i = 0; i < 12; i++) {
            let length = questions.length;
            let random = Math.floor(Math.random() * length);
            let q = questions[random];
            questions.splice(random, 1); //Rimuovi elemento
            quiz.push(q);
        }
        return quiz;
    }, [adultQuestions]);

    const generateChildQuiz = useCallback(() => {
        if (childQuestions.length === 0) return [];
        let questions = [...childQuestions];


        const questionLength = questions.length;
        if (questionLength < 12) return questions;

        const quiz = [];
        for (let i = 0; i < 12; i++) {
            let length = questions.length;
            let random = Math.floor(Math.random() * length);
            let q = questions[random];
            questions.splice(random, 1); //Rimuovi elemento
            quiz.push(q);
        }
        return quiz;
    }, [childQuestions]);

    // >> [FUNZIONE PER MESCOLARE LE DOMANDE - Fisher-Yates (o Knuth)] <<
    const shuffleQuiz = useCallback((array: any[]) => {
        var currentIndex = array.length, temporaryValue, randomIndex;
        while (0 !== currentIndex) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
        return array;
    }, []);



    return (
        <QuestionsContext.Provider value={{ isLoading, lan, setLan, languages, setLanguages, questions, setQuestions, childQuestions, setChildQuestions, adultQuestions, setAdultQuestions, generateAdultQuiz, generateChildQuiz, shuffleQuiz, loadQuestions }}>
            {children}
        </QuestionsContext.Provider>
    );
};

export const useQuestions = () => {
    const context = useContext(QuestionsContext);
    if (context === null) {
        throw new Error('useQuestions must be used within a QuestionsProvider');
    }
    return context;
};
