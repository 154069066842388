import React from 'react';
import NazioniUniteIcon from '../icons/NazioneUniteIcon';
import CaratIcon from '../icons/CaratIcon';
import '../css/Footer.css';
import CultHuRaIcon from '../icons/CultHuRaIcon';


const Footer: React.FC = () => {
    return (
        <div className='footer_div'>
            <div>
                <img height={'20px'} src="/FESR.svg" alt="Logo FESR" />
                <img src="/Repubblica_Italiana.svg" alt="Logo Rep. It." />
                <img src="/Regione_Siciliana.svg" alt="Logo Reg. Sicilia" />
                <img src="/Comune_Ragusa.svg" alt="Logo Com. Ragusa" />
                <NazioniUniteIcon />
                <CaratIcon />
            </div>
            <div>
                <CultHuRaIcon height='50px' width='50' />
            </div>
        </div>
    )
}

export default Footer;