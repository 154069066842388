import { Link } from "react-router-dom";
import GameSelection from "../components/GameSelection";
import { useNavigate } from 'react-router-dom';
import WrapperStep from "../components/WrapperStep";

const ZaccoHomePage = () => {

    const navigate = useNavigate();

    return (
        <div className="App">
            <WrapperStep>
                <GameSelection
                    onGameSelected={(uri: string) => {
                        navigate(`/${uri}`);
                    }} />
            </WrapperStep>
        </div>
    )
}

export default ZaccoHomePage