import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import '../css/FinishStep.css';
import { useQuestions } from './QuestionProvider';
import lang from '../quest/app_lan.json'

interface FinishStepProps {
    points: number;
    onRetry: () => void;
    onNewQuiz: () => void;
    onLeave: () => void;
    leaveTime: number;
}

const FinishStep: React.FC<FinishStepProps> = ({ points, onRetry, onNewQuiz, onLeave, leaveTime }) => {

    const { lan } = useQuestions();
    // @ts-ignore
    const againText = lang[lan]?.gioca_di_nuovo || "PLAY AGAIN"; // @ts-ignore
    const newQuixText = lang[lan]?.nuovo_quiz || "NEW QUIZ";  // @ts-ignore
    const congratsText = lang[lan]?.congratulazioni || "Congratulations"; // @ts-ignore
    const congratulazioni_sub_start = lang[lan]?.congratulazioni_sub?.split('[p]')?.[0] || "By answering correctly you got "; // @ts-ignore
    const congratulazioni_sub_end = lang[lan]?.congratulazioni_sub?.split('[p]')?.[1] || "points on ";

    useEffect(() => {
        const timerId = setTimeout(onLeave, leaveTime * 1000);
        return () => {
            clearTimeout(timerId);
        };
    }, [leaveTime])

    return (
        <div className='finish_ext'>
            <div className='finish_block'>
                <h1>{congratsText}</h1>
                <p>{`${congratulazioni_sub_start} ${points} ${congratulazioni_sub_end} 240`}</p>

                <div className='finish_image' style={{ backgroundImage: "url('./Congratulation.svg')" }} />

                <div className='finish_star'>
                    <img src='./Stars.svg' height={'50px'} />
                </div>
            </div>
            <div className='btn_zone'>
                <div><Button variant='zacco-white' onClick={onRetry}>{againText}</Button></div>
                <div><Button onClick={onNewQuiz}>{newQuixText}</Button></div>
            </div>
        </div>
    );
}

export default FinishStep;