
import React, { useCallback, useState } from 'react';
import { Question } from '../components/QuestionProvider';
import BlobBackground from '../components/BlobBackground';
import WelcomeStep from '../components/WelcomeStep';
import ModalityStep from '../components/ModalityStep';
import QuestionHandler from '../components/QuestionHandler';
import FinishStep from '../components/FinishStep';
import WrapperStep from '../components/WrapperStep';
import { Navigate, useNavigate } from 'react-router-dom';
const { useQuestions } = require('../components/QuestionProvider');

const Millionaire = () => {

    // HOOK PER LE DOMANDE
    const { lan, generateAdultQuiz, generateChildQuiz, reset, shuffleQuiz, loadQuestions } = useQuestions();
    const navigate = useNavigate();

    //SEI IN WELCOME OPPURE NO
    const [isWelcomeScreen, setIsWelcomeScreen] = useState<boolean>(true);

    //SCELTA GRUPPO ETA
    const [gruppoEta, setGruppoEta] = useState<string | undefined>(undefined);

    // ATTUALE DOMANDA E CONTATORE
    const [quiz, setQuiz] = useState<Question[]>([]);
    const [currentQuestion, setCurrentQuestion] = useState<Question | undefined>(undefined);
    const [counter, setCounter] = useState<number>(1);
    const [maxCounter, setMaxCounter] = useState<number>(12);

    // FUNZIONE DI VALIDAZIONE RISPOSTA E DI SALVATAGGIO DELLLE RISPOSTE
    interface Answer { question: Question; status: boolean; }

    // RISPOSTE DATE DALL'UTENTE CON RELATIVO STATO (CORRETTO O SBAGLIATO)
    const [answers, setAnswers] = useState<Answer[]>([]);

    // FUNZIONE PER VALIDARE LA RISPOSTA DATA DALL'UTENTE
    const onValidateAnswer = useCallback((indx: number) => {
        console.log("Validazione di " + quiz[counter - 1]?.lan[lan]?.testo)
        console.log("Risposta data " + quiz[counter - 1]?.lan[lan]?.opzioni[indx]?.testo)
        console.log("Stato della risposta " + quiz[counter - 1]?.lan[lan]?.opzioni[indx]?.corretta)
        setAnswers([...answers, { question: quiz[counter - 1], status: quiz[counter - 1]?.lan[lan]?.opzioni[indx]?.corretta }]);
    }, [quiz, answers, counter]);

    // FUNZIONE PER PASSARE ALLA PROSSIMA DOMANDA
    const goOn = useCallback(() => {
        setCurrentQuestion(quiz[counter]);
        setCounter(counter => counter + 1)
    }, [counter, quiz]);

    // FUNZIONE PER RICOMINCIARE IL TEST
    const restartTest = useCallback(() => {
        loadQuestions();
        setIsWelcomeScreen(true);
        setGruppoEta(undefined);
        setCounter(1);
        setAnswers([]);
    }, []);

    const onLeave = useCallback(() => {
        navigate('/');
    }, []);


    const retryTest = useCallback(() => {
        setAnswers([]);
        setQuiz(shuffleQuiz(quiz));
        setCounter(1);
        setIsWelcomeScreen(false);
    }, [quiz]);

    return (
        <div className="App">
            {
                /* DIAMO IL BENVENUTO E CHIEDIAMO DI INIZIARE */
                isWelcomeScreen &&
                <WrapperStep>
                    <WelcomeStep onGoOn={() => setIsWelcomeScreen(false)} />
                </WrapperStep>
            }
            {
                /*CHIEDIAMO SE QUIZ PER ADULTO O BAMBINO*/
                gruppoEta === undefined && !isWelcomeScreen &&
                <WrapperStep>
                    <ModalityStep onGoOn={(eta) => {
                        setGruppoEta(eta)
                        setQuiz(eta === 'adulti' ? generateAdultQuiz() : generateChildQuiz())
                    }} />
                </WrapperStep>
            }
            {
                gruppoEta && counter <= 12 && !isWelcomeScreen && quiz.length > 0 &&
                /* MOSTRIAMO UNA SINGOLA DOMANDA */
                <WrapperStep>
                    <QuestionHandler
                        counter={counter}
                        maxCounter={maxCounter}
                        currentQuestion={quiz[counter - 1]}
                        eta={gruppoEta}
                        onAnswer={(indx) => {
                            onValidateAnswer(indx)
                        }}
                        onGoOn={goOn}
                    />
                </WrapperStep>
            }
            {
                gruppoEta && counter > 12 && !isWelcomeScreen &&
                /* MOSTRIAMO IL PUNTEGGIO FINALE */
                <WrapperStep>
                    <FinishStep
                        points={answers.filter(answer => answer.status).length * 20}
                        onNewQuiz={restartTest}
                        onRetry={retryTest}
                        onLeave={onLeave}
                        leaveTime={60}
                    />
                </WrapperStep>
            }
            <BlobBackground />
        </div>
    );
}

export default Millionaire;
