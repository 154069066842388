import React from 'react';
import '../css/WrapperStep.css';

// child
interface WrapperStepProps {
    children: React.ReactNode;
}

const WrapperStep: React.FC<WrapperStepProps> = ({ children }) => {
    return (
        <div className='wrapper_step'>
            {
                children
            }
        </div>
    );
}

export default WrapperStep;