import React, { useCallback, useEffect } from "react";
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import '../css/ImageSlider.css';

interface ImageSliderProps {
    data: {
        image: string;
        imageAI: string;
    }
}

const ImageSlider: React.FC<ImageSliderProps> = ({ data }) => {

    return (
        <div className="image_slider">
            <ReactCompareSlider
                itemOne={<ReactCompareSliderImage src={`${data?.image}`} alt="Architettonic Image" />}
                itemTwo={<ReactCompareSliderImage src={`${data?.imageAI}`} alt="AI Image" />}
            />
        </div>
    );
}

export default ImageSlider;