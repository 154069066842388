import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import '../css/Modality.css';
import { useQuestions } from './QuestionProvider';
import lang from '../quest/app_lan.json';

interface GameSelectionProps {
    onGameSelected: (uri: string) => void;
}

const GameSelection: React.FC<GameSelectionProps> = ({ onGameSelected }) => {

    const { lan } = useQuestions(); // @ts-ignore
    const millionaire = lang[lan]?.millionaire || "Adult mode"; // @ts-ignore
    const time_travel = lang[lan]?.time_travel || "Time Travel"; // @ts-ignore

    const mill_sub = lang[lan]?.millionaire_sub || "Put your knowledge on the line"; // @ts-ignore
    const time_sub = lang[lan]?.time_travel_sub || "Reveals the ancient tablesvel"; // @ts-ignore


    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className='modality_' style={{ gap: '5%' }}>
            <div className="modalities_container">
                <div
                    style={{
                        backgroundImage: windowWidth > 500 ? "url('./Welcome.svg')" : "unset",
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: 'no-repeat'
                    }}
                />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                    <h1>{millionaire}</h1>
                    <p>{mill_sub}</p>
                </div>
                <div>
                    <Button style={{ width: '100%' }} onClick={() => onGameSelected('millionaire')}>{millionaire}</Button>
                </div>
            </div>
            <div className="modalities_container">
                <div
                    style={{
                        backgroundImage: windowWidth > 500 ? "url('./Time-Travel.svg')" : "unset",
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: 'no-repeat'
                    }}
                />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                    <h1 style={{ color: '' }}>{time_travel}</h1>
                    <p>{time_sub}</p>
                </div>
                <div>
                    <Button style={{ width: '100%' }} variant='zacco-white' onClick={() => onGameSelected('time-travel')}>{time_travel}</Button>
                </div>
            </div>
        </div>
    )
}

export default GameSelection;