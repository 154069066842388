import React from 'react';
import { IconInterface } from '../components/IconInterface';


const Blob: React.FC<IconInterface> = ({ width, height, fill }) => {
    return (
        <svg width={`${width ? `${width}px` : '776px'}`} height={`${height ? `${height}px` : '776px'}`} viewBox="0 0 776 837" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M633.176 -185.599C825.966 -58.6876 834.823 139.879 1045.95 351.003C1118.09 423.15 1333.09 556.924 1055.13 780.889C897.39 907.981 667.615 807.472 596.527 569.905C568.868 506.739 527.512 476.387 470.944 455.099C414.377 433.812 285.563 462.494 175.004 384.336C-157.706 149.131 -21.0603 -616.275 633.176 -185.599Z" fill="#D5C4B4" />
        </svg>
    )
}

export default Blob;