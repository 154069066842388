import React from "react";
import BlobBackground from '../components/BlobBackground';
import WrapperScreen from '../components/WrapperScreen';
import ImageSlider from '../components/ImageSlider';
import { Button } from '@mui/material';
import Gallery from '../components/Gallery';
import '../css/TimeTravel.css';
import { useQuestions } from "../components/QuestionProvider";
import lang from '../quest/app_lan.json'

const TimeTravel = () => {
    const [data, setData] = React.useState({
        image: '',
        imageAI: ''
    });

    const [isGalleryStep, setIsGalleryStep] = React.useState(true);

    const { lan } = useQuestions();
    // @ts-ignore
    const titleGallery = lang[lan]?.titolo_galleria || "Take a Dive into the Past"; // @ts-ignore
    const sub_title_1 = lang[lan]?.sottotilo_galleria || "What Was it Like and What Is it Now?";  // @ts-ignore
    const sub_title_2 = lang[lan]?.sottotiolo_galleria_2 || "Select the image you prefer and be amazed by the result"; // @ts-ignore

    return (
        <div className="App">
            <WrapperScreen>
                {
                    isGalleryStep ?
                        <div style={{ height: '90%', display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '50px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                <h1 className="gallery_title">{titleGallery}</h1>
                                <p>{sub_title_1}<br />
                                    {sub_title_2}</p>
                            </div>
                            <div style={{ flex: '1', maxWidth: '1920px' }} className="full-height-scrollable">
                                <Gallery
                                    onImageClick={(image) => {
                                        setIsGalleryStep(false);
                                        const min = 1;
                                        const max = 3;
                                        //const randomAI = Math.floor(Math.random() * (max - min + 1) + min)
                                        const imageAIName = `${image.split('.').slice(0, -1).join('.')}_NOW.jpg`
                                        console.log("L'immagine scelta è: ", image, "L'immagine AI scelta è: ", imageAIName)
                                        setData({
                                            image: image,
                                            imageAI: imageAIName
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        :
                        <div style={{ display: 'flex', height: '80%', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
                            {
                                !isGalleryStep &&
                                <Button style={{ width: 'max-content' }} onClick={() => setIsGalleryStep(true)}>Torna alla galleria</Button>
                            }
                            <div style={{ flex: 1, height: 'inherit' }}>
                                <ImageSlider data={data} />
                            </div>
                        </div>

                }
            </WrapperScreen>
            <BlobBackground />
        </div>
    );
}

export default TimeTravel;