import React, { useCallback, useEffect, useMemo } from 'react';
import { Question, useQuestions } from './QuestionProvider';
import { Button, LinearProgress } from '@mui/material';
import '../css/QuestionsHandler.css';
import lang from '../quest/app_lan.json'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

interface QuestionProp {
    currentQuestion?: Question; //DOMANDA MOSTRATA  IN QUESTO MOMENTO
    counter: number; //NUMERO DELLA DOMANDA
    maxCounter: number; //NUMERO MASSIMO DI DOMANDE (de default 12)
    onAnswer: (indx: number) => void; //INDICE DELLA RISPOSTA SELEZIONATA
    onGoOn: () => void; //FUNZIONE PER PASSARE ALLA PROSSIMA DOMANDA
    eta: string;
}

const QuestionHandler: React.FC<QuestionProp> = ({ currentQuestion, counter, maxCounter, onAnswer, onGoOn, eta }) => {

    const { lan } = useQuestions(); //@ts-ignore
    const domanda_txt = lang[lan]?.domanda || "Question"; //@ts-ignore
    const prosegui_txt = lang[lan]?.prosegui || "Go on";

    //TIME AUTO CALCULATION 
    const adultMsForChar = 0.1;
    const childMsForChar = 0.3;
    const minTime = 15;
    const calculateTime = (() => {
        if (eta === 'adulti') {
            const charOfAllAnswer = currentQuestion?.lan[lan]?.opzioni.reduce((acc, opzione) => acc + opzione.testo.length, 0) || 0;
            const charLength = currentQuestion?.lan[lan]?.testo.length || 0;
            const totalChar = charLength + charOfAllAnswer;
            const time = Math.floor(totalChar * adultMsForChar) < minTime ? minTime : Math.floor(totalChar * adultMsForChar);
            setTimer(time);
            setMaxTimerValue(time);
        } else {
            const charOfAllAnswer = currentQuestion?.lan[lan]?.opzioni.reduce((acc, opzione) => acc + opzione.testo.length, 0) || 0;
            const charLength = currentQuestion?.lan[lan]?.testo.length || 0;
            const totalChar = charLength + charOfAllAnswer;
            const time = Math.floor(totalChar * adultMsForChar) < minTime ? minTime : Math.floor(totalChar * adultMsForChar);

            setTimer(time);
            setMaxTimerValue(time);
        }
    })

    useEffect(() => {
        console.log("QuestionHandler -> Lingua cambiata -> ", lan);
    }, [lan])

    useEffect(() => {
        console.log("Prossima domanda -> ", currentQuestion?.lan[lan]?.testo);
    }, [currentQuestion])


    // INDICE DELLA RISPOSTA SELEZIONATA
    const [selectedIndex, setSelectedIndex] = React.useState<number | undefined>(undefined);

    //RESET DEL TIMER CSS 
    const [resetKey, setResetKey] = React.useState<number>(0);
    const [timer, setTimer] = React.useState<number>(0);
    const [maxTimerValue, setMaxTimerValue] = React.useState<number>(0);

    // AL CLICK SU UNA RISPOSTA, SALVA L'INDICE DELLA RISPOSTA SELEZIONATA
    const onAnswerClick = (indx: number) => {
        console.log("Arrivo di una risposta con indice: ", indx)
        if (selectedIndex !== undefined) return;
        if (onAnswer) onAnswer(indx)
        setSelectedIndex(indx);
    }

    // AL CAMBIO DOMANDA, RESETTA IL BOTTONE SELEZIONATO
    useEffect(() => {
        setSelectedIndex(undefined)
        setResetKey(resetKey + 1);
        calculateTime()
    }, [currentQuestion]);

    //GESTIONE DEL TIMER
    useEffect(() => {
        const interval = setInterval(() => {
            if (selectedIndex!==undefined) {
                console.log("Stiamo per disattivare il timer")
                clearInterval(interval);
                return;
            }

            const audio = new Audio('/Timer_Tick.mp3');
            const endAudio = new Audio('/Timer_Tick_End.mp3');
            if (timer == 10 || (timer <= 5 && timer != 0)) {
                audio.play();
            }

            if (timer === 0 && selectedIndex === undefined) {
                //SELEZIONE DI UNA RISPOSTA ERRATA A CASO
                endAudio.play();
                const errIndx = currentQuestion?.lan[lan].opzioni.findIndex(opzione => opzione.corretta === false);
                onAnswerClick(errIndx || 0);
                if (onGoOn) {
                    onGoOn();
                }
            }
            console.log("Timer: ", timer)

            setTimer(prevTimer => prevTimer - 1); // DECREMENTO DEL TIMER
        }, 1000);
        return () => clearInterval(interval); //PULIZIA DEL TIMER
    }, [timer, selectedIndex, currentQuestion]);

    return (
        <div className='question_container'>
            <h1>{`${domanda_txt} ${counter}/${maxCounter}`}</h1>
            <LinearProgress variant="determinate" value={counter * 100 / 12} />
            <div className='question_phrase_div'>
                <div style={{flex:1}}>
                    <p>{currentQuestion?.lan[lan]?.testo}</p>
                </div>
                {
                    selectedIndex === undefined && timer != 0 &&
                    <div className='timer_div'>
                        <CircularProgressbar
                            value={timer} // Utilizza il valore dinamico del timer
                            maxValue={maxTimerValue}
                            strokeWidth={50}

                            styles={buildStyles({
                                rotation: 0.01,
                                strokeLinecap: 'butt',
                                trailColor: 'rgba(243, 238, 234, 1)',
                                pathColor: 'rgba(204, 183, 163, 1)',
                            })}
                        />
                    </div>
                }
                {/* INSERIMENTO IMMAGINE */}
                {
                    currentQuestion?.images && currentQuestion.images.length > 0 &&
                    <div className={'img_zone'}>
                        <img className='image' src={currentQuestion?.images[0]} alt='Image' />
                    </div>
                }
            </div>
            <div className='question_btn_div'>
                <Button
                    disabled={timer == 0}
                    variant={
                        selectedIndex === undefined ?
                            'zacco-outlined'
                            :
                            currentQuestion?.lan[lan]?.opzioni[0].corretta ?
                                'zacco-correct'
                                :
                                !currentQuestion?.lan[lan]?.opzioni[0].corretta && selectedIndex === 0 ? 'zacco-error' : 'zacco-outlined'
                    }
                    onClick={() => { if (timer != 0) onAnswerClick(0); }}>{currentQuestion?.lan[lan]?.opzioni[0].testo}
                </Button>
                <Button
                    disabled={timer == 0}
                    variant={
                        selectedIndex === undefined ?
                            'zacco-outlined'
                            :
                            currentQuestion?.lan[lan]?.opzioni[1].corretta ?
                                'zacco-correct'
                                :
                                !currentQuestion?.lan[lan]?.opzioni[1].corretta && selectedIndex === 1 ? 'zacco-error' : 'zacco-outlined'
                    }
                    onClick={() => { if (timer != 0) onAnswerClick(1); }}>{currentQuestion?.lan[lan]?.opzioni[1].testo}
                </Button>
                <Button
                    disabled={timer == 0}
                    variant={
                        selectedIndex === undefined ?
                            'zacco-outlined'
                            :
                            currentQuestion?.lan[lan]?.opzioni[2].corretta ?
                                'zacco-correct'
                                :
                                !currentQuestion?.lan[lan]?.opzioni[2].corretta && selectedIndex === 2 ? 'zacco-error' : 'zacco-outlined'
                    }
                    onClick={() => { if (timer != 0) onAnswerClick(2) }}>{currentQuestion?.lan[lan]?.opzioni[2].testo}
                </Button>
                <Button
                    disabled={timer == 0}
                    variant={
                        selectedIndex === undefined ?
                            'zacco-outlined'
                            :
                            currentQuestion?.lan[lan]?.opzioni[3].corretta ?
                                'zacco-correct'
                                :
                                !currentQuestion?.lan[lan]?.opzioni[3].corretta && selectedIndex === 3 ? 'zacco-error' : 'zacco-outlined'
                    }
                    onClick={() => { if (timer != 0) onAnswerClick(3) }}>{currentQuestion?.lan[lan]?.opzioni[3].testo}
                </Button>
                <Button onClick={() => {
                    if (onGoOn) {
                        onGoOn();
                    }
                }} disabled={selectedIndex == undefined}>{prosegui_txt}</Button>
            </div>
        </div >
    )
}

export default QuestionHandler;