import React from 'react';
import { Button } from '@mui/material';
import { useQuestions } from './QuestionProvider';
import LoadingButton from '@mui/lab/LoadingButton';
import '../css/WelcomeStep.css';
import lang from '../quest/app_lan.json';

interface WelcomeStepProps {
    onGoOn: () => void;
}



const WelcomeStep: React.FC<WelcomeStepProps> = ({ onGoOn }) => {

    const { isLoading } = useQuestions();
    const { lan } = useQuestions(); //@ts-ignore
    const welcome_txt = lang[lan]?.welcome_title || "Hi, Welcome"; //@ts-ignore
    const welcome_sub = lang[lan]?.welcome_sub || "Choose the mode and start playing!"; //@ts-ignore
    const show_how_play_txt = lang[lan]?.choose_how_play || "Show how to play";

    return (
        <div className='modality_ext'>
            <div className="modalities_container">
                <div style={{ backgroundImage: "url('./Welcome.svg')", backgroundSize: "contain", backgroundPosition: "center", backgroundRepeat: 'no-repeat' }} />
                <div>
                    <h1>{welcome_txt}</h1>
                    <p>{welcome_sub}</p>
                </div>
                <div>
                    {
                        isLoading ?
                            <LoadingButton className='loadingBtn' loading variant="outlined" />
                            :
                            <Button onClick={() => onGoOn()}>{show_how_play_txt}</Button>
                    }
                </div>
            </div>
        </div>
    )
}
export default WelcomeStep;