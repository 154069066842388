import React, { useEffect, useState } from "react";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

interface GalleryProps {
    onImageClick?: (image: string) => void;
}

const Gallery: React.FC<GalleryProps> = ({ onImageClick }) => {


    const itemData = [
        {
            img: 'Tavola_1.jpg',
            img_reduce: 'Tavola_1_reduced.jpg',
            title: 'tav_architettonic',
        },
        {
            img: 'Tavola_2.jpg',
            img_reduce: 'Tavola_2_reduced.jpg',
            title: 'tav_architettonic'
        },
        /*{
            img: 'Tavola_3.jpg',
            img_reduce: 'Tavola_3_reduced.jpg',
            title: 'tav_architettonic'
        },*/
        {
            img: 'Tavola_4.jpg',
            img_reduce: 'Tavola_4_reduced.jpg',
            title: 'tav_architettonic'
        },
        {
            img: 'Tavola_5.jpg',
            img_reduce: 'Tavola_5_reduced.jpg',
            title: 'tav_architettonic'
        },
        {
            img: 'Tavola_6.jpg',
            img_reduce: 'Tavola_6_reduced.jpg',
            title: 'tav_architettonic'
        },
        {
            img: 'Tavola_7.jpg',
            img_reduce: 'Tavola_7_reduced.jpg',
            title: 'tav_architettonic'
        },
        {
            img: 'Tavola_8.jpg',
            img_reduce: 'Tavola_8_reduced.jpg',
            title: 'tav_architettonic'
        },
        {
            img: 'Tavola_9.jpg',
            img_reduce: 'Tavola_9_reduced.jpg',
            title: 'tav_architettonic'
        },
        {
            img: 'Tavola_10.jpg',
            img_reduce: 'Tavola_10_reduced.jpg',
            title: 'tav_architettonic'
        },
        {
            img: 'Tavola_11.jpg',
            img_reduce: 'Tavola_11_reduced.jpg',
            title: 'tav_architettonic'
        }
    ];

    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    function getCurrentDimension() {
        return { width: window.innerWidth, height: window.innerHeight }
    }
    useEffect(() => {
        const updateDimension = () => setScreenSize(getCurrentDimension())
        window.addEventListener('resize', updateDimension);
        return (() => window.removeEventListener('resize', updateDimension))
    }, [screenSize])

    const cols = screenSize.width > 600 ? 4 : 2;

    return (
        <ImageList sx={{ width: '100%' }} gap={50} cols={cols} >
            {itemData.map((item) => (
                <ImageListItem
                    key={item.img}
                    onClick={() => {
                        if (onImageClick)
                            onImageClick(item.img);
                    }}
                >
                    <img
                        srcSet={`${item.img_reduce}`}
                        src={`${item.img_reduce}`}
                        alt={item.title}
                        loading="lazy"
                        style={{ border: '2px solid rgba(136, 92, 49, 1)', cursor: 'pointer', borderRadius: '10px' }}
                    />
                </ImageListItem>
            ))}
        </ImageList>
    );
}

export default Gallery;