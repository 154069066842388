import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import '../css/Modality.css';
import { useQuestions } from './QuestionProvider';
import lang from '../quest/app_lan.json';

interface ModalityStepProps {
    onGoOn: (eta: string) => void;
}

const ModalityStep: React.FC<ModalityStepProps> = ({ onGoOn }) => {

    const { lan } = useQuestions(); // @ts-ignore
    const mod_adult = lang[lan]?.modality_adult || "Adult mode"; // @ts-ignore
    const mod_kid = lang[lan]?.modality_kid || "Kids mode"; // @ts-ignore
    const mod_sub = lang[lan]?.modality_sub || "Choose the mode and start playing!"; // @ts-ignore
    const adult_txt = lang[lan]?.adult || "ADULT"; // @ts-ignore
    const child_txt = lang[lan]?.kid || "KID";

    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className='modality_' style={{ gap: '5%' }}>
            <div className="modalities_container">
                <div
                    style={{
                        backgroundImage: windowWidth > 500 ? "url('./Adulto.svg')" : "unset",
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: 'no-repeat'
                    }}
                />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                    <h1>{mod_adult}</h1>
                    <p>{mod_sub}</p>
                </div>
                <div>
                    <Button style={{ width: '100%' }} onClick={() => onGoOn('adulti')}>{adult_txt}</Button>
                </div>
            </div>
            <div className="modalities_container">
                <div
                    style={{
                        backgroundImage: windowWidth > 500 ? "url('./Bambino.svg')" : "unset",
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: 'no-repeat'
                    }}
                />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                    <h1>{mod_kid}</h1>
                    <p>{mod_sub}</p>
                </div>
                <div>
                    <Button style={{ width: '100%' }} variant='zacco-white' onClick={() => onGoOn('bambini')}>{child_txt}</Button>
                </div>
            </div>
        </div>
    )
}

export default ModalityStep;